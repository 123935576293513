const en = {
    siteTitle: 'First Baptist Church - Sofia',
    brand: 'FBC - Sofia',
    searchLabel: 'Enter the search word and press Enter',
    searchPlaceholder: 'Search...',
    indexServicesTitle: 'Services',
    indexSermonsTitle: 'Sermons',
    indexSermonsSubTitle: 'Most recent videos',
    indexNewsTitle: 'News',
    indexMinistriesTitle: 'Ministries',
    contactTitle: 'Contact',
    contactAddressTitle: 'Address',
    contactAddress: 'ul. Osogovo 86, Sofia, Bulgaria',
    contactPhoneTitle: 'Phone',
    contactPhone: '+359 2 931 06 82',
    contactWriteUsTitle: 'Write us',
    contactWriteUsSubTitle: 'Pastors:',
    contactFormNameLabel: 'Your names',
    contactFormNamePlaceholder: 'Name Surname',
    contactFormMessageLabel: 'Message',
    contactFormPrivacy: 'I agree with the Privacy Policy',
    contactFormBtn: 'Send',
    pastorTeddy: 'Teodor Oprenov',
    pastorDidi: 'Dimitrina Oprenova',
    pastorVlady: 'Vladymir Raichinov',
    followUs: 'Follow us:',
    pages: 'Pages',
    images: 'Images',
    news: 'News',
    resources: 'Resources',
    sermons: 'Sermons',
    gallery: 'Gallery',
    filters: 'Filters',
    sortBy: 'Sort By',
    filterBy: 'Filter By:',
    book: 'Book',
    books: 'Books',
    speaker: 'Speaker',
    singleSeries: 'Series',
    speakers: 'Speakers',
    series: 'Series',
    share: 'Share:',
    shareWith: 'Share with',
    copyAddress: 'Copy the address',
    date: 'Date',
    bookRef: 'Reference',
    attachment: 'Attachment',
    download: 'Download',
    addedOn: 'Added on',
    emptyAlbum: 'The album is empty',
    live: 'Live',
    liveSubtitleSunday: 'Sunday at 10:30 am',
    liveSubtitleWednesday: 'Wednesday at 6:30 pm',
    countSeparator: 'of',
    timePreposition: 'at',
    year: 'Year',
    day: 'day',
    days: 'days',
    hour: 'hour',
    hours: 'hours',
    minute: 'minute',
    minutes: 'minutes',
    seconds: 'seconds',
    preachingsTitle: 'Our gatherings:',
    copied: 'Copied!',
    back: 'Back',
    next: 'Next',
    fbTitleAuthorise: 'Please, authorise our app',
    fbTitleLogin: 'Please, login to Facebook',
    tags: 'Keywords'
};

export {en};
