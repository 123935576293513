import React, {Component} from 'react';
import * as B from 'react-bootstrap';
import {postAuth} from '../API';
import PageTitle from "./layout/PageTitle";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            email: '',
            password: ''
        }
    }

    componentDidMount() {
        const {isLoaded} = this.props;
        isLoaded(true);
    }

    handleChange(ev) {
        const {name, value} = ev.target;
        this.setState({[name]: value});
    }

    setToken(token) {
        localStorage.setItem('tkn', token);
    }

    login(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        const {email, password} = this.state;
        const params = {email, password};
        postAuth('/api/auth/login', params)
            .then(data => {
                if (data.hasOwnProperty('access_token')) {
                    this.setToken(data['access_token']);
                    this.props.history.push('/admin');
                }
            })
            .catch(error => console.error(error));
    }

    render() {
        const {error, email, password} = this.state;

        if (error)
            return <B.Alert variant="danger">{error}</B.Alert>;

        return (
            <main id="app" className="pb-3" role="main">
                <PageTitle title='Вход'/>
                <B.Container as="section" className="py-3">
                    <B.Row>
                        <B.Col xs={12} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
                            <B.Form
                                onSubmit={ev => this.login(ev)}
                            >
                                <B.Form.Group controlId="email">
                                    <B.FormLabel>Email</B.FormLabel>
                                    <B.Form.Control type="email" name="email" value={email}
                                                    onChange={ev => this.handleChange(ev)} required/>
                                </B.Form.Group>
                                <B.Form.Group controlId="password">
                                    <B.FormLabel>Парола</B.FormLabel>
                                    <B.Form.Control type="password" name="password" value={password} autoComplete="off"
                                                    onChange={ev => this.handleChange(ev)} required/>
                                </B.Form.Group>
                                <B.Form.Group>
                                    <B.Button type="submit" variant="primary">Вход</B.Button>
                                </B.Form.Group>
                            </B.Form>
                        </B.Col>
                    </B.Row>
                </B.Container>
            </main>
        );
    }
}

export default Login;
