import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class FileUploadForm extends Component {
    render() {
        return (
            <form encType="multipart/form-data">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label htmlFor="mediaCategory">Категория:</label>
                            <select id="mediaCategory" className="custom-select">
                                <option value="">Моля, изберете</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label htmlFor="mediaSpeaker">Говорител:</label>
                            <select id="mediaSpeaker" className="custom-select">
                                <option value="">Моля, изберете</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label htmlFor="mediaTitle">Заглавие:</label>
                            <input type="text" id="mediaTitle" className="form-control"
                                   placeholder="Рождество Христово"/>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label htmlFor="mediaDate">Дата:</label>
                            <input type="date" id="mediaDate" className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label>Избери файл</label>
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="mediaFile"/>
                        <label className="custom-file-label" htmlFor="mediaFile">.mp4, .webm, .ogg</label>
                    </div>
                </div>
                <div className="form-group text-right">
                    <button type="submit" className="btn btn-primary">
                        <FontAwesomeIcon icon="upload" fixedWidth className="mr-2"/>
                        Качи файл
                    </button>
                </div>
            </form>
        );
    }
}
