const status = response => {
    if (response.ok) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(new Error(response.statusText));
    }
};

const jsonData = response => {
    if (!response)
        return Promise.reject(new Error('No response'));

    return response.json();
};

const getToken = () => localStorage.getItem('tkn');

const get = (url, needsToken = null) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    if (needsToken)
        headers.append("Authorization",`Bearer ${getToken()}`);

    const request = new Request(url, {
        method: 'GET',
        headers,
        cache: 'no-cache'
    });

    return fetch(request)
        .then(status)
        .catch(error => console.error('GET request failed', error))
        .then(jsonData);
};

const post = (url, data, needsToken = null) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    if (needsToken)
        headers.append("Authorization",`Bearer ${getToken()}`);

    const request = new Request(url, {
        method: 'POST',
        headers,
        cache: 'no-cache',
        body: JSON.stringify(data)
    });

    return fetch(request)
        .then(status)
        .catch(error => console.error('POST request failed', error))
        .then(jsonData);
};

const postFile = (url, data, needsToken = null) => {
    let options = {
        method: 'POST',
        cache: 'no-cache',
        body: data
    };
    if (needsToken) {
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${getToken()}`);
        options = Object.assign(options, {headers});
    }

    const request = new Request(url, options);

    return fetch(request)
        .then(status)
        .catch(error => console.error('POST File request failed', error))
        .then(jsonData);
};

const postAuth = (url, data) => {
    const request = new Request(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify(data)
    });

    return fetch(request)
        .then(status)
        .catch(error => console.error('POST Auth request failed', error))
        .then(jsonData);
};

const logout = () => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${getToken()}`);
    const request = new Request('/api/auth/logout', {
        method: 'GET',
        headers
    });

    return fetch(request)
        .then(status)
        .catch(error => console.error('Logout request failed', error))
        .then(jsonData);
};

export {get, post, postFile, postAuth, logout};
