import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

export default class MostWatched extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [
                {id: 1, name: 'Богослужение от 13.01.2019', watched: 5},
                {id: 2, name: 'Богослужение от 20.01.2019', watched: 15},
                {id: 3, name: 'Богослужение от 27.01.2019', watched: 30},
                {id: 4, name: 'Богослужение от 06.01.2019', watched: 10},
            ]
        }
    }

    getVideos() {
        return this.state.videos.sort((a, b) => b.watched - a.watched);
    }

    render() {
        return (
            <ul className="list-group">
                {this.getVideos().map(video => {
                    return (<li key={video.id}
                                className="list-group-item d-flex justify-content-between align-items-center">
                            <NavLink to={`/sermons/${video.id}`}>{video.name}</NavLink>
                            <span className="badge badge-primary badge-pill watched">{video.watched}</span>
                        </li>
                    )
                })
                }
            </ul>
        );
    }
}
