import React, {Component} from 'react';

export default class GoogleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapIsReady: false,
        };
    }

    componentDidMount() {
        const ApiKey = 'AIzaSyDls8gaGr344x0XOFVj4JmEp8JIQAajXz0';
        const script = document.createElement('script');
        if (document.getElementById('googleMaps')) {
            this.setState({ mapIsReady: true });
            return;
        }
        script.id = 'googleMaps';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}`;
        script.async = true;
        script.defer = true;
        script.addEventListener('load', () => {
            this.setState({ mapIsReady: true });
        });

        document.body.appendChild(script);
    }

    componentDidUpdate() {
        if (this.state.mapIsReady) {
            const coord = {lat: 42.704815, lng: 23.309692}
            // Display the map
            this.map = new window.google.maps.Map(document.getElementById('map'), {
                center: coord,
                zoom: 18,
                mapTypeId: 'roadmap',
                gestureHandling: 'cooperative',
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
            });
            this.marker = new google.maps.Marker({
                position: coord,
                animation: google.maps.Animation.DROP,
                map: this.map
            });
            // You also can add markers on the map below
        }
    }

    render() {
        return (
            <div id="map" className="contacts-map"/>
        );
    }
}
