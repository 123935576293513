import React, {Component} from 'react';
import * as B from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {get} from "../API";

class IndexPreachings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preachings: [],
            error: null
        };
        this.API_URL = '/api/preaching';
        this.days = {
            'bg': ['Неделя', 'Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота'],
            'en': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        };
    }

    componentWillMount() {
        this.loadPreachings();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language)
            this.loadPreachings();
    }

    loadPreachings() {
        this.setState({error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    const {language} = this.props;
                    const preachings = data.filter(pr => pr.lang_id === language);

                    this.setState({preachings});
                } else {
                    this.setState({
                        preachings: [],
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    render() {
        const {preachings, error} = this.state;

        if (error)
            return <B.Alert variant="info">{error}</B.Alert>;

        if (preachings.length) {
            return (
                <B.CardColumns className="sermons-wrapper">
                    {preachings.map(preach => {
                        const preachID = preach['preach_id'];
                        const day = this.days[preach.lang][preach.day];
                        const preachTime = preach.hour.split(':');
                        const date = new Date();
                        date.setHours(preachTime[0], preachTime[1], preachTime[2]);

                        return (
                            <B.Card key={preachID} className="text-center">
                                <B.Card.Body>
                                    <B.Card.Title>
                                        <FontAwesomeIcon icon={preach.icon} fixedWidth size="2x"/>
                                    </B.Card.Title>
                                    <B.Card.Text as='div'>
                                        <h5 className="sermon-name mb-0">{preach.name}</h5>
                                        <h6 className="sermon-time">{`${day} от ${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()} ч.`}</h6>
                                    </B.Card.Text>
                                </B.Card.Body>
                            </B.Card>
                        );
                    })}
                </B.CardColumns>
            );
        }

        return null;
    }
}

export default IndexPreachings;
