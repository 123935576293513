import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavLink} from "react-router-dom";

export default class MenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subOpen: false
        };
    }

    addChildren() {
        if (!this.state.subOpen) {
            return null;
        }

        return (
            <ul className="sub-menu nav flex-column">
                {this.props.children.map(child => <li key={child.id} className="nav-item"><NavLink to={child.addr} exact>{child.name}</NavLink>
                </li>)}
            </ul>
        );
    }

    handleClick() {
        this.setState(prevState => ({
            subOpen: this.props.children && !prevState.subOpen
        }));
    }

    render() {
        return (
            <li className={`nav-item${this.props.children ? ' parent' : ''}`}>
                <NavLink to={this.props.addr} exact onClick={() => this.handleClick()}>
                    {this.props.name}
                    {this.props.children && <FontAwesomeIcon icon={this.state.subOpen ? 'caret-up' : 'caret-down'}/>}
                </NavLink>
                {this.addChildren()}
            </li>
        );
    }
};
