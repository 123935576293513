import React, {Component} from 'react';
import * as B from 'react-bootstrap';
import MenuItem from './MenuItem';

export default class SidebarAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuItems: [
                {id: 1, name: 'Начало', addr: '/admin/'},
                {id: 2, name: 'Страници', addr: '/admin/pages'},
                {
                    id: 3, name: 'Медия', addr: '#', children: [
                        {id: 4, name: 'Живо предаване', addr: '/admin/live-stream'},
                        {id: 5, name: 'Проповеди', addr: '/admin/sermons'},
                        {id: 6, name: 'Снимки', addr: '/admin/galleries'},
                        {id: 7, name: 'Файлове', addr: '/admin/files'},
                    ]
                },
                {id: 8, name: 'Слайдери', addr: '/admin/slider'},
                {id: 9, name: 'Потребители', addr: '/admin/users'},
                {id: 10, name: 'Менюта', addr: '/admin/menus'},
                {id: 11, name: 'Новини', addr: '/admin/news'},
                {id: 12, name: 'Обратна връзка', addr: '/admin/contact'},
                {id: 13, name: 'Настройки', addr: '/admin/settings'}
            ]
        };
    }

    render() {
        return (
            <B.Col as="aside" xs={3} xl={2} className="h-100 p-0 text-white bg-dark d-none d-md-block">
                <nav>
                    <ul id="adminNav" className="nav flex-column">
                        {this.state.menuItems.map(item => <MenuItem key={item.id} id={item.id} name={item.name}
                                                                    addr={item.addr}
                                                                    children={item.children}/>)
                        }
                    </ul>
                </nav>
            </B.Col>
        );
    }
}
