import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as B from 'react-bootstrap';
import {get, post} from "../../API";

class SettingsPreaching extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            modalShow: false,
            validated: false,
            formsData: {},
            preachingEditID: null
        };
        this.API_URL = '/api/preaching';
        this.PREACHING_FUNC = {
            'add': 1,
            'edit': 2,
            'delete': 3
        };
        this.days = {
            'bg': ['Неделя', 'Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота'],
            'en': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        };
    }

    componentWillMount() {
        this.loadPreaching();
    }

    modalOpen() {
        this.setState({modalShow: true});
    }

    modalHide(reset) {
        if (reset) {
            const formsData = {...this.state.formsData};
            formsData['preachName'] = null;
            formsData['preachDay'] = '';
            formsData['preachTime'] = null;
            formsData['preachIcon'] = null;
            formsData['preachLanguage'] = null;
            this.setState({modalShow: false, validated: false, formsData, preachEditID: null});
            return;
        }
        this.setState({modalShow: false});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};

        formsData[name] = value;

        this.setState({formsData});
    }

    loadPreaching() {
        const {onPreachingUpdate} = this.props;
        this.setState({loaded: false, error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    this.setState({loaded: true});
                    onPreachingUpdate(data);
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message']
                    });
                    onPreachingUpdate([]);
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }


    addEditPreaching(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {preachEditID} = this.state;
            const {preachName, preachIcon, preachDay, preachTime, preachLanguage} = this.state.formsData;
            const userTime = preachTime.split(':');
            const date = new Date();
            date.setHours(userTime[0], userTime[1], 0);
            const func = preachEditID ? this.PREACHING_FUNC.edit : this.PREACHING_FUNC.add;
            const params = {
                name: preachName,
                icon: preachIcon,
                day: preachDay,
                lang_id: preachLanguage,
                hour: `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}:00`,
                preach_id: preachEditID
            };
            const data = {auth: {func}, params};
            const {updateAlerts} = this.props;

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide(true);

                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({
                            loaded: true,
                            validated: false
                        });

                        this.loadPreaching();
                    } else {
                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({loaded: true});
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    getPreachData(id) {
        const {updateAlerts} = this.props;

        get(`${this.API_URL}/${id}`)
            .then(data => {
                if (data['response'] !== false) {
                    const formsData = {...this.state.formsData};
                    formsData['preachName'] = data['name'];
                    formsData['preachDay'] = data['day'];
                    formsData['preachTime'] = data['hour'];
                    formsData['preachIcon'] = data['icon'];
                    formsData['preachLanguage'] = data['lang_id'];

                    this.setState({
                        formsData,
                        preachEditID: id
                    });
                    this.modalOpen();
                } else {
                    const alerts = [...this.props.alerts];
                    alerts.push({type: 'danger', text: data['message']});
                    updateAlerts(alerts);

                    this.setState({loaded: true});
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    deletePreaching(preach_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете това богослужение?')) {
            this.setState({loaded: false});
            const params = {preach_id};
            const data = {auth: {func: this.PREACHING_FUNC.delete}, params};
            const {updateAlerts} = this.props;

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        updateAlerts(alerts);

                        this.loadPreaching();
                    } else {
                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({loaded: true});
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }


    drawPreaching() {
        const {preaching} = this.props;

        if (preaching.length) {
            return (
                <B.Row>
                    {preaching.map(preach => {
                        const preachID = preach['preach_id'];
                        const day = this.days[preach.lang][preach.day];

                        return (
                            <B.Col key={preachID} xs={12} md={6} lg={4} xl={3} className="mb-3">
                                <B.Card className="text-center">
                                    <B.Card.Body>
                                        <FontAwesomeIcon icon={preach.icon} fixedWidth size="2x"/>
                                        <B.Card.Title>{preach.name}</B.Card.Title>
                                        <B.Card.Subtitle>{`${day} ${preach.lang === 'bg' ? 'от' : 'at'} ${preach.hour}`}</B.Card.Subtitle>
                                    </B.Card.Body>
                                    <B.Card.Footer className="text-center">
                                        <span className="langMarker">{preach['lang']}</span>
                                        <B.Button type="button" variant="primary" onClick={() => this.getPreachData(preachID)} className="mr-2">
                                            <FontAwesomeIcon icon="edit" fixedWidth/>
                                        </B.Button>
                                        <B.Button type="button" variant="danger" onClick={() => this.deletePreaching(preachID)}>
                                            <FontAwesomeIcon icon="trash-alt" fixedWidth/>
                                        </B.Button>
                                    </B.Card.Footer>
                                </B.Card>
                            </B.Col>
                        );
                    })}
                </B.Row>
            );
        }
    }

    drawModals() {
        const {formsData, validated, modalShow, preachEditID} = this.state;
        const {languages} = this.props;

        return (
            <B.Modal
                id='addEditUser'
                centered
                show={modalShow}
                onHide={() => this.modalHide(true)}
                aria-labelledby="addEditUserTitle"
            >
                <B.Modal.Header closeButton>
                    <B.Modal.Title id="addEditUserTitle">
                        {preachEditID ? 'Редактирай' : 'Добави'} богослужение
                    </B.Modal.Title>
                </B.Modal.Header>
                <B.Modal.Body>
                    <B.Form
                        noValidate
                        validated={validated}
                        onSubmit={e => this.addEditPreaching(e)}>
                        <B.Form.Group controlId="preachName">
                            <B.Form.Label>Име</B.Form.Label>
                            <B.Form.Control type="text" name="preachName" value={formsData['preachName'] || ''}
                                            onChange={(ev) => this.handleInputChange(ev)} required/>
                            <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                        </B.Form.Group>
                        <B.Row>
                            <B.Col xs={12} md={6}>
                                <B.Form.Group controlId="preachDay">
                                    <B.Form.Label>Ден</B.Form.Label>
                                    <B.Form.Control as="select" name="preachDay"
                                                    value={formsData['preachDay'] !== undefined ? formsData['preachDay'] : ''}
                                                    onChange={(ev) => this.handleInputChange(ev)} required>
                                        <option value="">Моля, изберете</option>
                                        {this.days['bg'].map((day, id) => {
                                            return <option key={id} value={id}>{day}</option>
                                        })}
                                    </B.Form.Control>
                                    <B.Form.Control.Feedback type="invalid">Полето е
                                        задължително!</B.Form.Control.Feedback>
                                </B.Form.Group>
                            </B.Col>
                            <B.Col xs={12} md={6}>
                                <B.Form.Group controlId="preachTime">
                                    <B.Form.Label>Час</B.Form.Label>
                                    <B.Form.Control type="text" name="preachTime"
                                                    value={formsData['preachTime'] || ''}
                                                    onChange={(ev) => this.handleInputChange(ev)}
                                                    placeholder="10:30" required/>
                                    <B.Form.Control.Feedback type="invalid">Полето е
                                        задължително!</B.Form.Control.Feedback>
                                </B.Form.Group>
                            </B.Col>
                        </B.Row>
                        <B.Form.Group controlId='preachLanguage'>
                            <B.Form.Label>Език</B.Form.Label>
                            <B.Form.Control as="select" name="preachLanguage" value={formsData['preachLanguage'] || ''}
                                            onChange={(ev) => this.handleInputChange(ev)}
                                            className="custom-select" required>
                                <option value="">Избери език</option>
                                {languages.map(({id, lang}) => (
                                    <option key={id} value={id}>{lang}</option>
                                ))}
                            </B.Form.Control>
                            <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                        </B.Form.Group>
                        <B.Form.Group controlId="preachIcon">
                            <B.Form.Label>Иконка</B.Form.Label>
                            <B.Form.Control type="text" name="preachIcon" value={formsData['preachIcon'] || ''}
                                            onChange={(ev) => this.handleInputChange(ev)}/>
                            <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                        </B.Form.Group>
                        <B.Form.Group className="text-center">
                            <B.Button type="submit" variant="primary">
                                <FontAwesomeIcon icon={preachEditID ? 'edit' : 'plus'} fixedWidth
                                                 className="mr-2"/>
                                {preachEditID ? 'Редактирай' : 'Добави'} богослужение
                            </B.Button>
                        </B.Form.Group>
                    </B.Form>
                </B.Modal.Body>
            </B.Modal>
        );
    }

    render() {
        const {loaded, error} = this.state;

        if (error) {
            return (
                <PreachingLayout modalShow={(id) => this.modalOpen(id)}>
                    <B.Alert variant='danger'>
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                    {this.drawModals()}
                </PreachingLayout>
            );
        } else if (!loaded) {
            return (
                <PreachingLayout modalShow={(id) => this.modalOpen(id)}>
                    <B.ProgressBar animated now={100} label="Loading..."/>
                    {this.drawModals()}
                </PreachingLayout>
            );
        } else {
            return (
                <PreachingLayout modalShow={(id) => this.modalOpen(id)}>
                    {this.drawPreaching()}
                    {this.drawModals()}
                </PreachingLayout>
            );
        }
    }
}

export default SettingsPreaching;

const PreachingLayout = props => {
    const {children, modalShow} = props;

    return (
        <div id="preachings" className="preachings-wrapper mb-4">
            <div className="page-header mb-3">
                <h4 className="d-inline-block">Богослужения</h4>
                <B.Button className="btn btn-light float-right" onClick={() => modalShow()}>
                    <FontAwesomeIcon icon='plus' className='text-success' fixedWidth/>
                    <span className="d-none d-md-inline-block ml-2">Добави богослужение</span>
                </B.Button>
            </div>
            <div className="clearfix"/>
            {children}
        </div>
    );
};
