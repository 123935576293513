import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as B from 'react-bootstrap';
import {get, post} from "../../API";

class SettingsFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            modalShow: {
                'quote': false,
                'social': false,
                'columns': false
            },
            validated: false,
            formsData: {},
            footer: [],
            footerList: {},
            social: {},
            columnEditID: null
        };
        this.API_URL = '/api/footer';
        this.FOOTER_FUNC = {
            'addNewColumn': 1,
            'addToColumn': 2,
            'editColumn': 3,
            'deleteColumn': 4,
            'deleteFromColumn': 5,
            'editSocial': 6,
            'editQuote': 7,
            'loadSocial': 8,
            'loadQuote': 9
        };
        this.days = {
            'bg': ['Неделя', 'Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота'],
            'en': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        };
    }

    componentWillMount() {
        this.loadFooter();
        this.loadQuote();
        this.loadSocial();
    }

    modalOpen(id) {
        const modalShow = {...this.state.modalShow};
        modalShow[id] = true;

        this.setState({modalShow});
    }

    modalHide(id, reset) {
        const modalShow = {...this.state.modalShow};
        modalShow[id] = false;

        if (reset) {
            const formsData = {...this.state.formsData};
            formsData['columnName'] = null;
            formsData['columnHasLinks'] = 0;
            formsData['columnLanguage'] = null;
            formsData['listText'] = null;
            formsData['listLink'] = null;
            formsData['listLanguage'] = null;
            this.setState({modalShow, validated: false, formsData, columnEditID: null});
            return;
        }
        this.setState({modalShow});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};

        formsData[name] = value;

        this.setState({formsData});
    }

    handleSocialChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const social = {...this.state.social};
        social[name] = value;

        this.setState({social});
    }

    loadFooter() {
        this.setState({loaded: false, error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    let footer = [];
                    let footerList = {};

                    if (data['data'])
                        footer = data['data'];

                    if (data['data_content'])
                        footerList = data['data_content'];

                    this.setState({
                        loaded: true,
                        footer,
                        footerList
                    });
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message'],
                        footer: [],
                        footerList: {}
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadSocial() {
        const params = {};
        const data = {auth: {func: this.FOOTER_FUNC.loadSocial}, params};

        post(this.API_URL, data)
            .then(data => {
                if (data['response'] !== false) {
                    this.setState({
                        loaded: true,
                        social: data
                    });
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message'],
                        social: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadQuote() {
        const params = {};
        const data = {auth: {func: this.FOOTER_FUNC.loadQuote}, params};

        post(this.API_URL, data)
            .then(data => {
                if (data['response'] !== false) {
                    const formsData = {...this.state.formsData};
                    formsData['quoteText'] = data['quote'];
                    formsData['quoteStatus'] = data['is_active'];
                    formsData['quoteLanguage'] = data['lang_id'];

                    this.setState({
                        loaded: true,
                        formsData
                    });
                } else {
                    const formsData = {...this.state.formsData};
                    formsData['quoteText'] = '';
                    formsData['quoteStatus'] = 0;
                    formsData['quoteLanguage'] = '';

                    this.setState({
                        loaded: true,
                        error: data['message'],
                        formsData
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    getColumnData(id, modalID) {
        const {updateAlerts} = this.props;

        get(`${this.API_URL}/${id}`)
            .then(data => {
                if (data['response'] !== false) {
                    const formsData = {...this.state.formsData};
                    if (data['data']) {
                        formsData['columnName'] = data['data']['column'];
                        formsData['columnHasLinks'] = data['data']['has_links'];
                        formsData['columnLanguage'] = data['data']['lang_id'];
                    }

                    this.setState({
                        formsData,
                        columnEditID: id
                    });
                    this.modalOpen(modalID);
                } else {
                    const alerts = [...this.props.alerts];
                    alerts.push({type: 'danger', text: data['message']});
                    updateAlerts(alerts);

                    this.setState({loaded: true});
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    addEditColumn(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;
        const {footer} = this.state;
        const {updateAlerts} = this.props;

        this.setState({validated: true});

        if (footer.length === 6) {
            const alerts = [...this.props.alerts];
            alerts.push({type: 'danger', text: 'Вече са въведени максимален брой колони!'});
            updateAlerts(alerts);

            return;
        }

        if (form.checkValidity()) {
            const {columnEditID} = this.state;
            const {columnName, columnHasLinks, columnLanguage} = this.state.formsData;
            const params = {
                column: columnName,
                has_links: columnHasLinks ? 1 : 0,
                column_id: columnEditID,
                lang_id: columnLanguage
            };
            const func = columnEditID ? this.FOOTER_FUNC.editColumn : this.FOOTER_FUNC.addNewColumn;
            const data = {auth: {func}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide('columns', true);

                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({
                            loaded: true,
                            validated: false
                        });

                        this.loadFooter();
                    } else {
                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({loaded: true});
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    deleteColumn(column_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете тази колона?')) {
            this.setState({loaded: false});
            const params = {column_id};
            const data = {auth: {func: this.FOOTER_FUNC.deleteColumn}, params};
            const {updateAlerts} = this.props;

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        updateAlerts(alerts);

                        this.loadFooter();
                    } else {
                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({loaded: true});
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    addToColumn(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {listText, listLink, listLanguage} = this.state.formsData;
            const {columnEditID} = this.state;
            const params = {column_id: columnEditID, list: listText, link: listLink, lang_id: listLanguage};
            const data = {auth: {func: this.FOOTER_FUNC.addToColumn}, params};
            const {updateAlerts} = this.props;

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide('addToColumn', true);

                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({
                            loaded: true,
                            validated: false
                        });

                        this.loadFooter();
                    } else {
                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({loaded: true});
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    deleteFromColumn(list_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете този елемент?')) {
            this.setState({loaded: false});
            const params = {list_id};
            const data = {auth: {func: this.FOOTER_FUNC.deleteFromColumn}, params};
            const {updateAlerts} = this.props;

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        updateAlerts(alerts);

                        this.loadFooter();
                    } else {
                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({loaded: true});
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    editQuote(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {quoteText, quoteStatus, quoteLanguage} = this.state.formsData;
            const params = {quote: quoteText, is_active: quoteStatus, lang_id: quoteLanguage};
            const data = {auth: {func: this.FOOTER_FUNC.editQuote}, params};
            const {updateAlerts} = this.props;

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide('quote', true);

                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({
                            loaded: true,
                            validated: false
                        });

                        this.loadQuote();
                    } else {
                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({loaded: true});
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    editSocial(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {social} = this.state;
            const data = {auth: {func: this.FOOTER_FUNC.editSocial}, params: social};
            const {updateAlerts} = this.props;

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide('social', true);

                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({
                            loaded: true,
                            validated: false
                        });

                        this.loadSocial();
                    } else {
                        const alerts = [...this.props.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        updateAlerts(alerts);

                        this.setState({loaded: true});
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    drawFooter(langID, lang) {
        const {footer, footerList, social, formsData} = this.state;
        const {preaching} = this.props;
        const date = new Date();
        let footerResult = [];
        let preachingResult = [];
        let socialResult = [];
        let lgColumns = 6;
        let lgColumnRight = 6;

        const footerLang = footer.filter(col => col['lang_id'] === langID);
        const preachingLang = preaching.filter(pr => pr['lang_id'] === langID);

        switch (footerLang.length) {
            case 0:
                lgColumnRight = 12;
                break;
            case 1:
                lgColumns = 6;
                lgColumnRight = 6;
                break;
            case 2:
                lgColumns = 4;
                lgColumnRight = 4;
                break;
            case 3:
                lgColumns = 3;
                lgColumnRight = 3;
                break;
            case 4:
                lgColumns = 3;
                lgColumnRight = 12;
                break;
            case 5:
                lgColumns = 2;
                lgColumnRight = 2;
                break;
            case 6:
                lgColumns = 2;
                lgColumnRight = 12;
                break;
            default:
                lgColumns = 6;
                lgColumnRight = 6;
                break;
        }

        if (footerLang.length) {
            footerLang.map(col => {
                const colID = col['column_id'];
                const footerListLang = footerList.hasOwnProperty(colID) ? footerList[colID].filter(li => li['lang_id'] === langID) : null;

                footerResult.push(
                    <B.Col key={`footerCol-${colID}`} xs={12} md={6} lg={lgColumns}
                           className="footer-widget mb-3 mb-lg-0">
                        <h6 className="font-weight-light text-white">
                            {col['column']}
                            <B.Button type="button" variant="link" className="ml-2 p-0"
                                      onClick={() => this.getColumnData(colID, 'columns')} title="Редактирай колоната">
                                <FontAwesomeIcon icon="edit" fixedWidth className="text-white"/>
                            </B.Button>
                            <B.Button type="button" variant="link" className="ml-2 p-0"
                                      onClick={() => this.deleteColumn(colID)} title="Изтрий колоната">
                                <FontAwesomeIcon icon="times" fixedWidth className="text-white"/>
                            </B.Button>
                        </h6>
                        {footerListLang ?
                            col['has_links'] ? <B.Nav as='ul' className='flex-column'>
                                    {footerListLang.map(item => (
                                        <B.NavItem as='li' key={item['list_id']}>
                                            {item.link ?
                                                item.link.indexOf('http') !== -1 ?
                                                    <a href={item.link} target="_blank"
                                                       className="nav-link">{item.list}</a> :
                                                    <NavLink to={item.link} className="nav-link" exact>{item.list}</NavLink>
                                                : <a href="#" className="nav-link">{item.list}</a>}
                                            <B.Button type="button" variant="link" className="ml-2 p-0"
                                                      title="Изтрий елемента"
                                                      onClick={() => this.deleteFromColumn(item['list_id'])}>
                                                <FontAwesomeIcon icon="times" fixedWidth className="text-white"/>
                                            </B.Button>
                                        </B.NavItem>)
                                    )}
                                </B.Nav> :
                                <ul className="list-unstyled">
                                    {footerListLang.map(item => (<>
                                            <li key={item['list_id']}>
                                                {item.list}
                                                <B.Button type="button" variant="link" className="ml-2 p-0"
                                                          title="Изтрий елемента"
                                                          onClick={() => this.deleteFromColumn(item['list_id'])}>
                                                    <FontAwesomeIcon icon="times" fixedWidth
                                                                     className="text-white"/>
                                                </B.Button>
                                            </li>
                                        </>
                                    ))}
                                </ul>
                            : ''
                        }
                        <div className="mt-2">
                            <B.Button type="button" variant="link" size="sm"
                                      onClick={() => this.getColumnData(colID, 'addToColumn')}>
                                <FontAwesomeIcon icon="plus" fixedWidth className="mr-2"/>
                                Добави елемент
                            </B.Button>
                        </div>
                    </B.Col>
                );
            });
        }

        if (preachingLang.length) {
            preachingResult.push(
                <ul key="preachingsList" className="services-list fa-ul">
                    {preachingLang.map(preach => {
                        const preachTime = preach.hour.split(':');
                        const date = new Date();
                        date.setHours(preachTime[0], preachTime[1], 0);
                        const day = this.days[lang][preach.day];

                        return (<li key={preach['preach_id']} title={preach['name']}>
                            <FontAwesomeIcon icon={preach.icon} listItem/>
                            {`${day} ${lang === 'bg' ? 'от' : 'at'} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`}
                        </li>);
                    })}
                </ul>
            );
        }

        if (Object.keys(social).length) {
            socialResult.push(
                social['show_facebook'] === 1 &&
                <a key="footerSocialFacebook" href={social['facebook']} target="_blank" className="ml-1">
                    <FontAwesomeIcon icon={['fab', 'facebook']} fixedWidth size="lg" className="mr-2"/>
                </a>,
                social['show_twitter'] === 1 &&
                <a key="footerSocialTwitter" href={social['twitter']} target="_blank" className="ml-1">
                    <FontAwesomeIcon icon={['fab', 'twitter']} fixedWidth size="lg" className="mr-2"/>
                </a>,
                social['show_instagram'] === 1 &&
                <a key="footerSocialInstagram" href={social['instagram']} target="_blank" className="ml-1">
                    <FontAwesomeIcon icon={['fab', 'instagram']} fixedWidth size="lg" className="mr-2"/>
                </a>,
                social['show_youtube'] === 1 &&
                <a key="footerSocialYouTube" href={social['youtube']} target="_blank" className="ml-1">
                    <FontAwesomeIcon icon={['fab', 'youtube']} fixedWidth size="lg" className="mr-2"/>
                </a>
            );
        }

        return (
            <>
                <h4 key={lang}>{lang}</h4>
                <footer key={langID} className="footer bg-dark text-muted pt-2 pb-3 mb-4">
                    <B.Container>
                        {formsData['quoteStatus'] ?
                            <B.Row className="footer-quote-wrapper pb-2">
                                <B.Col xs={12} className="text-center text-muted d-none d-md-block">
                                    <small>{formsData['quoteText']}</small>
                                </B.Col>
                            </B.Row>
                            : ''}
                        <B.Row className="footer-widgets-wrapper pb-2 pt-3">
                            {footerLang.length ?
                                footerResult
                                : ''}
                            <B.Col key="footerLastColumn" xs={12} lg={lgColumnRight} className="footer-widget">
                                {preachingLang.length ?
                                    <>
                                        <h6 key="preachingsTitle" className="font-weight-light text-white">
                                            Нашите събрания:
                                        </h6>
                                        {preachingResult}
                                    </>
                                    : ''}
                                <div key="footerSocials" className="socials-wrapper">
                                    {socialResult}
                                </div>
                            </B.Col>
                        </B.Row>
                        <B.Row className="footer-copyright text-muted pt-3">
                            <B.Col xs={12} md={6} className="text-center text-md-left">
                                Copyright &copy; {date.getFullYear()} ЕБЦ - София 1
                            </B.Col>
                        </B.Row>
                    </B.Container>
                </footer>
            </>
        );
    }

    drawModals() {
        const {languages} = this.props;
        const {formsData, validated, modalShow, social, columnEditID} = this.state;

        return (
            <>
                {/* Add/Edit column Modal */}
                <B.Modal id='columns'
                         centered
                         show={modalShow['columns']}
                         onHide={() => this.modalHide('columns', true)}
                         aria-labelledby="columnsTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="columnsTitle">
                            {columnEditID ? 'Редактирай' : 'Добави'} колона
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            onSubmit={ev => this.addEditColumn(ev)}
                            noValidate
                            validated={validated}>
                            <B.Form.Group controlId="columnName">
                                <B.Form.Label>Име/Заглавие</B.Form.Label>
                                <B.Form.Control type="text" name="columnName" value={formsData['columnName'] || ''}
                                                onChange={ev => this.handleInputChange(ev)} required/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId='columnLanguage'>
                                <B.Form.Label>Език</B.Form.Label>
                                <B.Form.Control as="select" name="columnLanguage"
                                                value={formsData['columnLanguage'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)}
                                                className="custom-select" required>
                                    <option value="">Избери език</option>
                                    {languages.map(({id, lang}) => (
                                        <option key={id} value={id}>{lang}</option>
                                    ))}
                                </B.Form.Control>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="columnHasLinks">
                                <B.Form.Label>Има линкове</B.Form.Label>
                                <div className="custom-control custom-switch">
                                    <B.Form.Check type='checkbox'>
                                        <B.Form.Check.Input type='checkbox' name="columnHasLinks"
                                                            className="custom-control-input"
                                                            checked={formsData['columnHasLinks']}
                                                            onChange={(ev) => this.handleInputChange(ev)}/>
                                        <B.Form.Check.Label
                                            className="custom-control-label">{formsData['columnHasLinks'] ? 'Да' : 'Не'}</B.Form.Check.Label>
                                    </B.Form.Check>
                                </div>
                            </B.Form.Group>
                            {columnEditID ? '' :
                                <B.Form.Group className="text-center">
                                    <B.FormText className="text-muted">Максималният брой колони е 6</B.FormText>
                                </B.Form.Group>
                            }
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon={columnEditID ? 'edit' : 'plus'} fixedWidth className="mr-2"/>
                                    {columnEditID ? 'Редактирай' : 'Добави'} колона
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
                {/* Add/Edit from column Modal */}
                <B.Modal id='addToColumn'
                         centered
                         show={modalShow['addToColumn']}
                         onHide={() => this.modalHide('addToColumn', true)}
                         aria-labelledby="addToColumnTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="addToColumnTitle">Добави към колона</B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            onSubmit={ev => this.addToColumn(ev)}
                            noValidate
                            validated={validated}>
                            <B.Form.Group controlId="listText">
                                <B.Form.Label>Текст</B.Form.Label>
                                <B.Form.Control type="text" name="listText" value={formsData['listText'] || ''}
                                                onChange={ev => this.handleInputChange(ev)} required/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId='listLanguage'>
                                <B.Form.Label>Език</B.Form.Label>
                                <B.Form.Control as="select" name="listLanguage" value={formsData['listLanguage'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)}
                                                className="custom-select" required>
                                    <option value="">Избери език</option>
                                    {languages.map(({id, lang}) => (
                                        <option key={id} value={id}>{lang}</option>
                                    ))}
                                </B.Form.Control>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="listLink">
                                <B.Form.Label>Линк</B.Form.Label>
                                <B.Form.Control type="text" name="listLink" value={formsData['listLink'] || ''}
                                                onChange={ev => this.handleInputChange(ev)}
                                                placeholder="https://www.google.bg или /page/name"
                                                required={formsData['columnHasLinks']}
                                                disabled={!formsData['columnHasLinks']}/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon='plus' fixedWidth className="mr-2"/>
                                    Добави към колона
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
                {/* Quote Modal */}
                <B.Modal id='quote'
                         centered
                         show={modalShow['quote']}
                         onHide={() => this.modalHide('quote', true)}
                         aria-labelledby="quoteTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="quoteTitle">Цитат</B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            onSubmit={ev => this.editQuote(ev)}
                            noValidate
                            validated={validated}>
                            <B.Form.Group controlId="quoteText">
                                <B.Form.Label>Текст</B.Form.Label>
                                <B.Form.Control as="textarea" name="quoteText" value={formsData['quoteText'] || ''}
                                                onChange={ev => this.handleInputChange(ev)} rows="4"/>
                            </B.Form.Group>
                            <B.Form.Group controlId='quoteLanguage'>
                                <B.Form.Label>Език</B.Form.Label>
                                <B.Form.Control as="select" name="quoteLanguage"
                                                value={formsData['quoteLanguage'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)}
                                                className="custom-select" required>
                                    <option value="">Избери език</option>
                                    {languages.map(({id, lang}) => (
                                        <option key={id} value={id}>{lang}</option>
                                    ))}
                                </B.Form.Control>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="quoteStatus">
                                <B.Form.Label>Видим</B.Form.Label>
                                <div className="custom-control custom-switch">
                                    <B.Form.Check type='checkbox'>
                                        <B.Form.Check.Input type='checkbox' name="quoteStatus"
                                                            className="custom-control-input"
                                                            checked={formsData['quoteStatus']}
                                                            onChange={(ev) => this.handleInputChange(ev)}/>
                                        <B.Form.Check.Label
                                            className="custom-control-label">{formsData['quoteStatus'] ? 'Да' : 'Не'}</B.Form.Check.Label>
                                    </B.Form.Check>
                                </div>
                            </B.Form.Group>
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon="save" fixedWidth className="mr-2"/>
                                    Запази промените
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
                {/* Social Modal */}
                <B.Modal id='social'
                         centered
                         size="lg"
                         show={modalShow['social']}
                         onHide={() => this.modalHide('social', true)}
                         aria-labelledby="socialTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="socialTitle">Социални мрежи</B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form onSubmit={ev => this.editSocial(ev)}
                                noValidate
                                validated={validated}>
                            <B.Form.Group>
                                <B.Row noGutters className="mb-3 align-items-center">
                                    <B.Col xs={9} className="mr-2">
                                        <B.InputGroup>
                                            <B.InputGroup.Prepend>
                                                <B.InputGroup.Text id="facebookAddon">
                                                    <FontAwesomeIcon icon={['fab', 'facebook']} size="lg"
                                                                     className="text-primary"/>
                                                </B.InputGroup.Text>
                                            </B.InputGroup.Prepend>
                                            <B.FormControl type="text"
                                                           value={social['facebook'] || ''}
                                                           name="facebook"
                                                           onChange={ev => this.handleSocialChange(ev)}
                                                           placeholder="https://www.facebook.com/username"
                                                           aria-describedby="facebookAddon"/>
                                        </B.InputGroup>
                                    </B.Col>
                                    <B.Col xs={2}>
                                        <B.Form.Check type='checkbox' name="show_facebook"
                                                      id="showFacebook"
                                                      checked={social['show_facebook']}
                                                      label={social['show_facebook'] ? 'Видимо' : 'Скрито'}
                                                      onChange={ev => this.handleSocialChange(ev)}/>
                                    </B.Col>
                                </B.Row>
                            </B.Form.Group>
                            <B.Form.Group>
                                <B.Row noGutters className="mb-3 align-items-center">
                                    <B.Col xs={9} className="mr-2">
                                        <B.InputGroup>
                                            <B.InputGroup.Prepend>
                                                <B.InputGroup.Text id="instagramAddon">
                                                    <FontAwesomeIcon icon={['fab', 'instagram']} size="lg"/>
                                                </B.InputGroup.Text>
                                            </B.InputGroup.Prepend>
                                            <B.FormControl type="text"
                                                           value={social['instagram'] || ''}
                                                           name="instagram"
                                                           onChange={ev => this.handleSocialChange(ev)}
                                                           placeholder="https://www.instagram.com/username"
                                                           aria-describedby="instagramAddon"/>
                                        </B.InputGroup>
                                    </B.Col>
                                    <B.Col xs={2}>
                                        <B.Form.Check type='checkbox' name="show_instagram"
                                                      id="show_instagram"
                                                      checked={social['show_instagram']}
                                                      label={social['show_instagram'] ? 'Видимо' : 'Скрито'}
                                                      onChange={ev => this.handleSocialChange(ev)}/>
                                    </B.Col>
                                </B.Row>
                            </B.Form.Group>
                            <B.Form.Group>
                                <B.Row noGutters className="mb-3 align-items-center">
                                    <B.Col xs={9} className="mr-2">
                                        <B.InputGroup>
                                            <B.InputGroup.Prepend>
                                                <B.InputGroup.Text id="twitterAddon">
                                                    <FontAwesomeIcon icon={['fab', 'twitter']} size="lg"
                                                                     className="text-info"/>
                                                </B.InputGroup.Text>
                                            </B.InputGroup.Prepend>
                                            <B.FormControl type="text"
                                                           value={social['twitter'] || ''}
                                                           name="twitter"
                                                           onChange={ev => this.handleSocialChange(ev)}
                                                           placeholder="https://www.twitter.com/username"
                                                           aria-describedby="twitterAddon"/>
                                        </B.InputGroup>
                                    </B.Col>
                                    <B.Col xs={2}>
                                        <B.Form.Check type='checkbox' name="show_twitter"
                                                      id="show_twitter"
                                                      checked={social['show_twitter']}
                                                      label={social['show_twitter'] ? 'Видимо' : 'Скрито'}
                                                      onChange={ev => this.handleSocialChange(ev)}/>
                                    </B.Col>
                                </B.Row>
                            </B.Form.Group>
                            <B.Form.Group>
                                <B.Row noGutters className="mb-3 align-items-center">
                                    <B.Col xs={9} className="mr-2">
                                        <B.InputGroup>
                                            <B.InputGroup.Prepend>
                                                <B.InputGroup.Text id="youtubeAddon">
                                                    <FontAwesomeIcon icon={['fab', 'youtube']} size="lg"
                                                                     className="text-danger"/>
                                                </B.InputGroup.Text>
                                            </B.InputGroup.Prepend>
                                            <B.FormControl type="text"
                                                           value={social['youtube'] || ''}
                                                           name="youtube"
                                                           onChange={ev => this.handleSocialChange(ev)}
                                                           placeholder="https://www.youtube.com/channel/ID"
                                                           aria-describedby="youtubeAddon"/>
                                        </B.InputGroup>
                                    </B.Col>
                                    <B.Col xs={2}>
                                        <B.Form.Check type='checkbox' name="show_youtube"
                                                      id="show_youtube"
                                                      checked={social['show_youtube']}
                                                      label={social['show_youtube'] ? 'Видимо' : 'Скрито'}
                                                      onChange={ev => this.handleSocialChange(ev)}/>
                                    </B.Col>
                                </B.Row>
                            </B.Form.Group>
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon="save" fixedWidth className="mr-2"/>
                                    Запази промените
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
            </>
        );
    }

    render() {
        const {loaded, error} = this.state;
        const {languages} = this.props;

        if (error) {
            return (
                <FooterLayout modalShow={(id) => this.modalOpen(id)}>
                    <B.Alert variant='danger'>
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                    {this.drawModals()}
                </FooterLayout>
            );
        } else if (!loaded) {
            return (
                <FooterLayout modalShow={(id) => this.modalOpen(id)}>
                    <B.ProgressBar animated now={100} label="Loading..."/>
                    {this.drawModals()}
                </FooterLayout>
            );
        } else {
            return (
                <FooterLayout modalShow={(id) => this.modalOpen(id)}>
                    {languages.map(({id, lang}) => (
                        this.drawFooter(id, lang)
                    ))}
                    {this.drawModals()}
                </FooterLayout>
            );
        }
    }
}

export default SettingsFooter;

const FooterLayout = props => {
    const {children, modalShow} = props;

    return (
        <div id="footer" className="footer-wrapper mt-4">
            <div className="page-header mb-3">
                <h4 className="d-inline-block">Футър</h4>
                <div className="d-inline-block float-right">
                    <B.Button className="btn btn-light mr-2" onClick={() => modalShow('quote')}>
                        <FontAwesomeIcon icon='quote-right' fixedWidth/>
                        <span className="d-none d-md-inline-block ml-2">Цитат</span>
                    </B.Button>
                    <B.Button className="btn btn-light mr-2" onClick={() => modalShow('social')}>
                        <FontAwesomeIcon icon={['fab', 'facebook']} className='text-primary' fixedWidth/>
                        <span className="d-none d-md-inline-block ml-2">Социални мрежи</span>
                    </B.Button>
                    <B.Button className="btn btn-light" onClick={() => modalShow('columns')}>
                        <FontAwesomeIcon icon='plus' className='text-success' fixedWidth/>
                        <span className="d-none d-md-inline-block ml-2">Добави колона</span>
                    </B.Button>
                </div>
            </div>
            <div className="clearfix"/>
            {children}
        </div>
    );
};
