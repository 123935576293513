import React, {Component} from 'react';
import * as B from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {get} from '../API';

//Import Layout
import PageTitle from "./layout/PageTitle";

import LiveStream from "./LiveStream";
import Countdown from './Countdown';
import {lang} from "../lang/all";

class Live extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLive: false,
            liveFrame: null
        };
        this.API_URL = '/api/fb';
    }

    componentDidMount() {
        const {isLoaded} = this.props;
        isLoaded(true);
        this.loadStreams();
    }

    calculateDate() {
        const date = new Date();
        let x = 0;

        if ((date.getDay() >= 0 && date.getDay() < 3) || (date.getDay() === 3 && date.getHours() <= 18 && date.getMinutes() <= 30)) {
            x = 3;
            date.setHours(18, 30, 0);
        } else {
            date.setHours(10, 30, 0);
        }

        date.setDate(date.getDate() + (x + (7 - date.getDay())) % 7);

        return date.toString();
    }

    loadStreams() {
        this.setState({isLive: false});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    if (data.length) {
                        const live = data.filter(({status}) => status === 1);

                        if (live.length) {
                            this.setState({
                                isLive: true,
                                liveFrame: live[0]['data']
                            });
                        }
                    }
                }
            })
            .catch(error => console.error(error));
    }

    render() {
        const {isLive, liveFrame} = this.state;
        const {language, onFBClick, fbLogin} = this.props;
        let fbTitle = lang[language].fbTitleAuthorise;
        if (fbLogin === 'unknown')
            fbTitle = lang[language].fbTitleLogin;

        return (
            <main id="app" className="pb-3" role="main">
                <PageTitle title={lang[language].live}>
                    <div className="d-md-inline-block mb-2 mb-md-0 mx-2">
                        <FontAwesomeIcon icon="church" fixedWidth className="mr-1"/>
                        {lang[language].liveSubtitleSunday}
                    </div>
                    <div className="d-md-inline-block mb-2 ml-2 ml-md-0">
                        <FontAwesomeIcon icon="praying-hands" fixedWidth className="mr-1"/>
                        {lang[language].liveSubtitleWednesday}
                    </div>
                </PageTitle>
                <B.Container as='section' className="py-3">
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe src="https://www.youtube.com/embed/live_stream?channel=UCOXyDd8vIw7mw8tlzgs9B7Q?autoplay=1" className="embed-responsive-item" allowFullScreen allow="autoplay; fullscreen" style={{border: 0}}></iframe>
                    </div>
                </B.Container>
            </main>
        );
    }
}

export default Live;
