import React, { Component } from "react";
import Alerts from "./Alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as B from "react-bootstrap";
import { get, post, postFile } from "../../API";
import { lang } from "../../lang/all";
// import {WithContext as ReactTags} from 'react-tag-input';

const KeyCodes = {
    space: 32,
    enter: 13
};

export default class Sermons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            alerts: [],
            modalShow: {
                sermons: false,
                series: false,
                books: false,
                speakers: false,
                tags: false
            },
            validated: false,
            formsData: {},
            sermonEditID: null,
            sermons: [],
            books: [],
            speakers: [],
            series: [],
            filters: {
                speaker: 0,
                series: 0,
                book: 0,
                year: 0
            },
            sortBy: 0,
            currentPage: 1,
            tags: [],
            tagsTemp: [],
            uploading: false
        };
        this.MEDIA_PLACEHOLDER = "/images/media_placeholder.jpg";
        this.API_URL = "/api/sermons";
        this.API_KEYWORDS_URL = "/api/keywords";
        this.SERMONS_FUNC = {
            addSermon: 1,
            editSermon: 2,
            deleteSermon: 3,
            addBook: 4,
            editBook: 5,
            deleteBook: 6,
            addSpeaker: 7,
            editSpeaker: 8,
            deleteSpeaker: 9,
            addSeries: 10,
            editSeries: 11,
            deleteSeries: 12,
            loadSeries: 13,
            loadBooks: 14,
            loadSpeakers: 15,
            updateTags: 16,
            getDates: 17
        };
        this.KEYWORDS_FUNC = {
            add: 1,
            edit: 2,
            delete: 3
        };
        this.TAGS_DELIMITERS = [KeyCodes.space, KeyCodes.enter];
    }

    componentWillMount() {
        this.loadDates();
        this.loadBooks();
        this.loadSeries();
        this.loadSpeakers();
        this.loadTags();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.currentPage !== this.state.currentPage)
            this.loadSermons();
    }

    modalOpen(id) {
        const modalShow = { ...this.state.modalShow };
        modalShow[id] = true;

        this.setState({ modalShow });
    }

    modalHide(id, reset) {
        const modalShow = { ...this.state.modalShow };
        modalShow[id] = false;

        if (reset) {
            const formsData = { ...this.state.formsData };
            formsData["sermonMediaThumb"] = null;
            formsData["sermonMedia"] = null;
            formsData["sermonTitle"] = null;
            formsData["sermonDate"] = null;
            formsData["sermonBook"] = null;
            formsData["sermonReference"] = null;
            formsData["sermonSpeaker"] = null;
            formsData["sermonSeries"] = null;
            formsData["sermonCover"] = null;
            formsData["sermonCoverName"] = null;
            formsData["sermonAttachment"] = null;
            formsData["sermonAttachmentName"] = null;
            formsData["seriesNew"] = null;
            formsData["speakerNew"] = null;
            formsData["bookNew"] = null;
            formsData["tagNew"] = null;
            this.setState({
                modalShow,
                validated: false,
                formsData,
                sermonEditID: null,
                tagsTemp: []
            });
            return;
        }
        this.setState({ modalShow });
    }

    handleInputChange(event) {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const formsData = { ...this.state.formsData };

        formsData[name] = value;

        this.setState({ formsData });
    }

    handleFileInputChange(event, allowedFormats = null) {
        const target = event.target;
        const value = target.files[0];
        const name = target.name;
        const type = value.type;

        if (allowedFormats && !allowedFormats.includes(type)) {
            let alerts = [...this.state.alerts];
            alerts.push({ type: "danger", text: "Неподдържан формат!" });
            this.setState({ alerts });
            return;
        }

        const formsData = { ...this.state.formsData };
        formsData[name] = value;

        this.setState({ formsData });
    }

    handleCloseAlert(alert) {
        const currentAlerts = [...this.state.alerts];
        const alerts = currentAlerts.filter(al => al !== alert);
        this.setState({ alerts });
    }

    handleSeriesChange(ev, id) {
        const target = ev.target;
        const value = target.value;
        const series = [...this.state.series];
        const thisSeries = series.filter(series => series["series_id"] === id);
        const index = thisSeries.length ? series.indexOf(thisSeries[0]) : null;
        if (index !== null) series[index]["series"] = value;

        this.setState({ series });
    }

    handleSpeakersChange(ev, id) {
        const target = ev.target;
        const value = target.value;
        const speakers = [...this.state.speakers];
        const thisSpeaker = speakers.filter(
            speaker => speaker["speaker_id"] === id
        );
        const index = thisSpeaker.length
            ? speakers.indexOf(thisSpeaker[0])
            : null;
        if (index !== null) speakers[index]["name"] = value;

        this.setState({ speakers });
    }

    handleBooksChange(ev, id) {
        const target = ev.target;
        const value = target.value;
        const books = [...this.state.books];
        const thisBook = books.filter(book => book["book_id"] === id);
        const index = thisBook.length ? books.indexOf(thisBook[0]) : null;
        if (index !== null) books[index]["book"] = value;

        this.setState({ books });
    }

    handleTagsChange(ev, id) {
        const target = ev.target;
        const value = target.value;
        const tags = [...this.state.tags];
        const thisTag = tags.filter(tag => tag["id"] === id);
        const index = thisTag.length ? tags.indexOf(thisTag[0]) : null;
        if (index !== null) tags[index]["name"] = value;

        this.setState({ tags });
    }

    handlePagination(page) {
        const currentPage = page < 0 ? 1 : page;
        this.setState({ currentPage });
    }

    handleFilters(ev) {
        const target = ev.target;
        const name = target.name;
        const value = target.value;
        const { filters } = this.state;
        filters[name] = value;

        if (name !== "year") filters["year"] = null;

        this.setState({ filters, currentPage: 1 });
        this.loadSermons("filters", filters);
    }

    handleSort(ev) {
        const target = ev.target;
        const value = target.value;

        this.setState({ sortBy: value });
        this.loadSermons("sort", value);
    }

    checkTag(tag) {
        const { tags } = this.state;

        return tags.filter(t => t.name === tag.name).length;
    }

    handleAddTag(tag) {
        const { tagsTemp } = this.state;
        const newTags = [...tagsTemp, tag];

        if (this.checkTag(tag) === 0) this.addTagSecondary(tag.name);

        this.setState({ tagsTemp: newTags });
    }

    handleDeleteTag(i) {
        const { tagsTemp } = this.state;
        this.setState({
            tagsTemp: tagsTemp.filter((tag, index) => index !== i)
        });
    }

    loadDates() {
        this.setState({ loaded: false, error: null });

        const params = {};
        const data = { auth: { func: this.SERMONS_FUNC.getDates }, params };
        post(this.API_URL, data)
            .then(data => {
                if (data["response"] !== false) {
                    this.setState({
                        minYear: data["min_value"],
                        maxYear: data["max_value"]
                    });

                    this.loadSermons();
                } else {
                    this.setState({
                        minYear: 0,
                        maxYear: 0
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    loadSermons(action = null, params = {}) {
        this.setState({ loaded: false, error: null });

        const { filters, sortBy, currentPage } = this.state;
        const urlParams = new URLSearchParams();
        urlParams.set("page", currentPage);

        for (const [filter, value] of Object.entries(filters)) {
            if (value) {
                urlParams.set(filter, value);
            }
        }
        if (sortBy) {
            urlParams.set("sort", sortBy);
        }

        const url = `${this.API_URL}?${urlParams.toString()}`;

        get(url)
            .then(data => {
                if (data["response"] !== false) {
                    this.setState({
                        sermons: data["sermons"],
                        totalSermons: data["total_sermons"],
                        loaded: true,
                        error: null
                    });
                } else {
                    this.setState({
                        loaded: true,
                        error: data["message"],
                        sermons: [],
                        totalSermons: 0
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    loadBooks() {
        const params = {};
        const data = { auth: { func: this.SERMONS_FUNC.loadBooks }, params };
        post(this.API_URL, data)
            .then(data => {
                if (data["response"] !== false) {
                    this.setState({
                        books: data
                    });
                } else {
                    this.setState({
                        books: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    loadSeries() {
        const params = {};
        const data = { auth: { func: this.SERMONS_FUNC.loadSeries }, params };
        post(this.API_URL, data)
            .then(data => {
                if (data["response"] !== false) {
                    this.setState({
                        series: data
                    });
                } else {
                    this.setState({
                        series: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    loadSpeakers() {
        const params = {};
        const data = { auth: { func: this.SERMONS_FUNC.loadSpeakers }, params };
        post(this.API_URL, data)
            .then(data => {
                if (data["response"] !== false) {
                    this.setState({
                        speakers: data
                    });
                } else {
                    this.setState({
                        speakers: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    loadTags() {
        get(this.API_KEYWORDS_URL)
            .then(data => {
                if (data["response"] !== false) {
                    const tags = [];
                    data.map(({ id, name }) =>
                        tags.push({ id: id.toString(), name })
                    );
                    this.setState({ tags });
                } else {
                    this.setState({
                        tags: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    getSermonData(sermonEditID) {
        this.setState({ sermonEditID });

        get(`${this.API_URL}/${sermonEditID}`)
            .then(data => {
                if (data["response"] !== false) {
                    const sermonDate = data["uploaded_at"]
                        .split(" ")[0]
                        .split("-");
                    const date = new Date(
                        sermonDate[2],
                        sermonDate[1] - 1,
                        sermonDate[0]
                    );
                    const tagsTemp = [];
                    const formsData = { ...this.state.formsData };
                    formsData["sermonMediaThumb"] = data["media"];
                    formsData["sermonTitle"] = data["title"];
                    formsData["sermonDate"] = date
                        .toISOString()
                        .replace(/T.*/, "");
                    formsData["sermonBook"] = data["book"];
                    formsData["sermonReference"] = data["reference"];
                    formsData["sermonSpeaker"] = data["speaker"];
                    formsData["sermonSeries"] = data["series"];
                    formsData["sermonCoverName"] =
                        data["cover"] === "/storage/" ? null : data["cover"];
                    formsData["sermonAttachmentName"] =
                        data["attachment"] === "/storage/"
                            ? null
                            : data["attachment"];

                    if (
                        data["sermon_keywords"] &&
                        data["sermon_keywords"].hasOwnProperty(sermonEditID) &&
                        data["sermon_keywords"][sermonEditID].length
                    ) {
                        data["sermon_keywords"][sermonEditID].map(
                            ({ id, keyword }) =>
                                tagsTemp.push({
                                    id: id.toString(),
                                    name: keyword
                                })
                        );
                    }

                    this.setState({ formsData, tagsTemp });
                    this.modalOpen("sermons");
                } else {
                    const alerts = [...this.state.alerts];
                    alerts.push({ type: "danger", text: data["message"] });
                    this.setState({
                        loaded: true,
                        alerts
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    addEditSermon(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({ validated: true });

        if (form.checkValidity()) {
            this.setState({ uploading: true });
            const { sermonEditID, tagsTemp } = this.state;
            const {
                sermonMedia,
                sermonTitle,
                sermonDate,
                sermonBook,
                sermonReference,
                sermonSpeaker,
                sermonSeries,
                sermonAttachment,
                sermonCover
            } = this.state.formsData;
            const func = sermonEditID
                ? this.SERMONS_FUNC.editSermon
                : this.SERMONS_FUNC.addSermon;
            const userDate = sermonDate.split("-");
            const date = new Date(userDate[0], userDate[1] - 1, userDate[2]);
            const uploaded_at = `${date.getDate()}-${date.getMonth() +
                1}-${date.getFullYear()} 00:00:00`;
            console.log({ sermonDate, userDate, date, uploaded_at });
            const keywords = [];
            if (tagsTemp.length) tagsTemp.map(({ id }) => keywords.push(id));
            const params = {
                sermon_id: sermonEditID,
                title: sermonTitle,
                book: sermonBook,
                reference: sermonReference,
                speaker: sermonSpeaker,
                series: sermonSeries,
                uploaded_at,
                keywords
            };
            const formData = new FormData();
            formData.append("auth", JSON.stringify({ func }));
            formData.append("params", JSON.stringify(params));
            if (sermonMedia) formData.append("media", sermonMedia);
            if (sermonAttachment)
                formData.append("attachment", sermonAttachment);
            if (sermonCover) formData.append("cover", sermonCover);

            if (sermonEditID) this.updateTags(keywords);

            postFile(this.API_URL, formData, true)
                .then(data => {
                    if (data["response"] === true) {
                        this.modalHide("sermons", true);

                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            loaded: true,
                            alerts,
                            validated: false,
                            uploading: false
                        });

                        this.loadSermons();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "danger", text: data["message"] });
                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    deleteSermon(sermon_id) {
        if (confirm("Сигурни ли сте, че искате да изтриете тази проповед?")) {
            this.setState({ loaded: false });
            const params = { sermon_id };
            const data = {
                auth: { func: this.SERMONS_FUNC.deleteSermon },
                params
            };

            post(this.API_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts
                        });
                        this.loadSermons();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    addBook(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({ validated: true });

        if (form.checkValidity()) {
            const book = this.state.formsData["bookNew"];
            const params = { book };
            const data = { auth: { func: this.SERMONS_FUNC.addBook }, params };

            post(this.API_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const formsData = { ...this.state.formsData };
                        const alerts = [...this.state.alerts];
                        formsData["bookNew"] = null;
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts,
                            validated: false,
                            formsData
                        });

                        this.loadBooks();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    editBook(book_id) {
        const { books } = this.state;
        const thisBook = books.filter(book => book["book_id"] === book_id);

        if (thisBook.length) {
            const params = { book_id, book: thisBook[0].book };
            const data = { auth: { func: this.SERMONS_FUNC.editBook }, params };

            post(this.API_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts,
                            validated: false
                        });
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    deleteBook(book_id) {
        if (confirm("Сигурни ли сте, че искате да изтриете тази книга?")) {
            this.setState({ loaded: false });
            const params = { book_id };
            const data = {
                auth: { func: this.SERMONS_FUNC.deleteBook },
                params
            };

            post(this.API_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts
                        });
                        this.loadBooks();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    addSpeaker(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({ validated: true });

        if (form.checkValidity()) {
            const name = this.state.formsData["speakerNew"];
            const params = { name };
            const data = {
                auth: { func: this.SERMONS_FUNC.addSpeaker },
                params
            };

            post(this.API_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const formsData = { ...this.state.formsData };
                        const alerts = [...this.state.alerts];
                        formsData["speakerNew"] = null;
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts,
                            validated: false,
                            formsData
                        });

                        this.loadSpeakers();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    editSpeaker(speaker_id) {
        const { speakers } = this.state;
        const thisSpeaker = speakers.filter(
            speaker => speaker["speaker_id"] === speaker_id
        );

        if (thisSpeaker.length) {
            const params = { speaker_id, name: thisSpeaker[0].name };
            const data = {
                auth: { func: this.SERMONS_FUNC.editSpeaker },
                params
            };

            post(this.API_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts,
                            validated: false
                        });
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    deleteSpeaker(speaker_id) {
        if (confirm("Сигурни ли сте, че искате да изтриете този говорител?")) {
            this.setState({ loaded: false });
            const params = { speaker_id };
            const data = {
                auth: { func: this.SERMONS_FUNC.deleteSpeaker },
                params
            };

            post(this.API_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts
                        });
                        this.loadSpeakers();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    addSeries(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({ validated: true });

        if (form.checkValidity()) {
            const series = this.state.formsData["seriesNew"];
            const params = { series };
            const data = {
                auth: { func: this.SERMONS_FUNC.addSeries },
                params
            };

            post(this.API_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const formsData = { ...this.state.formsData };
                        const alerts = [...this.state.alerts];
                        formsData["seriesNew"] = null;
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts,
                            validated: false,
                            formsData
                        });

                        this.loadSeries();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    editSeries(series_id) {
        const { series } = this.state;
        const thisSeries = series.filter(
            series => series["series_id"] === series_id
        );

        if (thisSeries.length) {
            const params = { series_id, series: thisSeries[0].series };
            const data = {
                auth: { func: this.SERMONS_FUNC.editSeries },
                params
            };

            post(this.API_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts,
                            validated: false
                        });
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    deleteSeries(series_id) {
        if (confirm("Сигурни ли сте, че искате да изтриете тази серия?")) {
            this.setState({ loaded: false });
            const params = { series_id };
            const data = {
                auth: { func: this.SERMONS_FUNC.deleteSeries },
                params
            };

            post(this.API_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts
                        });
                        this.loadSeries();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    addTag(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({ validated: true });

        if (form.checkValidity()) {
            const keyword = this.state.formsData["tagNew"];
            const params = { keyword };
            const data = { auth: { func: this.KEYWORDS_FUNC.add }, params };

            post(this.API_KEYWORDS_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const formsData = { ...this.state.formsData };
                        const alerts = [...this.state.alerts];
                        formsData["tagNew"] = null;
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts,
                            validated: false,
                            formsData
                        });

                        this.loadTags();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    addTagSecondary(keyword) {
        const params = { keyword };
        const data = { auth: { func: this.KEYWORDS_FUNC.add }, params };

        post(this.API_KEYWORDS_URL, data, true)
            .then(data => {
                if (data["response"] === true) {
                    this.loadTags();
                    const tagsTemp = [...this.state.tagsTemp];
                    const tagForChange = tagsTemp.filter(
                        t => t["name"] === keyword
                    );
                    const index = tagsTemp.indexOf(tagForChange[0]);
                    tagsTemp[index]["id"] = data["record_id"].toString();
                    this.setState({ tagsTemp });
                } else {
                    const alerts = [...this.state.alerts];
                    alerts.push({ type: "danger", text: data["message"] });

                    this.setState({
                        loaded: true,
                        alerts
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    editTag(keyword_id) {
        const { tags } = this.state;
        const thisTags = tags.filter(tag => tag["id"] === keyword_id);

        if (thisTags.length) {
            const params = { keyword_id, keyword: thisTags[0].name };
            const data = { auth: { func: this.KEYWORDS_FUNC.edit }, params };

            post(this.API_KEYWORDS_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts,
                            validated: false
                        });
                    } else {
                        this.setState({
                            loaded: true,
                            error: data["message"]
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    deleteTag(keyword_id) {
        if (confirm("Сигурни ли сте, че искате да изтриете този таг?")) {
            const params = { keyword_id };
            const data = { auth: { func: this.KEYWORDS_FUNC.delete }, params };

            post(this.API_KEYWORDS_URL, data, true)
                .then(data => {
                    if (data["response"] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "success", text: data["message"] });
                        this.setState({
                            alerts
                        });
                        this.loadTags();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "danger", text: data["message"] });
                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    updateTags(keywords) {
        if (keywords.length) {
            const { sermonEditID } = this.state;
            const params = { sermon_id: sermonEditID, keywords };
            const data = {
                auth: { func: this.SERMONS_FUNC.updateTags },
                params
            };

            post(this.API_URL, data, true)
                .then(data => {
                    if (data["response"] === false) {
                        const alerts = [...this.state.alerts];
                        alerts.push({ type: "danger", text: data["message"] });
                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error:
                            "Възникна неочаквана грешка! Моля, опитайте отново."
                    });
                });
        }
    }

    drawModals() {
        const {
            formsData,
            validated,
            modalShow,
            sermonEditID,
            books,
            speakers,
            series,
            tags,
            tagsTemp,
            uploading
        } = this.state;
        let media = null;

        if (formsData["sermonMediaThumb"]) {
            media = (
                <video className="slide-video w-100" controls>
                    <source
                        src={formsData["sermonMediaThumb"]}
                        type="video/mp4"
                    />
                </video>
            );
        }

        return (
            <>
                {/* Add/Edit Sermon Modal */}
                <B.Modal
                    id="sermons"
                    centered
                    size="lg"
                    show={modalShow["sermons"]}
                    onHide={() => this.modalHide("sermons", true)}
                    aria-labelledby="sermonsTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="sermonsTitle">
                            {sermonEditID ? "Редактирай" : "Добави"} проповед
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            noValidate
                            validated={validated}
                            onSubmit={e => this.addEditSermon(e)}
                            method="POST"
                            encType="multipart/form-data"
                        >
                            <B.Form.Group controlId="sermonMedia">
                                <label>Медия</label>
                                {media ? (
                                    <div className="w-25 mb-2 mx-auto">
                                        {media}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div className="custom-file">
                                    <B.Form.Control
                                        type="file"
                                        name="sermonMedia"
                                        className="custom-file-input"
                                        onChange={ev =>
                                            this.handleFileInputChange(ev, [
                                                "video/mp4",
                                                "video/ogg"
                                            ])
                                        }
                                        accept="video/mp4, video/ogg"
                                        lang="bg"
                                        required={!sermonEditID}
                                    />
                                    <B.Form.Label className="custom-file-label">
                                        {formsData["sermonMedia"] ? (
                                            formsData["sermonMedia"]["name"]
                                        ) : (
                                            <div>
                                                <span className="d-md-none">
                                                    Видео
                                                </span>
                                                <span className="d-none d-md-block">
                                                    .mp4, .ogg
                                                </span>
                                            </div>
                                        )}
                                    </B.Form.Label>
                                </div>
                                <B.FormText className="text-muted d-md-none">
                                    .mp4, .ogg
                                </B.FormText>
                                <B.Form.Control.Feedback type="invalid">
                                    Полето е задължително!
                                </B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="sermonTitle">
                                <B.Form.Label>Заглавие</B.Form.Label>
                                <B.Form.Control
                                    type="text"
                                    name="sermonTitle"
                                    value={formsData["sermonTitle"] || ""}
                                    onChange={ev => this.handleInputChange(ev)}
                                    required
                                />
                                <B.Form.Control.Feedback type="invalid">
                                    Полето е задължително!
                                </B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="sermonDate">
                                <B.Form.Label>Дата</B.Form.Label>
                                <B.Form.Control
                                    type="date"
                                    name="sermonDate"
                                    value={formsData["sermonDate"] || ""}
                                    onChange={ev => this.handleInputChange(ev)}
                                    required
                                />
                                <B.Form.Control.Feedback type="invalid">
                                    Полето е задължително!
                                </B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Row>
                                <B.Col xs={12} md={6}>
                                    <B.Form.Group controlId="sermonBook">
                                        <B.Form.Label>Книга</B.Form.Label>
                                        <B.Form.Control
                                            as="select"
                                            name="sermonBook"
                                            className="custom-select"
                                            value={
                                                formsData["sermonBook"] || ""
                                            }
                                            onChange={ev =>
                                                this.handleInputChange(ev)
                                            }
                                        >
                                            <option value="">
                                                Моля, изберете
                                            </option>
                                            {books
                                                ? books.map(book => (
                                                      <option
                                                          key={book["book_id"]}
                                                          value={
                                                              book["book_id"]
                                                          }
                                                      >
                                                          {book.book}
                                                      </option>
                                                  ))
                                                : ""}
                                        </B.Form.Control>
                                        <B.FormText className="text-muted">
                                            Полето не е задължително
                                        </B.FormText>
                                    </B.Form.Group>
                                </B.Col>
                                <B.Col xs={12} md={6}>
                                    <B.Form.Group controlId="sermonReference">
                                        <B.Form.Label>Препратка</B.Form.Label>
                                        <B.Form.Control
                                            type="text"
                                            name="sermonReference"
                                            value={
                                                formsData["sermonReference"] ||
                                                ""
                                            }
                                            onChange={ev =>
                                                this.handleInputChange(ev)
                                            }
                                            placeholder="1:25"
                                        />
                                        <B.FormText className="text-muted">
                                            Полето не е задължително
                                        </B.FormText>
                                    </B.Form.Group>
                                </B.Col>
                            </B.Row>
                            <B.Row>
                                <B.Col xs={12} md={6}>
                                    <B.Form.Group controlId="sermonSpeaker">
                                        <B.Form.Label>Говорител</B.Form.Label>
                                        <B.Form.Control
                                            as="select"
                                            name="sermonSpeaker"
                                            className="custom-select"
                                            value={
                                                formsData["sermonSpeaker"] || ""
                                            }
                                            onChange={ev =>
                                                this.handleInputChange(ev)
                                            }
                                        >
                                            <option value="">
                                                Моля, изберете
                                            </option>
                                            {speakers
                                                ? speakers.map(speakers => (
                                                      <option
                                                          key={
                                                              speakers[
                                                                  "speaker_id"
                                                              ]
                                                          }
                                                          value={
                                                              speakers[
                                                                  "speaker_id"
                                                              ]
                                                          }
                                                      >
                                                          {speakers.name}
                                                      </option>
                                                  ))
                                                : ""}
                                        </B.Form.Control>
                                        <B.FormText className="text-muted">
                                            Полето не е задължително
                                        </B.FormText>
                                    </B.Form.Group>
                                </B.Col>
                                <B.Col xs={12} md={6}>
                                    <B.Form.Group controlId="sermonSeries">
                                        <B.Form.Label>Серия</B.Form.Label>
                                        <B.Form.Control
                                            as="select"
                                            name="sermonSeries"
                                            className="custom-select"
                                            value={
                                                formsData["sermonSeries"] || ""
                                            }
                                            onChange={ev =>
                                                this.handleInputChange(ev)
                                            }
                                        >
                                            <option value="">
                                                Моля, изберете
                                            </option>
                                            {series.length
                                                ? series.map(series => (
                                                      <option
                                                          key={
                                                              series[
                                                                  "series_id"
                                                              ]
                                                          }
                                                          value={
                                                              series[
                                                                  "series_id"
                                                              ]
                                                          }
                                                      >
                                                          {series.series}
                                                      </option>
                                                  ))
                                                : ""}
                                        </B.Form.Control>
                                        <B.FormText className="text-muted">
                                            Полето не е задължително
                                        </B.FormText>
                                    </B.Form.Group>
                                </B.Col>
                            </B.Row>
                            <B.Row>
                                <B.Col xs={12} md={6}>
                                    <B.Form.Group controlId="sermonCover">
                                        <label>Прикачи снимка</label>
                                        <div className="custom-file">
                                            <B.Form.Control
                                                type="file"
                                                name="sermonCover"
                                                className="custom-file-input"
                                                onChange={ev =>
                                                    this.handleFileInputChange(
                                                        ev
                                                    )
                                                }
                                                lang="bg"
                                            />
                                            <B.Form.Label className="custom-file-label">
                                                {formsData["sermonCover"] ? (
                                                    formsData["sermonCover"][
                                                        "name"
                                                    ]
                                                ) : formsData[
                                                      "sermonCoverName"
                                                  ] ? (
                                                    formsData["sermonCoverName"]
                                                ) : (
                                                    <div>Всички формати</div>
                                                )}
                                            </B.Form.Label>
                                        </div>
                                        <B.FormText className="text-muted">
                                            Полето не е задължително
                                        </B.FormText>
                                    </B.Form.Group>
                                </B.Col>
                                <B.Col xs={12} md={6}>
                                    <B.Form.Group controlId="sermonAttachment">
                                        <label>Прикачи файл</label>
                                        <div className="custom-file">
                                            <B.Form.Control
                                                type="file"
                                                name="sermonAttachment"
                                                className="custom-file-input"
                                                onChange={ev =>
                                                    this.handleFileInputChange(
                                                        ev
                                                    )
                                                }
                                                lang="bg"
                                            />
                                            <B.Form.Label className="custom-file-label">
                                                {formsData[
                                                    "sermonAttachment"
                                                ] ? (
                                                    formsData[
                                                        "sermonAttachment"
                                                    ]["name"]
                                                ) : formsData[
                                                      "sermonAttachmentName"
                                                  ] ? (
                                                    formsData[
                                                        "sermonAttachmentName"
                                                    ]
                                                ) : (
                                                    <div>Всички формати</div>
                                                )}
                                            </B.Form.Label>
                                        </div>
                                        <B.FormText className="text-muted">
                                            Полето не е задължително
                                        </B.FormText>
                                    </B.Form.Group>
                                </B.Col>
                            </B.Row>
                            <B.Form.Group controlId="sermonTags">
                                <B.Form.Label>Тагове</B.Form.Label>
                                {/* {tags.length ?
                                    <ReactTags tags={tagsTemp}
                                               suggestions={tags}
                                               labelField={'name'}
                                               name="sermonTags"
                                               placeholder="Въведи таг и натисни Enter или Space"
                                               handleDelete={i => this.handleDeleteTag(i)}
                                               handleAddition={tag => this.handleAddTag(tag)}
                                               delimiters={this.TAGS_DELIMITERS}
                                               allowDragDrop={false}
                                               inline={false}
                                               inputFieldPosition="bottom"
                                               classNames={{
                                                   tags: '',
                                                   tagInput: 'tag-input-wrapper',
                                                   tagInputField: 'form-control',
                                                   selected: 'tags-wrapper',
                                                   tag: 'tag p-2 mr-2 mb-2 border-1 bg-info text-white',
                                                   remove: 'remove-tag ml-1',
                                                   suggestions: 'suggestions',
                                                   activeSuggestion: 'suggestion-active'
                                               }}
                                    /> :
                                    <B.Alert variant="info">Няма въведени тагове</B.Alert>
                                } */}

                                <B.FormText className="text-muted">
                                    Полето не е задължително
                                </B.FormText>
                            </B.Form.Group>
                            <B.Form.Group className="text-center">
                                <B.Button
                                    type="submit"
                                    variant="primary"
                                    disabled={uploading}
                                >
                                    {uploading ? (
                                        <>
                                            <FontAwesomeIcon
                                                icon="spinner"
                                                spin
                                                fixedWidth
                                                className="mr-2"
                                            />
                                            Качва се...
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon
                                                icon={
                                                    sermonEditID
                                                        ? "edit"
                                                        : "plus"
                                                }
                                                fixedWidth
                                                className="mr-2"
                                            />
                                            {sermonEditID
                                                ? "Редактирай"
                                                : "Добави"}{" "}
                                            проповед
                                        </>
                                    )}
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
                {/* Series Modal */}
                <B.Modal
                    id="series"
                    centered
                    show={modalShow["series"]}
                    onHide={() => this.modalHide("series", true)}
                    aria-labelledby="seriesTitle"
                    className="body-scroll"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="seriesTitle">Серии</B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form onSubmit={ev => ev.preventDefault()}>
                            {series.length === 0 ? (
                                <B.Alert variant="info">
                                    Няма въведени серии
                                </B.Alert>
                            ) : (
                                series.map(series => {
                                    return (
                                        <B.Form.Group key={series["series_id"]}>
                                            <B.Form.Row>
                                                <B.Col>
                                                    <B.Form.Control
                                                        type="text"
                                                        value={
                                                            series.series || ""
                                                        }
                                                        onChange={ev =>
                                                            this.handleSeriesChange(
                                                                ev,
                                                                series[
                                                                    "series_id"
                                                                ]
                                                            )
                                                        }
                                                    />
                                                </B.Col>
                                                <B.Col>
                                                    <B.Button
                                                        type="button"
                                                        variant="primary"
                                                        className="mr-2"
                                                        onClick={() =>
                                                            this.editSeries(
                                                                series[
                                                                    "series_id"
                                                                ]
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="edit"
                                                            fixedWidth
                                                        />
                                                    </B.Button>
                                                    <B.Button
                                                        type="button"
                                                        variant="danger"
                                                        onClick={() =>
                                                            this.deleteSeries(
                                                                series[
                                                                    "series_id"
                                                                ]
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="trash-alt"
                                                            fixedWidth
                                                        />
                                                    </B.Button>
                                                </B.Col>
                                            </B.Form.Row>
                                        </B.Form.Group>
                                    );
                                })
                            )}
                        </B.Form>
                    </B.Modal.Body>
                    <B.Modal.Footer>
                        <B.Form
                            className="w-100"
                            onSubmit={ev => this.addSeries(ev)}
                            noValidate
                            validated={validated}
                        >
                            <B.Form.Group>
                                <B.Form.Row>
                                    <B.Col>
                                        <B.Form.Control
                                            type="text"
                                            name="seriesNew"
                                            value={formsData["seriesNew"] || ""}
                                            onChange={ev =>
                                                this.handleInputChange(ev)
                                            }
                                            required
                                        />
                                        <B.Form.Control.Feedback type="invalid">
                                            Полето е задължително!
                                        </B.Form.Control.Feedback>
                                    </B.Col>
                                    <B.Col>
                                        <B.Button
                                            type="submit"
                                            variant="primary"
                                        >
                                            <FontAwesomeIcon
                                                icon="plus"
                                                fixedWidth
                                                className="mr-2"
                                            />
                                            Добави серия
                                        </B.Button>
                                    </B.Col>
                                </B.Form.Row>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Footer>
                </B.Modal>
                {/* Speakers Modal */}
                <B.Modal
                    id="speakers"
                    centered
                    show={modalShow["speakers"]}
                    onHide={() => this.modalHide("speakers", true)}
                    aria-labelledby="speakersTitle"
                    className="body-scroll"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="speakersTitle">
                            Говорители
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form onSubmit={ev => ev.preventDefault()}>
                            {speakers.length === 0 ? (
                                <B.Alert variant="info">
                                    Няма въведени говорители
                                </B.Alert>
                            ) : (
                                speakers.map(speaker => {
                                    return (
                                        <B.Form.Group
                                            key={speaker["speaker_id"]}
                                        >
                                            <B.Form.Row>
                                                <B.Col>
                                                    <B.Form.Control
                                                        type="text"
                                                        value={
                                                            speaker.name || ""
                                                        }
                                                        onChange={ev =>
                                                            this.handleSpeakersChange(
                                                                ev,
                                                                speaker[
                                                                    "speaker_id"
                                                                ]
                                                            )
                                                        }
                                                    />
                                                </B.Col>
                                                <B.Col>
                                                    <B.Button
                                                        type="button"
                                                        variant="primary"
                                                        className="mr-2"
                                                        onClick={() =>
                                                            this.editSpeaker(
                                                                speaker[
                                                                    "speaker_id"
                                                                ]
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="edit"
                                                            fixedWidth
                                                        />
                                                    </B.Button>
                                                    <B.Button
                                                        type="button"
                                                        variant="danger"
                                                        onClick={() =>
                                                            this.deleteSpeaker(
                                                                speaker[
                                                                    "speaker_id"
                                                                ]
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="trash-alt"
                                                            fixedWidth
                                                        />
                                                    </B.Button>
                                                </B.Col>
                                            </B.Form.Row>
                                        </B.Form.Group>
                                    );
                                })
                            )}
                        </B.Form>
                    </B.Modal.Body>
                    <B.Modal.Footer>
                        <B.Form
                            className="w-100"
                            onSubmit={ev => this.addSpeaker(ev)}
                            noValidate
                            validated={validated}
                        >
                            <B.Form.Group>
                                <B.Form.Row>
                                    <B.Col>
                                        <B.Form.Control
                                            type="text"
                                            name="speakerNew"
                                            value={
                                                formsData["speakerNew"] || ""
                                            }
                                            onChange={ev =>
                                                this.handleInputChange(ev)
                                            }
                                            required
                                        />
                                        <B.Form.Control.Feedback type="invalid">
                                            Полето е задължително!
                                        </B.Form.Control.Feedback>
                                    </B.Col>
                                    <B.Col>
                                        <B.Button
                                            type="submit"
                                            variant="primary"
                                        >
                                            <FontAwesomeIcon
                                                icon="plus"
                                                fixedWidth
                                                className="mr-2"
                                            />
                                            Добави говорител
                                        </B.Button>
                                    </B.Col>
                                </B.Form.Row>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Footer>
                </B.Modal>
                {/* Books Modal */}
                <B.Modal
                    id="books"
                    centered
                    show={modalShow["books"]}
                    onHide={() => this.modalHide("books", true)}
                    aria-labelledby="booksTitle"
                    className="body-scroll"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="booksTitle">Книги</B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form onSubmit={ev => ev.preventDefault()}>
                            {books.length === 0 ? (
                                <B.Alert variant="info">
                                    Няма въведени книги
                                </B.Alert>
                            ) : (
                                books.map(book => {
                                    return (
                                        <B.Form.Group key={book["book_id"]}>
                                            <B.Form.Row>
                                                <B.Col>
                                                    <B.Form.Control
                                                        type="text"
                                                        value={book.book || ""}
                                                        onChange={ev =>
                                                            this.handleBooksChange(
                                                                ev,
                                                                book["book_id"]
                                                            )
                                                        }
                                                    />
                                                </B.Col>
                                                <B.Col>
                                                    <B.Button
                                                        type="button"
                                                        variant="primary"
                                                        className="mr-2"
                                                        onClick={() =>
                                                            this.editBook(
                                                                book["book_id"]
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="edit"
                                                            fixedWidth
                                                        />
                                                    </B.Button>
                                                    <B.Button
                                                        type="button"
                                                        variant="danger"
                                                        onClick={() =>
                                                            this.deleteBook(
                                                                book["book_id"]
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="trash-alt"
                                                            fixedWidth
                                                        />
                                                    </B.Button>
                                                </B.Col>
                                            </B.Form.Row>
                                        </B.Form.Group>
                                    );
                                })
                            )}
                        </B.Form>
                    </B.Modal.Body>
                    <B.Modal.Footer>
                        <B.Form
                            className="w-100"
                            onSubmit={ev => this.addBook(ev)}
                            noValidate
                            validated={validated}
                        >
                            <B.Form.Group>
                                <B.Form.Row>
                                    <B.Col>
                                        <B.Form.Control
                                            type="text"
                                            name="bookNew"
                                            value={formsData["bookNew"] || ""}
                                            onChange={ev =>
                                                this.handleInputChange(ev)
                                            }
                                            required
                                        />
                                        <B.Form.Control.Feedback type="invalid">
                                            Полето е задължително!
                                        </B.Form.Control.Feedback>
                                    </B.Col>
                                    <B.Col>
                                        <B.Button
                                            type="submit"
                                            variant="primary"
                                        >
                                            <FontAwesomeIcon
                                                icon="plus"
                                                fixedWidth
                                                className="mr-2"
                                            />
                                            Добави книга
                                        </B.Button>
                                    </B.Col>
                                </B.Form.Row>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Footer>
                </B.Modal>
                {/* Tags Modal */}
                <B.Modal
                    id="tags"
                    centered
                    show={modalShow["tags"]}
                    onHide={() => this.modalHide("tags", true)}
                    aria-labelledby="tagsTitle"
                    className="body-scroll"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="tagsTitle">Тагове</B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form onSubmit={ev => ev.preventDefault()}>
                            {tags.length === 0 ? (
                                <B.Alert variant="info">
                                    Няма въведени тагове
                                </B.Alert>
                            ) : (
                                tags.map(({ id, name }) => {
                                    return (
                                        <B.Form.Group key={id}>
                                            <B.Form.Row>
                                                <B.Col>
                                                    <B.Form.Control
                                                        type="text"
                                                        value={name || ""}
                                                        onChange={ev =>
                                                            this.handleTagsChange(
                                                                ev,
                                                                id
                                                            )
                                                        }
                                                    />
                                                </B.Col>
                                                <B.Col>
                                                    <B.Button
                                                        type="button"
                                                        variant="primary"
                                                        className="mr-2"
                                                        onClick={() =>
                                                            this.editTag(id)
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="edit"
                                                            fixedWidth
                                                        />
                                                    </B.Button>
                                                    <B.Button
                                                        type="button"
                                                        variant="danger"
                                                        onClick={() =>
                                                            this.deleteTag(id)
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="trash-alt"
                                                            fixedWidth
                                                        />
                                                    </B.Button>
                                                </B.Col>
                                            </B.Form.Row>
                                        </B.Form.Group>
                                    );
                                })
                            )}
                        </B.Form>
                    </B.Modal.Body>
                    <B.Modal.Footer>
                        <B.Form
                            className="w-100"
                            onSubmit={ev => this.addTag(ev)}
                            noValidate
                            validated={validated}
                        >
                            <B.Form.Group>
                                <B.Form.Row>
                                    <B.Col>
                                        <B.Form.Control
                                            type="text"
                                            name="tagNew"
                                            value={formsData["tagNew"] || ""}
                                            onChange={ev =>
                                                this.handleInputChange(ev)
                                            }
                                            required
                                        />
                                        <B.Form.Control.Feedback type="invalid">
                                            Полето е задължително!
                                        </B.Form.Control.Feedback>
                                    </B.Col>
                                    <B.Col>
                                        <B.Button
                                            type="submit"
                                            variant="primary"
                                        >
                                            <FontAwesomeIcon
                                                icon="plus"
                                                fixedWidth
                                                className="mr-2"
                                            />
                                            Добави таг
                                        </B.Button>
                                    </B.Col>
                                </B.Form.Row>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Footer>
                </B.Modal>
            </>
        );
    }

    drawSermons() {
        const {
            sermons,
            books,
            speakers,
            series,
            filters,
            sortBy,
            currentPage,
            totalSermons,
            minYear,
            maxYear
        } = this.state;
        const language = 1; //BG
        let result = [];
        let years = [];

        for (let year = maxYear; year >= minYear; year--) {
            years.push(year);
        }

        if (sermons.length === 0)
            return <B.Alert variant="warning">Няма въведени проповеди</B.Alert>;

        result.push(
            <>
                <B.Row
                    key="sermonHeadersKey"
                    className="headers align-items-center"
                >
                    <B.Col key="sermonCounterKey" xs={12} md={6}>
                        <h4 className="font-weight-light mb-md-0">
                            Общо {totalSermons}{" "}
                            {lang[language].sermons.toLowerCase()}
                        </h4>
                    </B.Col>
                    <B.Col
                        key="sermonSortKey"
                        xs={12}
                        md={6}
                        className="sort mb-1"
                    >
                        <B.Form.Control
                            as="select"
                            className="custom-select"
                            value={sortBy}
                            onChange={ev => this.handleSort(ev)}
                        >
                            <option value="0">{lang[language].sortBy}</option>
                            <option value="book">{lang[language].book}</option>
                            <option value="speaker">
                                {lang[language].speaker}
                            </option>
                            <option value="series">
                                {lang[language].singleSeries}
                            </option>
                        </B.Form.Control>
                    </B.Col>
                </B.Row>
                <B.Row
                    key="sermonFiltersKey"
                    className="filters align-items-center py-2 my-4 bg-light"
                >
                    <B.Col key="bookFilterKey" xs={12} md={4} className="mb-1">
                        {lang[language].filterBy}
                    </B.Col>
                    {books.length ? (
                        <B.Col xs={12} md={2} className="filter mb-1">
                            <B.Form.Control
                                as="select"
                                className="custom-select"
                                name="book"
                                value={filters.book || ""}
                                onChange={ev => this.handleFilters(ev)}
                            >
                                <option value="0">{lang[language].book}</option>
                                {books.map(book => (
                                    <option
                                        key={book["book_id"]}
                                        value={book["book_id"]}
                                    >
                                        {book.book}
                                    </option>
                                ))}
                            </B.Form.Control>
                        </B.Col>
                    ) : (
                        ""
                    )}
                    {speakers.length ? (
                        <B.Col
                            key="speakerFilterKey"
                            xs={12}
                            md={2}
                            className="filter mb-1"
                        >
                            <B.Form.Control
                                as="select"
                                className="custom-select"
                                name="speaker"
                                value={filters.speaker || ""}
                                onChange={ev => this.handleFilters(ev)}
                            >
                                <option value="0">
                                    {lang[language].speaker}
                                </option>
                                {speakers.map(speaker => (
                                    <option
                                        key={speaker["speaker_id"]}
                                        value={speaker["speaker_id"]}
                                    >
                                        {speaker.name}
                                    </option>
                                ))}
                            </B.Form.Control>
                        </B.Col>
                    ) : (
                        ""
                    )}
                    {series.length ? (
                        <B.Col
                            key="seriesFilterKey"
                            xs={12}
                            md={2}
                            className="filter mb-1"
                        >
                            <B.Form.Control
                                as="select"
                                className="custom-select"
                                name="series"
                                value={filters.series || ""}
                                onChange={ev => this.handleFilters(ev)}
                            >
                                <option value="0">
                                    {lang[language].singleSeries}
                                </option>
                                {series.map(series => (
                                    <option
                                        key={series["series_id"]}
                                        value={series["series_id"]}
                                    >
                                        {series.series}
                                    </option>
                                ))}
                            </B.Form.Control>
                        </B.Col>
                    ) : (
                        ""
                    )}
                    {years.length ? (
                        <B.Col
                            key="yearFilterKey"
                            xs={12}
                            md={2}
                            className="filter mb-1"
                        >
                            <B.Form.Control
                                as="select"
                                className="custom-select"
                                name="year"
                                value={filters.year || ""}
                                onChange={ev => this.handleFilters(ev)}
                            >
                                <option value="0">{lang[language].year}</option>
                                {years.map(year => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </B.Form.Control>
                        </B.Col>
                    ) : (
                        ""
                    )}
                </B.Row>
                <B.Row key="sermonsWrapperKey" className="sermons-wrapper mt-3">
                    {sermons.map(sermon => {
                        const sermonDate = sermon["uploaded_at"]
                            .split(" ")[0]
                            .split("-");
                        const date = new Date(
                            sermonDate[2],
                            sermonDate[1] - 1,
                            sermonDate[0]
                        );
                        return (
                            <B.Col
                                key={sermon["sermon_id"]}
                                xs={12}
                                sm={6}
                                lg={4}
                                xl={3}
                                className="mb-3"
                            >
                                <B.Card>
                                    <video
                                        className="card-img-top"
                                        controls
                                        preload="none"
                                        poster={
                                            sermon.cover !== "/storage/"
                                                ? sermon.cover
                                                : this.MEDIA_PLACEHOLDER
                                        }
                                    >
                                        <source
                                            src={sermon.media}
                                            type="video/mp4"
                                        />
                                    </video>
                                    <B.Card.Body>
                                        <B.Card.Title>
                                            {sermon.title}
                                        </B.Card.Title>
                                        <B.Card.Subtitle className="text-muted">{`${date.toLocaleDateString(
                                            "bg"
                                        )}`}</B.Card.Subtitle>
                                        <B.Card.Text>
                                            {sermon.speaker || (
                                                <span>&nbsp;</span>
                                            )}
                                        </B.Card.Text>
                                    </B.Card.Body>
                                    <B.Card.Footer className="text-center">
                                        <B.Button
                                            type="button"
                                            variant="primary"
                                            className="mr-2"
                                            onClick={() =>
                                                this.getSermonData(
                                                    sermon["sermon_id"]
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon="edit"
                                                fixedWidth
                                            />
                                        </B.Button>
                                        <B.Button
                                            type="button"
                                            variant="danger"
                                            onClick={() =>
                                                this.deleteSermon(
                                                    sermon["sermon_id"]
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon="trash-alt"
                                                fixedWidth
                                            />
                                        </B.Button>
                                    </B.Card.Footer>
                                </B.Card>
                            </B.Col>
                        );
                    })}
                </B.Row>
            </>
        );

        result.push(
            <div
                key="sermonsPaginationKey"
                className="pagination-wrapper text-center mt-4"
            >
                <B.Pagination>
                    <B.Pagination.Item
                        key="paginationBackKey"
                        tabIndex={0}
                        onClick={() => this.handlePagination(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        {lang[language].back}
                    </B.Pagination.Item>
                    <B.Pagination.Item
                        key="paginationNextKey"
                        tabIndex={0}
                        onClick={() => this.handlePagination(currentPage + 1)}
                        disabled={sermons.length < 20}
                    >
                        {lang[language].next}
                    </B.Pagination.Item>
                </B.Pagination>
            </div>
        );

        return result;
    }

    render() {
        const { loaded, error, alerts } = this.state;

        if (error) {
            return (
                <SermonsLayout
                    alerts={alerts}
                    handleCloseClick={alert => this.handleCloseAlert(alert)}
                    modalShow={id => this.modalOpen(id)}
                >
                    <B.Alert variant="danger">
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                    {this.drawModals()}
                </SermonsLayout>
            );
        } else if (!loaded) {
            return (
                <SermonsLayout
                    alerts={alerts}
                    handleCloseClick={alert => this.handleCloseAlert(alert)}
                    modalShow={id => this.modalOpen(id)}
                >
                    <B.ProgressBar animated now={100} label="Loading..." />
                    {this.drawModals()}
                </SermonsLayout>
            );
        } else {
            return (
                <SermonsLayout
                    alerts={alerts}
                    handleCloseClick={alert => this.handleCloseAlert(alert)}
                    modalShow={id => this.modalOpen(id)}
                >
                    {this.drawSermons()}
                    {this.drawModals()}
                </SermonsLayout>
            );
        }
    }
}

const SermonsLayout = props => {
    const { children, alerts, handleCloseClick, modalShow } = props;
    return (
        <>
            {alerts.length ? (
                <Alerts
                    alerts={alerts}
                    handleCloseClick={alert => handleCloseClick(alert)}
                />
            ) : (
                ""
            )}
            <div className="page-header mb-3">
                <h2 className="d-inline-block">Проповеди</h2>

                <div className="float-right d-inline-block">
                    <B.Button
                        className="btn btn-light mr-3"
                        onClick={() => modalShow("tags")}
                    >
                        <FontAwesomeIcon
                            icon="tags"
                            className="mr-2 text-warning"
                            fixedWidth
                        />
                        Тагове
                    </B.Button>
                    <B.Button
                        className="btn btn-light mr-3"
                        onClick={() => modalShow("books")}
                    >
                        <FontAwesomeIcon
                            icon="bible"
                            className="mr-2"
                            fixedWidth
                        />
                        Книги
                    </B.Button>
                    <B.Button
                        className="btn btn-light mr-3"
                        onClick={() => modalShow("speakers")}
                    >
                        <FontAwesomeIcon
                            icon="users"
                            className="mr-2 text-danger"
                            fixedWidth
                        />
                        Говорители
                    </B.Button>
                    <B.Button
                        className="btn btn-light mr-3"
                        onClick={() => modalShow("series")}
                    >
                        <FontAwesomeIcon
                            icon="list"
                            className="mr-2 text-info"
                            fixedWidth
                        />
                        Серии
                    </B.Button>
                    <B.Button
                        className="btn btn-light"
                        onClick={() => modalShow("sermons")}
                    >
                        <FontAwesomeIcon
                            icon="plus"
                            className="mr-2 text-success"
                            fixedWidth
                        />
                        Добави проповед
                    </B.Button>
                </div>
            </div>
            {children}
        </>
    );
};
