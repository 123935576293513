import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as B from 'react-bootstrap';

import MenuItem from './MenuItem';
import {get, post} from "../../API";
import {lang} from "../../lang/all";

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearchOpen: false,
            searchVal: '',
            searchResults: {},
            menuItems: [],
            searching: false
        };
        this.mounted = false;
        this.language = props.language;
        this.MEDIA_PLACEHOLDER = '/images/media_placeholder.jpg';
        this.SEARCH_API_URL = '/api/search';
        this.MENU_API_URL = '/api/menu';
    }

    componentWillMount() {
        this.mounted = true;
        this.loadMenus();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.language !== this.props.language) {
            this.language = this.props.language;
            this.loadMenus();
        }
    }

    handleSearchClick() {
        const {isSearchOpen} = this.state;
        this.setState(prevState => ({
            isSearchOpen: !prevState.isSearchOpen
        }));

        document.body.style.overflow = `${isSearchOpen ? 'auto' : 'hidden'}`;

        if (!isSearchOpen)
            this.setState({searchVal: '', searchResults: {}});
    }

    handleInputChange(ev) {
        const target = ev.target;
        const value = target.value;

        this.setState({searchVal: value});
    }

    handleSearch(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        const {searchVal} = this.state;

        if (searchVal.length) {
            this.setState({searching: true});
            const params = {string: searchVal};
            const data = {auth: {func: 1}, params};

            post(this.SEARCH_API_URL, data)
                .then(data => {
                    this.setState({searchResults: data, searching: false});
                })
                .catch(error => console.error(error));
        }
    }

    drawSearchResults() {
        const {language} = this.props;
        const {searchResults} = this.state;
        let resultsArray = [];

        for (let type in searchResults) {
            if (searchResults.hasOwnProperty(type)) {
                const results = searchResults[type];

                if (type === 'pages') {
                    resultsArray.push(<h3 key="searchResultsPagesTitle">{lang[language].pages}:</h3>);
                    resultsArray.push(
                        <B.ListGroup key="searchResultsPages" className="search-result-pages mb-5">
                            {results.map(page => {
                                const addr = `${page.addr.indexOf('/') === 0 ? '' : '/'}${page.addr}`;
                                return <B.ListGroupItem key={`searchPage-${page['page_id']}`} href={addr} action>{page.page}</B.ListGroupItem>
                            })}
                        </B.ListGroup>
                    );
                } else if (type === 'galleries') {
                    resultsArray.push(<h3 key="searchResultsGalleryTitle">{lang[language].images}:</h3>);
                    resultsArray.push(
                        <B.Row className="mb-5">
                            {results.map(pic => {
                                return (
                                    <B.Col key={`searchGallery-${pic['gallery_id']}`} xs={12} md={6} lg={4} className="mb-3">
                                        <B.Card>
                                            <a href={`/media/gallery/${pic['category_id']}`}>
                                                <B.CardImg src={pic.media} variant="top" alt={pic.title}/>
                                                <B.Card.Body>
                                                    <B.Card.Title className="text-body">{pic.title}</B.Card.Title>
                                                    <div className="text-muted">Част от {pic.category}</div>
                                                </B.Card.Body>
                                            </a>
                                        </B.Card>
                                    </B.Col>
                                );
                            })}
                        </B.Row>
                    )
                } else if (type === 'sermons') {
                    resultsArray.push(<h3 key="searchResultsSermonsTitle">{lang[language].sermons}:</h3>);
                    resultsArray.push(
                        <B.Row className="mb-5">
                            {results.map(sermon => {
                                const sermonDate = sermon['created_at'].split(' ')[0].split('-');
                                const date = new Date(sermonDate[2], sermonDate[1], sermonDate[0]);
                                const sermonCover = sermon.cover;

                                return (
                                    <B.Col key={`searchSermon-${sermon['sermon_id']}`} xs={12} md={6} lg={4} className="mb-3">
                                        <a href={`/media/sermons/${sermon['sermon_id']}`}>
                                            <B.Card>
                                                <video className="card-img-top" controls={false} preload="none" poster={sermonCover !== '/storage/' ? sermonCover : this.MEDIA_PLACEHOLDER}>
                                                    <source src={sermon.media} type="video/mp4"/>
                                                </video>
                                                <B.Card.Body>
                                                    <B.Card.Title className="text-body">{sermon.title}</B.Card.Title>
                                                    <B.Card.Subtitle className="text-muted">{`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}</B.Card.Subtitle>
                                                </B.Card.Body>
                                            </B.Card>
                                        </a>
                                    </B.Col>
                                );
                            })}
                        </B.Row>
                    )
                } else if (type === 'news') {
                    resultsArray.push(<h3 key="searchResultsNewsTitle">{lang[language].news}:</h3>);
                    resultsArray.push(
                        <B.Row className="mb-5">
                            {results.map(news => {
                                const newsDate = news['created_at'].split(' ')[0].split('-');
                                const date = new Date(newsDate[2], newsDate[1], newsDate[0]);

                                return (
                                    <B.Col key={`searchNews-${news['news_id']}`} xs={12} md={6} lg={4} className="mb-3">
                                        <B.Card>
                                            <a href={`/news/${news['news_id']}`}>
                                                <B.CardImg src={news.media} variant="top" alt={news.title}/>
                                                <B.Card.Body>
                                                    <B.Card.Title className="text-body">{news.title}</B.Card.Title>
                                                    <B.Card.Subtitle className="text-muted">{`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}</B.Card.Subtitle>
                                                </B.Card.Body>
                                            </a>
                                        </B.Card>
                                    </B.Col>
                                );
                            })}
                        </B.Row>
                    )
                }
            }
        }

        resultsArray.push(<div key="searchPreloaderKey" className="preloader"><FontAwesomeIcon icon="spinner" pulse size="3x"/></div>);

        return resultsArray;
    }

    loadMenus() {
        get(this.MENU_API_URL)
            .then(data => {
                if (this.mounted) {
                    if (data['response'] !== false) {
                        if (data['data']) {
                            let menuItems = [];
                            const menus = data['data'].filter(menu => menu['lang_id'] === this.language);

                            if (menus.length) {
                                menus.map(menu => {
                                    const menuItem = {};
                                    menuItem.id = menu.id;
                                    menuItem.name = menu['page_name'];
                                    menuItem.addr = menu['page_addr'];

                                    if (data['data_content'] && data['data_content'].hasOwnProperty(menu.id) && data['data_content'][menu.id]['response'] !== false) {
                                        let children = [];

                                        data['data_content'][menu.id].map(subMenu => {
                                            const subMenuItem = {};
                                            subMenuItem.id = subMenu.id;
                                            subMenuItem.name = subMenu['page_name'];
                                            subMenuItem.addr = subMenu['page_addr'];

                                            children.push(subMenuItem);
                                        });

                                        menuItem.children = children;
                                    }

                                    menuItems.push(menuItem);
                                });

                                this.setState({menuItems});
                            }
                        }
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    render() {
        const {isSearchOpen, searchVal, searchResults, menuItems, searching} = this.state;
        const {language, onLanguageChange} = this.props;
        const nextLang = language === 1 ? 2 : 1;
        const nextLangString = language === 1 ? 'en' : 'bg';

        return (
            <React.Fragment>
                <header>
                    <B.Navbar collapseOnSelect bg="dark" expand="lg" variant="dark" fixed='top'>
                        <B.Container>
                            <B.Navbar.Brand href="/"
                                            className="pr-md-4 d-lg-none navbar-brand flex-grow-1 flex-grow-md-0">{lang[language].brand}</B.Navbar.Brand>
                            <B.Button variant="link" className="nav-link d-lg-none"
                                      onClick={() => this.handleSearchClick()}>
                                <FontAwesomeIcon icon="search"/>
                            </B.Button>
                            <B.Button variant="link" onClick={() => onLanguageChange(nextLang)}
                                      className="nav-link d-lg-none">{nextLangString.toUpperCase()}</B.Button>
                            <B.Navbar.Toggle aria-controls="mainNav"/>
                            <B.Navbar.Collapse id="mainNav">
                                <B.Nav as='ul' className="w-100 justify-content-between">
                                    {menuItems.map(item => (
                                        <MenuItem key={item.id} id={item.id} name={item.name} addr={item.addr}
                                                  children={item.children}/>)
                                    )}
                                    <B.Button variant="link" className="nav-link d-none d-lg-block"
                                              onClick={() => this.handleSearchClick()}>
                                        <FontAwesomeIcon icon="search"/>
                                    </B.Button>
                                    <B.Button variant="link" onClick={() => onLanguageChange(nextLang)}
                                              className="nav-link d-none d-lg-block">{nextLangString.toUpperCase()}</B.Button>
                                </B.Nav>
                            </B.Navbar.Collapse>
                        </B.Container>
                    </B.Navbar>
                </header>
                <div id="search" className={`search py-5 ${isSearchOpen ? 'show' : ''}`}>
                    <B.Container className="position-relative">
                        <B.Form key="searchForm"
                                onSubmit={e => this.handleSearch(e)}>
                            <B.Form.Group controlId="searchInput" className="pt-5 pt-lg-0">
                                <B.Form.Label className="mb-4">{lang[language].searchLabel}</B.Form.Label>
                                <B.Form.Control size="lg" type="search" className="search-input" value={searchVal || ''}
                                                placeholder={lang[language].searchPlaceholder} onChange={ev => this.handleInputChange(ev)}/>
                            </B.Form.Group>
                            <B.Button variant="link" className="close-search" onClick={() => this.handleSearchClick()}>
                                <FontAwesomeIcon icon={['far', 'times-circle']} size="2x" fixedWidth
                                                 className="text-light"/>
                            </B.Button>
                        </B.Form>
                        <div key="searchResultsWrapper" id="searchResults" className={`search-results-wrapper pr-2 ${searching ? 'loading' : ''}`}>
                            {searchResults['response'] !== false ?
                                this.drawSearchResults() : <h2 key="noResultsMessage" className="display-4">{searchResults['message']}</h2>
                            }
                        </div>
                    </B.Container>
                </div>
            </React.Fragment>
        );
    }
}
