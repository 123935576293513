import React, {Component} from 'react';
import * as Icons from '../../icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class IconPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            icons: Icons['default'],
            selected: undefined,
            loaded: false,
            content: []
        };
    }

    componentDidMount() {
        // fetch('https://raw.githubusercontent.com/FortAwesome/Font-Awesome/master/metadata/icons.json')
        //     .then(response => response.json())
        //     .then(data => {
        //     let icons = [];
        //     $.each(data, (icon, meta) => {
        //         meta['styles'].forEach(type => {
        //             if (type === 'solid') {
        //                 icons.push(icon);
        //             } else if (type === 'regular') {
        //                 icons.push(['far', icon]);
        //             } else if (type === 'brands') {
        //                 icons.push(['fab', icon]);
        //             }
        //         });
        //     });
        //
        //     console.log(JSON.stringify(icons));
        // });

        this.drawIcons();
    }

    handleClick(selected, icon) {
        const {onChange} = this.props;
        this.setState({selected});

        this.drawIcons();
        return onChange(icon);
    }

    drawIcons() {
        const {icons, selected} = this.state;
        let content = [];

        icons.map((icon, i) => content.push(
            <div key={i} className={`icon-wrapper mr-1 mb-1 p-1 border ${selected === i ? 'selected' : ''}`}
                 title={icon.toString().replace(/fab,|far,/, '')} onClick={() => this.handleClick(i, icon)}>
                <FontAwesomeIcon icon={icon} fixedWidth size="lg"/>
            </div>
        ));

        this.setState({content, loaded: true});
    }

    render() {
        const {loaded, content} = this.state;

        if (!loaded) {
            return <div>Icons are loading...</div>;
        } else {
            return (<div className="icons-list">{content}</div>);
        }
    }
}

export default IconPicker;
