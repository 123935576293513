import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as B from 'react-bootstrap';
import {logout} from "../../../API";

export default class HeaderAdmin extends Component {
    constructor(props) {
        super(props);
    }

    logout() {
        logout()
            .then(() => {
                localStorage.removeItem('tkn');
                window.location = '/';
            })
            .catch(error => console.error(error));
    }

    render() {
        return (
            <header>
                <B.Navbar bg="dark" variant="dark">
                    <B.Container fluid>
                        <h1 className="navbar-brand mr-3">Админ панел ЕБЦ - София 1</h1>
                        <nav>
                            <a href="/" className="btn btn-dark mr-2" title="Обратно към сайта">
                                <FontAwesomeIcon icon="home" fixedWidth className="mr-2"/>
                                Website
                            </a>
                            <B.Button type="button" variant="dark" title="Logout" onClick={() => this.logout()}>
                                <FontAwesomeIcon icon='sign-out-alt' fixedWidth className="mr-2"/>
                                Logout
                            </B.Button>
                        </nav>
                    </B.Container>
                </B.Navbar>
            </header>
        );
    }
}
