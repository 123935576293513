import React, {Component} from 'react';
import * as B from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import PageTitle from "./layout/PageTitle";
import {Link} from "react-router-dom";
import {get} from "../API";
import {lang} from "../lang/all";
import {FacebookShareButton, TwitterShareButton} from "react-share";

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            match: props.match.params.id,
            news: [],
            newsContent: {},
            error: null,
            copySuccess: false,
            target: null,
            tags: []
        };

        this.API_URL = '/api/news';
        this.DEFAULT_NEWS_IMG = 'images/default_news-admin.png';
        this.attachRef = target => this.setState({target});
    }

    componentWillMount() {
        const {isLoaded, match} = this.props;
        const id = match.params.id;

        isLoaded(false);

        if (id)
            this.getNewsData(id);

        this.loadNews();
    }

    componentDidUpdate(prevProps) {
        const {isLoaded} = this.props;
        const oldMatchID = prevProps.match.params.id;
        const newMatchID = this.props.match.params.id;

        if (oldMatchID !== newMatchID) {
            this.setState({match: newMatchID});

            if (newMatchID) {
                isLoaded(false);
                this.getNewsData(newMatchID);
            }
        }
    }

    copyToClipboard(e) {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({copySuccess: true});

        setTimeout(() => {
            this.setState({copySuccess: false})
        }, 5000);
    }

    loadNews() {
        this.setState({error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    const {language, isLoaded, match} = this.props;
                    const news = data['news'].filter(news => news.lang_id === language);

                    this.setState({
                        news
                    });
                    if (!match.params.id)
                        isLoaded(true);
                } else {
                    this.setState({
                        error: data['message'],
                        news: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    getNewsData(id) {
        get(`${this.API_URL}/${id}`)
            .then(data => {
                if (data['response'] !== false) {
                    const {isLoaded} = this.props;
                    const tags = [];

                    if (data['news_keywords'] && data['news_keywords'].hasOwnProperty(id) && data['news_keywords'][id].length) {
                        data['news_keywords'][id].map(({keyword}) => tags.push(keyword));
                    }
                    this.setState({
                        newsContent: data,
                        tags
                    });
                    isLoaded(true);
                } else {
                    this.setState({
                        error: data['message'],
                        newsContent: {},
                        tags: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    render() {
        const {language} = this.props;
        const {match, news, newsContent, error, target, copySuccess, tags} = this.state;
        const address = window.location.toString();

        if (error)
            return <B.Alert variant="info">{error}</B.Alert>;

        if (match && Object.keys(newsContent).length) {
            const newsDate = newsContent['created_at'].split('-');
            const date = new Date(newsDate[2], newsDate[1], newsDate[0]);
            const dateLocales = language === 1 ? 'bg-BG' : 'en-GB';

            return (
                <main id="app" role="main">
                    <PageTitle title={newsContent.title}>
                        <h2><Link to="/news">{lang[language].news}</Link></h2>
                    </PageTitle>
                    <B.Container as='section' className="py-3">
                        <article className="news">
                            <B.Row key="newsContentKey">
                                {newsContent.media !== '/storage/' ?
                                    <B.Col className="news-img" xs={12} md={{span: 10, offset: 1}}
                                           lg={{span: 8, offset: 2}}>
                                        <img src={newsContent.media} className="img-fluid mb-3"
                                             alt={newsContent.title}/>
                                    </B.Col>
                                    : ''
                                }
                                <B.Col xs={12} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}
                                       dangerouslySetInnerHTML={{__html: newsContent.text}}/>
                            </B.Row>
                            {tags.length ?
                                <>
                                    <h5 key="newsTagsTitleKey" className="mt-3 text-muted font-weight-light">{lang[language].tags}</h5>
                                    <div key="newsTagsKey" className="tags-wrapper d-flex flex-wrap align-items-center">
                                        {tags.map(tag => (
                                            <small key={tag} className="tag bg-light text-muted px-3 py-2 mr-2 mb-2 font-weight-light">{tag}</small>
                                        ))}
                                    </div>
                                </>
                                : ''}
                            <footer
                                className="mt-4 py-2 border-top border-bottom d-flex align-items-center justify-content-between">
                                <span className="text-muted">{date.toLocaleString(dateLocales, {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</span>
                                <span className="share-wrapper">
                                     <FacebookShareButton url={address}
                                                          quote={`${newsContent.title} - новина от ${date.toLocaleString('bg-BG', {
                                                              year: 'numeric',
                                                              month: 'long',
                                                              day: 'numeric'
                                                          })}`}
                                                          children={<FontAwesomeIcon icon={['fab', 'facebook-square']}
                                                                                     fixedWidth size="lg"
                                                                                     title={`${lang[language].shareWith} Facebook`}/>}
                                                          className="ml-2 d-inline-block"/>
                                    <TwitterShareButton url={address}
                                                        title={`${newsContent.title} - новина от ${date.toLocaleString('bg-BG', {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric'
                                                        })}`}
                                                        children={<FontAwesomeIcon icon={['fab', 'twitter-square']}
                                                                                   fixedWidth size="lg"
                                                                                   title={`${lang[language].shareWith} Twitter`}/>}
                                                        className="ml-2 d-inline-block"/>
                                    <B.Button type="button" variant="link" className="ml-2 p-0"
                                              title={lang[language].copyAddress} ref={this.attachRef}
                                              onClick={ev => this.copyToClipboard(ev)}>
                                        <FontAwesomeIcon icon="link" fixedWidth size="lg"/>
                                    </B.Button>
                                    <B.Overlay target={target} show={copySuccess} placement="auto">
                                        {props => (
                                            <B.Tooltip {...props}>{lang[language].copied}</B.Tooltip>
                                        )}
                                    </B.Overlay>
                                    <textarea className="textarea-for-copy" ref={textarea => this.textArea = textarea}
                                              value={address.indexOf(newsContent['news_id']) !== -1 ? address : `${address}/${newsContent['news_id']}`}
                                              readOnly/>
                                </span>
                            </footer>
                        </article>
                    </B.Container>
                </main>
            );
        } else if (news.length) {
            return (
                <main id="app" role="main">
                    <PageTitle title={lang[language].news}/>
                    <B.Container as='section' className="py-3">
                        <B.Row className="news-wrapper">
                            {news.map(news => {
                                const newsDate = news['created_at'].split(' ')[0].split('-');
                                const date = new Date(newsDate[2], newsDate[1], newsDate[0]);

                                return (
                                    <B.Col key={news['news_id']} xs={12} md={6} lg={4} className="news-col mb-3">
                                        <B.Card className="news">
                                            <Link to={`/news/${news['news_id']}`}>
                                                <B.CardImg
                                                    src={news.media === '/storage/' ? this.DEFAULT_NEWS_IMG : news.media}
                                                    alt={news.title}/>
                                                <B.Card.ImgOverlay>
                                                    <B.Card.Title>{news.title}</B.Card.Title>
                                                    <B.Card.Text
                                                        className="news-date">{`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}</B.Card.Text>
                                                </B.Card.ImgOverlay>
                                            </Link>
                                        </B.Card>
                                    </B.Col>
                                );
                            })}
                        </B.Row>
                    </B.Container>
                </main>
            );
        }

        return null;
    }
}

export default News;
