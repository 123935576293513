import React, {Component} from 'react';
import * as B from 'react-bootstrap';
import MenuItem from './MenuItem';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {get, post} from "../../API";
import {lang} from "../../lang/all";
import {NavLink} from "react-router-dom";

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            footer: [],
            footerList: {},
            social: {},
            quote: null,
            preaching: []
        };
        this.mounted = false;
        this.API_FOOTER_URL = '/api/footer';
        this.API_PREACHING_URL = '/api/preaching';
        this.FOOTER_FUNC = {
            'loadSocial': 8,
            'loadQuote': 9
        };
        this.days = {
            1: ['Неделя', 'Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота'],
            2: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        };
    }

    componentWillMount() {
        this.mounted = true;
        this.loadFooter();
        this.loadQuote();
        this.loadSocial();
        this.loadPreaching();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadFooter() {
        this.setState({error: null});

        get(this.API_FOOTER_URL)
            .then(data => {
                if (this.mounted) {
                    if (data['response'] !== false) {
                        const {language} = this.props;
                        let footer = [];
                        let footerList = {};

                        if (data['data']) {
                            footer = data['data'].filter(col => col.lang_id === language);

                            footer.map(({column_id}) => {
                                if (data['data_content'] && data['data_content'].hasOwnProperty(column_id) && data['data_content'][column_id].length) {
                                    footerList[column_id] = [];
                                    footerList[column_id] = data['data_content'][column_id].filter(list => list.lang_id === language);
                                }
                            });
                        }

                        this.setState({
                            footer,
                            footerList
                        });
                    } else {
                        this.setState({
                            error: data['message'],
                            footer: [],
                            footerList: {}
                        });
                    }
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadSocial() {
        const params = {};
        const data = {auth: {func: this.FOOTER_FUNC.loadSocial}, params};

        post(this.API_FOOTER_URL, data)
            .then(data => {
                if (this.mounted) {
                    if (data['response'] !== false) {
                        this.setState({
                            loaded: true,
                            social: data
                        });
                    } else {
                        this.setState({
                            loaded: true,
                            error: data['message'],
                            social: []
                        });
                    }
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadQuote() {
        const params = {};
        const data = {auth: {func: this.FOOTER_FUNC.loadQuote}, params};

        post(this.API_FOOTER_URL, data)
            .then(data => {
                if (this.mounted) {
                    if (data['response'] !== false) {
                        const quote = data['quote'];

                        this.setState({
                            quote
                        });
                    } else {
                        this.setState({
                            error: data['message'],
                            quote: null
                        });
                    }
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadPreaching() {
        get(this.API_PREACHING_URL)
            .then(data => {
                if (this.mounted) {
                    if (data['response'] !== false) {
                        const {language} = this.props;
                        const preaching = data.filter(pr => pr.lang_id === language);

                        this.setState({
                            preaching
                        });
                    } else {
                        this.setState({
                            preaching: [],
                            error: data['message']
                        });
                    }
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    render() {
        const {language} = this.props;
        const {quote, footer, footerList, social, preaching} = this.state;
        const date = new Date();
        let lgColumns = 6;
        let lgColumnRight = 6;
        let footerResult = [];
        let preachingResult = [];
        let socialResult = [];

        switch (footer.length) {
            case 0:
                lgColumnRight = 12;
                break;
            case 1:
                lgColumns = 6;
                lgColumnRight = 6;
                break;
            case 2:
                lgColumns = 4;
                lgColumnRight = 4;
                break;
            case 3:
                lgColumns = 3;
                lgColumnRight = 3;
                break;
            case 4:
                lgColumns = 3;
                lgColumnRight = 12;
                break;
            case 5:
                lgColumns = 2;
                lgColumnRight = 2;
                break;
            case 6:
                lgColumns = 2;
                lgColumnRight = 12;
                break;
            default:
                lgColumns = 6;
                lgColumnRight = 6;
                break;
        }

        if (footer.length) {
            footer.map(({column_id, column, has_links}) => {
               footerResult.push(
                   <B.Col key={`footerCol-${column_id}`} xs={12} md={6} lg={lgColumns}
                          className="footer-widget mb-3 mb-lg-0">
                       <h6 className="font-weight-light text-white">{column}</h6>
                       {footerList && footerList.hasOwnProperty(column_id) ?
                           has_links ?
                               <B.Nav as='ul' className='flex-column'>
                                   {footerList[column_id].map(({list_id, link, list}) => (
                                       <B.NavItem as='li' key={list_id}>
                                           {link ?
                                               link.indexOf('http') !== -1 ?
                                                   <a href={link} target="_blank"
                                                      className="nav-link">{list}</a> :
                                                   <NavLink to={link} className="nav-link" exact>{list}</NavLink>
                                               : <a href="#" className="nav-link">{list}</a>}
                                       </B.NavItem>)
                                   )}
                               </B.Nav> :
                               <ul className="list-unstyled">
                                   {footerList.map(({list_id, link, list}) => (
                                           <li key={list_id}>{list}</li>
                                   ))}
                               </ul>
                           : ''}
                   </B.Col>
               );
            });
        }

        if (preaching.length) {
            preachingResult.push(
                <ul key="preachingsList" className="services-list fa-ul">
                    {preaching.map(preach => {
                        const preachTime = preach.hour.split(':');
                        const date = new Date();
                        date.setHours(preachTime[0], preachTime[1], 0);
                        const day = this.days[language][preach.day];

                        return (<li key={preach['preach_id']} title={preach['name']}>
                            <FontAwesomeIcon icon={preach.icon} listItem/>
                            {`${day} ${lang[language].timePreposition} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`}
                        </li>);
                    })}
                </ul>
            );
        }

        if (Object.keys(social).length) {
            socialResult.push(
                social['show_facebook'] === 1 &&
                <a key="footerSocialFacebook" href={social['facebook']} target="_blank" className="ml-1">
                    <FontAwesomeIcon icon={['fab', 'facebook']} fixedWidth size="lg" className="mr-2"/>
                </a>,
                social['show_twitter'] === 1 &&
                <a key="footerSocialTwitter" href={social['twitter']} target="_blank" className="ml-1">
                    <FontAwesomeIcon icon={['fab', 'twitter']} fixedWidth size="lg" className="mr-2"/>
                </a>,
                social['show_instagram'] === 1 &&
                <a key="footerSocialInstagram" href={social['instagram']} target="_blank" className="ml-1">
                    <FontAwesomeIcon icon={['fab', 'instagram']} fixedWidth size="lg" className="mr-2"/>
                </a>,
                social['show_youtube'] === 1 &&
                <a key="footerSocialYouTube" href={social['youtube']} target="_blank" className="ml-1">
                    <FontAwesomeIcon icon={['fab', 'youtube']} fixedWidth size="lg" className="mr-2"/>
                </a>
            );
        }

        return (
                <footer className="footer bg-dark text-muted pt-2 pb-3">
                    <B.Container>
                        {quote ?
                            <B.Row className="footer-quote-wrapper pb-2">
                                <B.Col xs={12} className="text-center text-muted d-none d-md-block">
                                    <small>{quote}</small>
                                </B.Col>
                            </B.Row>
                            : ''}
                        <B.Row className="footer-widgets-wrapper pb-2 pt-3">
                            {footer.length ?
                                footerResult
                                : ''}
                            <B.Col key="footerLastColumn" xs={12} lg={lgColumnRight} className="footer-widget">
                                {preaching.length ?
                                    <>
                                        <h6 key="preachingsTitle" className="font-weight-light text-white">
                                            {lang[language].preachingsTitle}
                                        </h6>
                                        {preachingResult}
                                    </>
                                    : ''}
                                <div key="footerSocials" className="socials-wrapper">
                                    {socialResult}
                                </div>
                            </B.Col>
                        </B.Row>
                        <B.Row className="footer-copyright text-muted pt-3">
                            <B.Col xs={12} md={6} className="text-center text-md-left">
                                Copyright &copy; {date.getFullYear()} {lang[language].brand}
                            </B.Col>
                        </B.Row>
                    </B.Container>
                </footer>
        );
    }
}
