import React, {Component} from 'react';

class Error404 extends Component {
    componentWillMount() {
        const {isLoaded} = this.props;
        isLoaded(true);
    }

    render() {
        return (
            <main id="app" role="main">
                <h1>Error: 404!</h1>
            </main>
        );
    }
}

export default Error404;
