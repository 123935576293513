import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as B from "react-bootstrap";

import { get, post } from "../API";
import PageTitle from "./layout/PageTitle";
import Error404 from "./Error404";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lightbox from "react-images";
import { lang } from "../lang/all";
import { FacebookShareButton, TwitterShareButton } from "react-share";

class Media extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            type: props.match.params.type,
            error: null,
            error404: false,
            loaded: false,
            sermons: [],
            watchID: null,
            sermonForWatch: {},
            tags: [],
            books: [],
            speakers: [],
            series: [],
            galleries: [],
            categories: [],
            categoryForWatch: {},
            categoryForWatchID: null,
            filters: {
                speaker: 0,
                series: 0,
                book: 0,
                year: 0
            },
            sortBy: 0,
            currentPage: 1,
            currentSermonsPage: 1,
            totalSermons: 0,
            minYear: 0,
            maxYear: 0,
            resultsPerPage: 12,
            lightboxIsOpen: false,
            currentImage: 0,
            copySuccess: false,
            target: null
        };
        this.MEDIA_PLACEHOLDER = "/images/media_placeholder.jpg";
        this.SERMONS_API = "/api/sermons";
        this.GALLERY_API = "/api/galleries";
        this.SERMONS_FUNC = {
            loadSeries: 13,
            loadBooks: 14,
            loadSpeakers: 15,
            getDates: 17
        };
        this.GALLERY_FUNC = {
            loadCategories: 7
        };
        this.attachRef = target => this.setState({ target });
    }

    componentWillMount() {
        const { isLoaded } = this.props;
        const { params } = this.props.match;
        isLoaded(false);
        this.checkParams(params);
    }

    componentDidUpdate(prevProps, prevState) {
        const { isLoaded } = this.props;
        const { params } = this.props.match;
        const newID = params.id;
        const oldID = prevProps.match.params.id;
        const oldType = prevProps.match.params.type;
        const newType = params.type;

        if (oldType !== newType || oldID !== newID) {
            isLoaded(false);
            this.setState({ type: newType, id: newID });
            this.checkParams(params);
        }

        if (
            prevState.currentSermonsPage !== this.state.currentSermonsPage ||
            prevState.sortBy !== this.state.sortBy
        )
            this.loadSermons();
    }

    checkParams(params) {
        if (params.type === "sermons") {
            this.loadDates(params.id);
            this.loadBooks();
            this.loadSeries();
            this.loadSpeakers();
        } else if (params.type === "gallery") {
            this.loadCategories();

            if (params.hasOwnProperty("id"))
                this.setState({ categoryForWatchID: params.id });
            else this.setState({ categoryForWatchID: null });
        } else {
            this.setState({ error404: true });
        }
    }

    handleFilters(ev) {
        const target = ev.target;
        const name = target.name;
        const value = target.value;
        const { filters } = this.state;
        filters[name] = Number(value);

        this.setState({ filters, currentSermonsPage: 1 });
        this.loadSermons();
    }

    handleSort(ev) {
        const target = ev.target;
        const value = target.value;

        this.setState({ sortBy: value });
    }

    handlePagination(currentPage) {
        this.setState({ currentPage });
    }

    handleSermonsPagination(page) {
        const currentSermonsPage = page < 1 ? 1 : page;
        this.setState({ currentSermonsPage });
        this.scrollIntoView(document.querySelector(".filters"));
    }

    scrollIntoView(element) {
        try {
            if ("scrollBehavior" in document.documentElement.style) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
                return;
            }
        } catch (e) {}

        element.scrollIntoView();
    }

    copyToClipboard(e) {
        this.textArea.select();
        document.execCommand("copy");
        e.target.focus();
        this.setState({ copySuccess: true });

        setTimeout(() => {
            this.setState({ copySuccess: false });
        }, 5000);
    }

    gotoPrevious() {
        const { currentImage } = this.state;

        this.setState({
            currentImage: currentImage - 1
        });
    }

    gotoNext() {
        const { currentImage } = this.state;

        this.setState({
            currentImage: currentImage + 1
        });
    }

    gotoImage(index) {
        this.setState({ currentImage: index });
    }

    handleClickImage() {
        const { currentImage, galleries, categoryForWatchID } = this.state;

        if (currentImage === galleries[categoryForWatchID].length - 1) return;

        this.gotoNext();
    }

    openLightbox(index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true
        });
    }

    closeLightbox() {
        this.setState({ currentImage: 0, lightboxIsOpen: false });
    }

    loadDates(watchID) {
        this.setState({ loaded: false, error: null, watchID });

        const params = {};
        const data = { auth: { func: this.SERMONS_FUNC.getDates }, params };
        post(this.SERMONS_API, data)
            .then(data => {
                if (data["response"] !== false) {
                    this.setState({
                        minYear: data["min_value"],
                        maxYear: data["max_value"]
                    });

                    this.loadSermons();
                } else {
                    this.setState({
                        minYear: 0,
                        maxYear: 0
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    loadSermons() {
        this.setState({ error: null, loaded: false });

        const { watchID, filters, sortBy, currentSermonsPage } = this.state;

        const urlParams = new URLSearchParams();
        urlParams.set("page", currentSermonsPage);

        for (const [filter, value] of Object.entries(filters)) {
            if (value) {
                urlParams.set(filter, value);
            }
        }
        if (sortBy) {
            urlParams.set("sort", sortBy);
        }

        const url = `${this.SERMONS_API}?${urlParams.toString()}`;

        get(url)
            .then(data => {
                if (data["response"] !== false) {
                    if (!watchID) {
                        const watchID = data["sermons"][0]["sermon_id"];
                        this.setState({ watchID });
                    }

                    this.getSermonData();

                    this.setState({
                        sermons: data["sermons"],
                        totalSermons: data["total_sermons"],
                        loaded: true
                    });
                } else {
                    const { isLoaded } = this.props;

                    this.setState({
                        loaded: true,
                        error: data["message"],
                        sermons: [],
                        totalSermons: 0
                    });
                    isLoaded(true);
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    loadBooks() {
        const params = {};
        const data = { auth: { func: this.SERMONS_FUNC.loadBooks }, params };
        post(this.SERMONS_API, data)
            .then(data => {
                if (data["response"] !== false) {
                    this.setState({
                        books: data
                    });
                } else {
                    this.setState({
                        books: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    loadSeries() {
        const params = {};
        const data = { auth: { func: this.SERMONS_FUNC.loadSeries }, params };
        post(this.SERMONS_API, data)
            .then(data => {
                if (data["response"] !== false) {
                    this.setState({
                        series: data
                    });
                } else {
                    this.setState({
                        series: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    loadSpeakers() {
        const params = {};
        const data = { auth: { func: this.SERMONS_FUNC.loadSpeakers }, params };
        post(this.SERMONS_API, data)
            .then(data => {
                if (data["response"] !== false) {
                    this.setState({
                        speakers: data
                    });
                } else {
                    this.setState({
                        speakers: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    getSermonData() {
        const id = this.state.watchID;
        get(`${this.SERMONS_API}/${id}`)
            .then(data => {
                if (data["response"] !== false) {
                    const { isLoaded } = this.props;
                    const tags = [];

                    if (
                        data["sermon_keywords"] &&
                        data["sermon_keywords"].hasOwnProperty(id) &&
                        data["sermon_keywords"][id].length
                    ) {
                        data["sermon_keywords"][id].map(({ keyword }) =>
                            tags.push(keyword)
                        );
                    }

                    this.setState({
                        sermonForWatch: data,
                        tags
                    });
                    isLoaded(true);
                } else {
                    this.setState({
                        error: data["message"],
                        sermonForWatch: {},
                        tags: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    getBookByID(id) {
        const { books } = this.state;
        const book = books.filter(book => book["book_id"] === id);

        return book.length ? book[0] : null;
    }

    getSeriesByID(id) {
        const { series } = this.state;
        const thisSeries = series.filter(series => series["series_id"] === id);

        return thisSeries.length ? thisSeries[0] : null;
    }

    getSpeakerByID(id) {
        const { speakers } = this.state;
        const speaker = speakers.filter(
            speaker => speaker["speaker_id"] === id
        );

        return speaker.length ? speaker[0] : null;
    }

    loadGalleries() {
        this.setState({ error: null });

        get(this.GALLERY_API)
            .then(data => {
                if (data["response"] !== false) {
                    const { isLoaded } = this.props;
                    const galleries = {};
                    data.map(media => {
                        if (!galleries[media.category])
                            galleries[media.category] = [];

                        galleries[media.category].push(media);
                    });
                    this.setState({
                        galleries,
                        loaded: true
                    });
                    isLoaded(true);
                } else {
                    this.setState({
                        loaded: true,
                        error: data["message"]
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    loadCategories() {
        const params = {};
        const data = {
            auth: { func: this.GALLERY_FUNC.loadCategories },
            params
        };

        post(this.GALLERY_API, data)
            .then(data => {
                if (data["response"] !== false) {
                    this.loadGalleries();
                    const { categoryForWatchID } = this.state;
                    let categoryForWatch = null;

                    if (categoryForWatchID)
                        categoryForWatch = data.filter(
                            cat =>
                                cat["category_id"] ===
                                parseInt(categoryForWatchID)
                        )[0];

                    this.setState({
                        categories: data,
                        categoryForWatch
                    });
                } else {
                    this.setState({
                        loaded: true,
                        error: data["message"],
                        categories: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: "Възникна неочаквана грешка! Моля, опитайте отново."
                });
            });
    }

    drawSermons() {
        const { language } = this.props;
        const {
            error,
            sermons,
            sermonForWatch,
            books,
            speakers,
            series,
            filters,
            sortBy,
            currentSermonsPage,
            totalSermons,
            copySuccess,
            target,
            loaded,
            minYear,
            maxYear,
            tags
        } = this.state;
        let result = [];
        let years = [];

        for (let year = maxYear; year >= minYear; year--) {
            years.push(year);
        }

        if (Object.keys(sermonForWatch).length) {
            const sermonDate = sermonForWatch["uploaded_at"]
                .split(" ")[0]
                .replace(/-/g, ".");
            const speaker = this.getSpeakerByID(sermonForWatch.speaker);
            const book = this.getBookByID(sermonForWatch.book);
            const series = this.getSeriesByID(sermonForWatch.series);
            const address = window.location.toString();
            const shareAddress =
                address.indexOf(sermonForWatch["sermon_id"]) !== -1
                    ? address
                    : `${address}/${sermonForWatch["sermon_id"]}`;

            result.push(
                <section className="main-player">
                    <B.Row noGutters className="bg-dark text-white pb-3">
                        <B.Col>
                            <B.Container>
                                <video
                                    key="mainVideoKey"
                                    className="w-100"
                                    src={sermonForWatch.media}
                                    controls
                                    poster={
                                        sermonForWatch.cover !== "/storage/"
                                            ? sermonForWatch.cover
                                            : ""
                                    }
                                    controlsList="nodownload"
                                />
                                <h3
                                    key="mainVideoTitleKey"
                                    className="sermon-title d-md-inline-block"
                                >
                                    {sermonForWatch.title}
                                </h3>
                                <div
                                    key="mainVideoMetaKey"
                                    className="share-wrapper d-md-inline-block float-md-right mb-1 mb-md-0"
                                >
                                    <span className="d-md-none">
                                        <b>{lang[language].share}</b>
                                    </span>
                                    <FacebookShareButton
                                        key="facebookShareKey"
                                        url={shareAddress}
                                        quote={`${
                                            sermonForWatch.title
                                        } - проповед ${
                                            sermonForWatch.speaker
                                                ? ` на ${speaker.name}`
                                                : ""
                                        } от ${sermonDate})}`}
                                        children={
                                            <FontAwesomeIcon
                                                icon={[
                                                    "fab",
                                                    "facebook-square"
                                                ]}
                                                fixedWidth
                                                size="lg"
                                                title={`${lang[language].shareWith} Facebook`}
                                            />
                                        }
                                        className="ml-2 d-inline-block"
                                    />
                                    <TwitterShareButton
                                        key="twitterShareKey"
                                        url={shareAddress}
                                        title={`${sermonForWatch.title} - проповед от ${sermonDate})}`}
                                        children={
                                            <FontAwesomeIcon
                                                icon={["fab", "twitter-square"]}
                                                fixedWidth
                                                size="lg"
                                                title={`${lang[language].shareWith} Twitter`}
                                            />
                                        }
                                        className="ml-2 d-inline-block"
                                    />
                                    <B.Button
                                        key="linkShareKey"
                                        type="button"
                                        variant="link"
                                        className="ml-2 p-0"
                                        title={lang[language].copyAddress}
                                        ref={this.attachRef}
                                        onClick={ev => this.copyToClipboard(ev)}
                                    >
                                        <FontAwesomeIcon
                                            icon="link"
                                            fixedWidth
                                            size="lg"
                                        />
                                    </B.Button>
                                    <B.Overlay
                                        target={target}
                                        show={copySuccess}
                                        placement="auto"
                                    >
                                        {props => (
                                            <B.Tooltip {...props}>
                                                {lang[language].copied}
                                            </B.Tooltip>
                                        )}
                                    </B.Overlay>
                                    <textarea
                                        className="textarea-for-copy"
                                        ref={textarea =>
                                            (this.textArea = textarea)
                                        }
                                        value={shareAddress}
                                        readOnly
                                    />
                                </div>
                                <B.Row className="sermon-meta text-light">
                                    <B.Col
                                        key="sermonMetaDate"
                                        xs={12}
                                        md={6}
                                        className="mb-1"
                                    >
                                        <b>{lang[language].date}:</b>{" "}
                                        {sermonDate}
                                    </B.Col>
                                    {sermonForWatch.speaker ? (
                                        <B.Col
                                            key="sermonMetaSpeaker"
                                            xs={12}
                                            md={6}
                                            className="mb-1"
                                        >
                                            <b>{lang[language].speaker}:</b>{" "}
                                            {speaker ? speaker.name : ""}
                                        </B.Col>
                                    ) : (
                                        ""
                                    )}
                                    {sermonForWatch.book ? (
                                        <B.Col
                                            key="sermonMetaRef"
                                            xs={12}
                                            md={6}
                                            className="mb-1"
                                        >
                                            <b>{lang[language].bookRef}:</b>{" "}
                                            {book ? book.book : ""}{" "}
                                            {sermonForWatch.reference}
                                        </B.Col>
                                    ) : (
                                        ""
                                    )}
                                    {sermonForWatch.series ? (
                                        <B.Col
                                            key="sermonMetaSeries"
                                            xs={12}
                                            md={6}
                                            className="mb-1"
                                        >
                                            <b>
                                                {lang[language].singleSeries}:
                                            </b>{" "}
                                            {series ? series.series : ""}
                                        </B.Col>
                                    ) : (
                                        ""
                                    )}
                                    {sermonForWatch.attachment !==
                                    "/storage/" ? (
                                        <B.Col
                                            key="sermonMetaAttachment"
                                            xs={12}
                                            md={6}
                                            className="mb-1"
                                        >
                                            <b>{lang[language].attachment}:</b>
                                            <a
                                                href={sermonForWatch.attachment}
                                                download={
                                                    sermonForWatch.attachment
                                                }
                                                className="text-white ml-1"
                                                target="_blank"
                                            >
                                                {lang[language].download}
                                            </a>
                                        </B.Col>
                                    ) : (
                                        ""
                                    )}
                                    {tags.length ? (
                                        <B.Col
                                            key="sermonMetaTags"
                                            xs={12}
                                            md={6}
                                            className="mb-1"
                                        >
                                            <b>{lang[language].tags}:</b>{" "}
                                            tags.join(', ')
                                        </B.Col>
                                    ) : (
                                        ""
                                    )}
                                </B.Row>
                            </B.Container>
                        </B.Col>
                    </B.Row>
                </section>
            );
        }

        result.push(
            <B.Container as="section" className="sermons-wrapper py-3">
                <B.Row className="headers align-items-center">
                    <B.Col xs={12} md={8}>
                        <h4 className="font-weight-light mb-md-0">
                            Общо {totalSermons}{" "}
                            {lang[language].sermons.toLowerCase()}
                        </h4>
                    </B.Col>
                    <B.Col xs={12} md={4}>
                        <B.Form.Control
                            as="select"
                            className="custom-select"
                            value={sortBy}
                            onChange={ev => this.handleSort(ev)}
                        >
                            <option value="0">{lang[language].sortBy}</option>
                            <option value="book">{lang[language].book}</option>
                            <option value="speaker">
                                {lang[language].speaker}
                            </option>
                            <option value="series">
                                {lang[language].singleSeries}
                            </option>
                        </B.Form.Control>
                    </B.Col>
                </B.Row>

                <B.Row className="filters align-items-center py-2 my-2 bg-light">
                    <B.Col xs={12} md={4} className="mb-1">
                        {lang[language].filterBy}
                    </B.Col>
                    {books.length ? (
                        <B.Col xs={12} md={2} className="filter mb-1">
                            <B.Form.Control
                                as="select"
                                className="custom-select"
                                name="book"
                                value={filters.book || ""}
                                onChange={ev => this.handleFilters(ev)}
                            >
                                <option value="0">{lang[language].book}</option>
                                {books.map(book => (
                                    <option
                                        key={book["book_id"]}
                                        value={book["book_id"]}
                                    >
                                        {book.book}
                                    </option>
                                ))}
                            </B.Form.Control>
                        </B.Col>
                    ) : (
                        ""
                    )}
                    {speakers.length ? (
                        <B.Col xs={12} md={2} className="filter mb-1">
                            <B.Form.Control
                                as="select"
                                className="custom-select"
                                name="speaker"
                                value={filters.speaker || ""}
                                onChange={ev => this.handleFilters(ev)}
                            >
                                <option value="0">
                                    {lang[language].speaker}
                                </option>
                                {speakers.map(speaker => (
                                    <option
                                        key={speaker["speaker_id"]}
                                        value={speaker["speaker_id"]}
                                    >
                                        {speaker.name}
                                    </option>
                                ))}
                            </B.Form.Control>
                        </B.Col>
                    ) : (
                        ""
                    )}
                    {series.length ? (
                        <B.Col xs={12} md={2} className="filter mb-1">
                            <B.Form.Control
                                as="select"
                                className="custom-select"
                                name="series"
                                value={filters.series || ""}
                                onChange={ev => this.handleFilters(ev)}
                            >
                                <option value="0">
                                    {lang[language].singleSeries}
                                </option>
                                {series.map(series => (
                                    <option
                                        key={series["series_id"]}
                                        value={series["series_id"]}
                                    >
                                        {series.series}
                                    </option>
                                ))}
                            </B.Form.Control>
                        </B.Col>
                    ) : (
                        ""
                    )}
                    {years.length ? (
                        <B.Col xs={12} md={2} className="filter mb-1">
                            <B.Form.Control
                                as="select"
                                className="custom-select"
                                name="year"
                                value={filters.year || ""}
                                onChange={ev => this.handleFilters(ev)}
                            >
                                <option value="0">{lang[language].year}</option>
                                {years.map(year => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </B.Form.Control>
                        </B.Col>
                    ) : (
                        ""
                    )}
                </B.Row>
                {loaded ? (
                    sermons.length || !error ? (
                        <div className="sermons-list mt-3">
                            <B.Row>
                                {sermons.map(sermon => {
                                    const sermonID = sermon["sermon_id"];
                                    const sermonDate = sermon["uploaded_at"]
                                        .split(" ")[0]
                                        .replace(/-/g, ".");
                                    const sermonCover = sermon.cover;
                                    return (
                                        <B.Col
                                            key={sermonID}
                                            xs={12}
                                            md={6}
                                            lg={4}
                                            className="mb-3"
                                        >
                                            <Link
                                                to={`/media/sermons/${sermonID}`}
                                            >
                                                <B.Card>
                                                    <B.CardImg
                                                        variant="top"
                                                        src={
                                                            sermonCover !==
                                                            "/storage/"
                                                                ? sermonCover
                                                                : this
                                                                      .MEDIA_PLACEHOLDER
                                                        }
                                                    />
                                                    <B.Card.Body>
                                                        <B.Card.Title className="text-body font-weight-normal">
                                                            {sermon.title}
                                                        </B.Card.Title>
                                                        {sermon.speaker ? (
                                                            <B.Card.Subtitle className="text-muted mb-3">
                                                                {sermon.speaker}
                                                            </B.Card.Subtitle>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {sermon.series ? (
                                                            <small className="d-block text-muted">
                                                                <strong>
                                                                    {
                                                                        lang[
                                                                            language
                                                                        ]
                                                                            .singleSeries
                                                                    }
                                                                    :
                                                                </strong>{" "}
                                                                {sermon.series}
                                                            </small>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {sermon.book ? (
                                                            <small className="d-block text-muted">
                                                                <strong>
                                                                    {
                                                                        lang[
                                                                            language
                                                                        ].book
                                                                    }
                                                                    :
                                                                </strong>{" "}
                                                                {sermon.book}
                                                            </small>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <small className="d-block text-muted">
                                                            <strong>
                                                                {
                                                                    lang[
                                                                        language
                                                                    ].date
                                                                }
                                                                :
                                                            </strong>{" "}
                                                            {sermonDate}
                                                        </small>
                                                    </B.Card.Body>
                                                </B.Card>
                                            </Link>
                                        </B.Col>
                                    );
                                })}
                                {sermons.length === 20 ? (
                                    <B.Col
                                        key="nextPage"
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        className="mb-3"
                                    >
                                        <B.Button
                                            type="button"
                                            variant="link"
                                            className="nextSermon text-muted"
                                            onClick={() =>
                                                this.handleSermonsPagination(
                                                    currentSermonsPage + 1
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon="chevron-right"
                                                fixedWidth
                                                size="4x"
                                                className="mb-5"
                                            />
                                            {lang[language].next}
                                        </B.Button>
                                    </B.Col>
                                ) : (
                                    ""
                                )}
                            </B.Row>
                        </div>
                    ) : (
                        <B.Alert variant="info">{error}</B.Alert>
                    )
                ) : (
                    <B.ProgressBar
                        animated
                        now={100}
                        className="mt-4"
                        label="Loading..."
                    />
                )}
                <div className="pagination-wrapper text-center mt-4">
                    <B.Pagination>
                        <B.Pagination.Item
                            tabIndex={0}
                            onClick={() =>
                                this.handleSermonsPagination(
                                    currentSermonsPage - 1
                                )
                            }
                            disabled={currentSermonsPage === 1}
                        >
                            {lang[language].back}
                        </B.Pagination.Item>
                        <B.Pagination.Item
                            tabIndex={0}
                            onClick={() =>
                                this.handleSermonsPagination(
                                    currentSermonsPage + 1
                                )
                            }
                            disabled={sermons.length < 20}
                        >
                            {lang[language].next}
                        </B.Pagination.Item>
                    </B.Pagination>
                </div>
            </B.Container>
        );

        return result;
    }

    drawGallery() {
        const { language } = this.props;
        const {
            error,
            categories,
            galleries,
            currentPage,
            resultsPerPage
        } = this.state;
        let result = [];
        const indexOfLastResult = currentPage * resultsPerPage;
        const indexOfFirstResult = indexOfLastResult - resultsPerPage;
        const currentCategories = categories.slice(
            indexOfFirstResult,
            indexOfLastResult
        );
        let pageNumbers = [];

        for (
            let i = 1;
            i <= Math.ceil(categories.length / resultsPerPage);
            i++
        ) {
            pageNumbers.push(i);
        }

        result.push(
            <B.Container as="section" className="gallery-wrapper py-3">
                {categories.length || !error ? (
                    <B.Row>
                        {currentCategories.map(category => {
                            const categoryID = category["category_id"];
                            const img = (
                                <B.CardImg
                                    variant="top"
                                    src={
                                        galleries.hasOwnProperty(categoryID)
                                            ? galleries[categoryID][0].media
                                            : "/images/default_album.png"
                                    }
                                    title={category.category}
                                />
                            );
                            const catDate = category["created_at"].split("-");
                            const date = new Date(
                                catDate[2],
                                catDate[1],
                                catDate[0]
                            );
                            return (
                                <B.Col
                                    key={categoryID}
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    className="mb-3"
                                >
                                    <Link to={`/media/gallery/${categoryID}`}>
                                        <B.Card>
                                            {img}
                                            <B.Card.Body>
                                                <B.Card.Title className="text-body font-weight-normal">
                                                    {category.category}
                                                </B.Card.Title>
                                                <B.Card.Subtitle className="text-muted font-weight-light">
                                                    {lang[language].addedOn}{" "}
                                                    {`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}
                                                </B.Card.Subtitle>
                                            </B.Card.Body>
                                        </B.Card>
                                    </Link>
                                </B.Col>
                            );
                        })}
                    </B.Row>
                ) : (
                    <B.Alert variant="info">{error}</B.Alert>
                )}
                {pageNumbers.length > 1 ? (
                    <div className="pagination-wrapper text-center mt-4">
                        <B.Pagination>
                            <B.Pagination.First
                                tabIndex={0}
                                onClick={() => this.handlePagination(1)}
                                disabled={currentPage === 1}
                            />
                            <B.Pagination.Prev
                                tabIndex={0}
                                onClick={() =>
                                    this.handlePagination(currentPage - 1)
                                }
                                disabled={currentPage === 1}
                            />
                            {pageNumbers.map(page => (
                                <B.Pagination.Item
                                    key={page}
                                    active={page === currentPage}
                                    tabIndex={0}
                                    onClick={() => this.handlePagination(page)}
                                >
                                    {page}
                                </B.Pagination.Item>
                            ))}
                            <B.Pagination.Next
                                tabIndex={0}
                                onClick={() =>
                                    this.handlePagination(currentPage + 1)
                                }
                                disabled={currentPage === pageNumbers.length}
                            />
                            <B.Pagination.Last
                                tabIndex={0}
                                onClick={() =>
                                    this.handlePagination(pageNumbers.length)
                                }
                                disabled={currentPage === pageNumbers.length}
                            />
                        </B.Pagination>
                    </div>
                ) : (
                    ""
                )}
            </B.Container>
        );

        return result;
    }

    drawAlbum() {
        const { language } = this.props;
        const {
            error,
            galleries,
            categoryForWatchID,
            lightboxIsOpen,
            currentImage
        } = this.state;
        let result = [];
        let images = [];

        result.push(
            <B.Container as="section" className="gallery-wrapper py-3">
                {error ? (
                    <B.Container as="section" className="gallery-wrapper py-3">
                        <B.Alert variant="info">{error}</B.Alert>
                    </B.Container>
                ) : (
                    ""
                )}
                {galleries.hasOwnProperty(categoryForWatchID) && !error ? (
                    <B.CardColumns>
                        {galleries[categoryForWatchID].map(
                            ({ media, title }, i) => {
                                images.push({
                                    src: media,
                                    caption: title,
                                    alt: title
                                });
                                return (
                                    <a
                                        href={media}
                                        key={i}
                                        onClick={ev => this.openLightbox(i, ev)}
                                    >
                                        <B.Card>
                                            <B.CardImg
                                                variant="top"
                                                src={media}
                                                alt={title}
                                            />
                                        </B.Card>
                                    </a>
                                );
                            }
                        )}
                    </B.CardColumns>
                ) : (
                    <B.Alert variant="info">
                        {lang[language].emptyAlbum}
                    </B.Alert>
                )}
            </B.Container>
        );

        result.push(
            <Lightbox
                currentImage={currentImage}
                images={images}
                isOpen={lightboxIsOpen}
                onClickImage={() => this.handleClickImage()}
                onClickPrev={() => this.gotoPrevious()}
                onClickNext={() => this.gotoNext()}
                onClickThumbnail={index => this.gotoImage(index)}
                onClose={() => this.closeLightbox()}
                backdropClosesModal={true}
                imageCountSeparator={` ${lang[language].countSeparator} `}
                showThumbnails={true}
                width={1920}
            />
        );

        return result;
    }

    render() {
        const { language } = this.props;
        const {
            type,
            categoryForWatch,
            categoryForWatchID,
            error404
        } = this.state;

        if (error404) {
            return <Error404 />;
        }

        if (type === "sermons") {
            return (
                <main id="app" role="main" key="sermonsMain">
                    <PageTitle title={lang[language].sermons}>
                        <h2 key="pageSubTitleKey">
                            {lang[language].resources}
                        </h2>
                    </PageTitle>
                    {this.drawSermons()}
                </main>
            );
        } else if (type === "gallery") {
            return (
                <main id="app" role="main" key="galleryMain">
                    <PageTitle
                        title={`${
                            categoryForWatch
                                ? categoryForWatch.category
                                : `${lang[language].gallery}`
                        }`}
                    >
                        {categoryForWatchID ? (
                            <h2>
                                <Link to="/media/gallery">
                                    {lang[language].gallery}
                                </Link>
                            </h2>
                        ) : (
                            <h2>{lang[language].resources}</h2>
                        )}
                    </PageTitle>
                    {categoryForWatchID ? this.drawAlbum() : this.drawGallery()}
                </main>
            );
        }

        return null;
    }
}

export default Media;
