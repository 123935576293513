import React, {Component} from "react";
import {Route, Switch} from 'react-router-dom';
import * as B from 'react-bootstrap';

//Import Layout
import SidebarAdmin from './dashboard/layout/SidebarAdmin';

//Import Components
import Dashboard from './dashboard/Dashboard';
import Files from "./dashboard/Files";
import Galleries from "./dashboard/Galleries";
import LiveStream from "./dashboard/LiveStream";
import Menus from "./dashboard/Menus";
import Slider from "./dashboard/Slider";
import Pages from "./dashboard/Pages";
import Sermons from "./dashboard/Sermons";
import Users from "./dashboard/Users";
import Settings from "./dashboard/Settings";
import News from "./dashboard/News";
import Contact from "./dashboard/Contact";

export default class Admin extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        const {isLoaded} = this.props;
        isLoaded(true);
        document.title = "Админ панел - ЕБЦ София 1";
    }

    render() {
        const {isLive, onStartStream, onScheduleStream, onStopStream, languages, onFBClick, fbLogin, fbLoginName, fbPageName, fbAccessToken} = this.props;

        return (
            <React.Fragment>
                <B.Container as="div" fluid role="main">
                    <B.Row className="h-100">
                        <SidebarAdmin/>
                        <B.Col as="main" xs={12} md={9} xl={10} className="p-3">
                            <Switch>
                                <Route exact path="/admin/"
                                       render={props => <Dashboard {...props} isLive={isLive} languages={languages}
                                                                   onStartStream={onStartStream}
                                                                   onScheduleStream={onScheduleStream}
                                                                   onStopStream={onStopStream}/>}/>
                                <Route exact path="/admin/files"
                                       render={props => <Files {...props} languages={languages}/>}/>
                                <Route exact path="/admin/galleries"
                                       render={props => <Galleries {...props} languages={languages}/>}/>
                                <Route exact path="/admin/live-stream"
                                       render={props => <LiveStream {...props} isLive={isLive} languages={languages}
                                                                    onStartStream={onStartStream}
                                                                    onScheduleStream={onScheduleStream}
                                                                    onStopStream={onStopStream}
                                                                    fbLogin={fbLogin}
                                                                    fbLoginName={fbLoginName}
                                                                    fbPageName={fbPageName}
                                                                    fbAccessToken={fbAccessToken}
                                                                    onFBClick={(scope, pageID) => onFBClick(scope, pageID)}/>}/>
                                <Route exact path="/admin/menus"
                                       render={props => <Menus {...props} languages={languages}/>}/>
                                <Route exact path="/admin/slider"
                                       render={props => <Slider {...props} languages={languages}/>}/>
                                <Route exact path="/admin/slider/:id"
                                       render={props => <Slider {...props} languages={languages}/>}/>
                                <Route exact path="/admin/pages"
                                       render={props => <Pages {...props} languages={languages}/>}/>
                                <Route exact path="/admin/pages/:id"
                                       render={props => <Pages {...props} languages={languages}/>}/>
                                <Route exact path="/admin/sermons"
                                       render={props => <Sermons {...props} languages={languages}/>}/>
                                <Route exact path="/admin/news"
                                       render={props => <News {...props} languages={languages}/>}/>
                                <Route exact path="/admin/settings"
                                       render={props => <Settings {...props} languages={languages}/>}/>
                                <Route exact path="/admin/users"
                                       render={props => <Users {...props} languages={languages}/>}/>
                                <Route exact path="/admin/contact"
                                       render={props => <Contact {...props} languages={languages}/>}/>
                            </Switch>
                        </B.Col>
                    </B.Row>
                </B.Container>
            </React.Fragment>
        );
    }
}
