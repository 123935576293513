import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as B from 'react-bootstrap';
import Countdown from "./Countdown";
import {Link} from "react-router-dom";

class Carousel extends Component {
    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            index: 0,
            direction: null,
        };
    }

    handleSelect(selectedIndex, e) {
        this.setState({
            index: selectedIndex,
            direction: e,
        });
    }

    render() {
        const {slides} = this.props;
        const {index, direction} = this.state;

        if (slides.length) {
            return (
                <B.Carousel
                    key="pageCarouselKey"
                    activeIndex={index}
                    controls={slides.length > 1}
                    indicators={slides.length > 1}
                    direction={direction}
                    onSelect={this.handleSelect}>
                    {slides.map(slide => {
                        const type = slide['media_type'].indexOf('video') !== -1 ? 'video' : 'image';
                        const slideBackground = {
                            backgroundImage: `url('${slide['slide_addr']}')`
                        };
                        return (
                            type === 'image' ?
                                <B.Carousel.Item key={`slideID-${slide['slide_id']}`} className="has-mask" style={slideBackground}>
                                    <B.Carousel.Caption>
                                        {slide.title ? <h3 className="display-2">{slide.title}</h3> : ''}
                                        {slide['sub_title'] ? <p className="lead">{slide['sub_title']}</p> : ''}
                                        {slide.button && slide['button_addr'] ?
                                            <a href={slide['button_addr']}
                                               className="btn btn-ebc-flat btn-lg mt-2 mb-4">{slide.button}</a> : ''}
                                    </B.Carousel.Caption>
                                </B.Carousel.Item>
                                :
                                <B.Carousel.Item key={`slideID-${slide['slide_id']}`}>
                                    <video className="d-block w-100" autoPlay muted loop controls={false}>
                                        <source src={slide['slide_addr']} type={slide['media_type']}/>
                                    </video>
                                    <B.Carousel.Caption>
                                        {slide.title ? <h3 className="display-2">{slide.title}</h3> : ''}
                                        {slide['sub_title'] ? <p className="lead">{slide['sub_title']}</p> : ''}
                                        {slide.button && slide['button_addr'] ?
                                            <a href={slide['button_addr']}
                                               className="btn btn-ebc-flat btn-lg mt-2 mb-4">{slide.button}</a> : ''}
                                    </B.Carousel.Caption>
                                </B.Carousel.Item>
                        )
                    })}
                </B.Carousel>
            );
        }
    }
}

Carousel.propTypes = {
  slides: PropTypes.array.isRequired
};

export default Carousel;
