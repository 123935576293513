import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import * as B from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class MenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    addChildren() {
        const {children} = this.props;

        if (children) {
            let childrenResult = [];

            children.map(child => {
                const addr = `${child.addr.indexOf('/') === 0 ? '' : '/'}${child.addr}`;
                childrenResult.push(<NavLink key={child.id} to={addr} className="dropdown-item" exact>{child.name}</NavLink>);
            });

            return childrenResult;
        }
    }

    handleOpen() {
        this.setState({isOpen: true})
    }

    handleClose() {
        this.setState({isOpen: false})
    }

    render() {
        const {id, name, addr, icon} = this.props;
        return (
            <React.Fragment>
                {this.props.children ?
                    <B.NavDropdown title={name}
                                   id={`dropdown${addr}${id}`}
                                   onMouseEnter={() => this.handleOpen()}
                                   onMouseLeave={() => this.handleClose()}
                                   show={this.state.isOpen}
                                   onToggle={() => true}>
                        {this.addChildren()}
                    </B.NavDropdown> :
                    <B.NavItem as='li' key={id}>
                        {addr.indexOf('http') !== -1 ?
                            <a href={addr} target="_blank" className="nav-link">{icon ?
                                <FontAwesomeIcon icon={icon} className="mr-2"/> : ''}{name}</a>
                            :
                            <NavLink to={addr.trim()} className="nav-link" exact>{name}</NavLink>
                        }
                    </B.NavItem>
                }
            </React.Fragment>
        );
    }
};
