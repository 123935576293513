import React, {Component} from 'react';
import {Link} from "react-router-dom";

//Packages
import Analytics from './GoogleAnalytics';

//Components
import FileUploadForm from './widgets/FileUploadForm';
import LiveStream from './widgets/LiveStream';
import CreatePage from './widgets/CreatePage';
import MostWatched from './widgets/MostWatched';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {isLive, onStartStream, onScheduleStream, onStopStream} = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 col-xl-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Качи медия:</h4>
                                <FileUploadForm/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title d-inline-block">Живо излъчване:</h4>
                                <LiveStream isLive={isLive}
                                            onStartStream={onStartStream}
                                            onScheduleStream={onScheduleStream}
                                            onStopStream={onStopStream}/>
                                <span className="float-right"><Link to="/admin/live-stream">Повече настройки</Link></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4 mb-3">
                        <div className="card w-100">
                            <div className="card-body">
                                <h4 className="card-title">Създай нова страница:</h4>
                                <CreatePage/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Google Analytics:</h4>
                                {/*<Analytics/>*/}
                                <small className="text-muted">Ще работи само на production сървър, поради автентикация
                                    по домейн.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Най-гледани видеа:</h4>
                                <MostWatched/>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
