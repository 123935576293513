import React, {Component} from 'react';
import Alerts from "./Alerts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as B from 'react-bootstrap';
import {get, post} from "../../API";

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            alerts: [],
            modalShow: false,
            validated: false,
            formsData: {},
            tickets: [],
            ticketForAnswerID: null,
            ticketHasAnswer: false
        };
        this.API_URL = '/api/contact';
        this.CONTACT_FUNC = {
            'answer': 1,
            'delete': 2
        };
        this.TICKET_STATUS = {
            0: 'Нов',
            1: 'Отговорен'
        }
    }

    componentWillMount() {
        this.loadTickets();
    }

    modalOpen() {
        this.setState({modalShow: true});
    }

    modalHide(reset) {
        if (reset) {
            const formsData = {...this.state.formsData};
            formsData['name'] = null;
            formsData['mail'] = null;
            formsData['message'] = null;
            formsData['answer'] = null;
            this.setState({modalShow: false, validated: false, formsData, ticketForAnswerID: null});
            return;
        }
        this.setState({modalShow: false});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};

        formsData[name] = value;

        this.setState({formsData});
    }

    handleCloseAlert(alert) {
        const currentAlerts = [...this.state.alerts];
        const alerts = currentAlerts.filter(al => al !== alert);
        this.setState({alerts});
    }

    loadTickets() {
        this.setState({loaded: false, error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    this.setState({
                        loaded: true,
                        tickets: data
                    });
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message'],
                        tickets: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    getTicketData(id) {
        this.setState({ticketForAnswerID: id});
        get(`${this.API_URL}/${id}`)
            .then(data => {
                if (data['response'] !== false) {
                    let ticketHasAnswer = false;
                    const formsData = {...this.state.formsData};
                    formsData['name'] = data['name'];
                    formsData['mail'] = data['email'];
                    formsData['message'] = data['message'];
                    if (data['answer']) {
                        formsData['answer'] = data['answer'];
                        ticketHasAnswer = true;
                    }
                    this.setState({formsData, ticketHasAnswer});
                    this.modalOpen();
                } else {
                    this.setState({
                        loaded: false,
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    answerTicket(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {ticketForAnswerID} = this.state;
            const {answer} = this.state.formsData;
            const params = {ticket_id: ticketForAnswerID, answer};
            const data = {auth: {func: this.CONTACT_FUNC.answer}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.modalHide(true);
                        this.loadTickets();
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    deleteTicket(ticket_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете това писмо?')) {
            this.setState({loaded: false});
            const params = {ticket_id};
            const data = {auth: {func: this.CONTACT_FUNC.delete}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.loadTickets();
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    drawTickets() {
        const {tickets} = this.state;

        if (tickets.length) {
            return (
                <B.Table striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Име</th>
                        <th>Статус</th>
                        <th>Изпратено на</th>
                        <th>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tickets.map(({id, name, status, created_at}) => {
                        const dbDate = created_at.split('-');
                        const date = new Date(dbDate[2], dbDate[1], dbDate[0]);
                        const answerBtn = <B.Button type="button" variant="link"
                                                    title={status === 0 ? 'Отговори' : 'Прочети'}
                                                    onClick={() => this.getTicketData(id)}><FontAwesomeIcon
                            icon={status === 0 ? 'edit' : 'eye'} fixedWidth/></B.Button>;
                        const deleteBtn = <B.Button type="button" variant="link" title="Изтрий"
                                                    onClick={() => this.deleteTicket(id)}><FontAwesomeIcon
                            icon="trash-alt" fixedWidth className="text-danger"/></B.Button>;

                        return (<tr key={id}>
                            <td>{id}</td>
                            <td>{name}</td>
                            <td>{this.TICKET_STATUS[status]}</td>
                            <td>{`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}</td>
                            <td>{answerBtn}{deleteBtn}</td>
                        </tr>)
                    })}
                    </tbody>
                </B.Table>
            );
        }
    }

    drawModals() {
        const {modalShow, formsData, validated, ticketHasAnswer} = this.state;

        return (
            <B.Modal
                id='files'
                centered
                show={modalShow}
                onHide={() => this.modalHide(true)}
            >
                <B.Modal.Header closeButton>
                    <B.Modal.Title>Отговори на писмо</B.Modal.Title>
                </B.Modal.Header>
                <B.Modal.Body>
                    <B.Form
                        noValidate
                        validated={validated}
                        onSubmit={e => this.answerTicket(e)}
                        method="POST">
                        <B.Row>
                            <B.Col xs={12} md={6}>
                                <B.Form.Group controlId="name">
                                    <B.Form.Label>Име</B.Form.Label>
                                    <B.Form.Control type="text" plaintext readOnly defaultValue={formsData['name']}/>
                                </B.Form.Group>
                            </B.Col>
                            <B.Col xs={12} md={6}>
                                <B.Form.Group controlId="mail">
                                    <B.Form.Label>Мейл</B.Form.Label>
                                    <B.Form.Control type="email" plaintext readOnly defaultValue={formsData['mail']}/>
                                </B.Form.Group>
                            </B.Col>
                        </B.Row>
                        <B.Form.Group controlId="message">
                            <B.Form.Label>Съобщение</B.Form.Label>
                            <B.Form.Control as="textarea" plaintext readOnly defaultValue={formsData['message']}/>
                        </B.Form.Group>
                        <B.Form.Group controlId="answer">
                            <B.Form.Label>Отговор</B.Form.Label>
                            <B.Form.Control as="textarea" name="answer" value={formsData['answer'] || ''} rows="3"
                                            readOnly={ticketHasAnswer} onChange={ev => this.handleInputChange(ev)}
                                            required/>
                            <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                        </B.Form.Group>
                        <B.Form.Group className="text-center">
                            {ticketHasAnswer ?
                                <B.Button type="button" variant="secondary" onClick={() => this.modalHide(true)}>
                                    <FontAwesomeIcon icon='times' fixedWidth className="mr-2"/>
                                    Затвори
                                </B.Button> :
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon='edit' fixedWidth className="mr-2"/>
                                    Отговори
                                </B.Button>
                            }
                        </B.Form.Group>
                    </B.Form>
                </B.Modal.Body>
            </B.Modal>
        );
    }

    render() {
        const {loaded, error, alerts} = this.state;

        if (error) {
            return (
                <ContactLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}>
                    <B.Alert variant='danger'>
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                </ContactLayout>
            );
        } else if (!loaded) {
            return (
                <ContactLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}>
                    <B.ProgressBar animated now={100} label="Loading..."/>
                </ContactLayout>
            );
        } else {
            return (
                <ContactLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}>
                    {this.drawTickets()}
                    {this.drawModals()}
                </ContactLayout>
            );
        }
    }
}

const ContactLayout = props => {
    const {children, alerts, handleCloseClick} = props;
    return (
        <>
            {alerts.length ?
                <Alerts alerts={alerts} handleCloseClick={(alert) => handleCloseClick(alert)}/>
                : ''}
            <div className="page-header mb-3">
                <h2 className="d-inline-block">Обратна връзка</h2>
            </div>
            {children}
        </>
    );
};
