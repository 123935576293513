import React, {Component} from 'react';
import Alerts from "./Alerts";
import * as B from 'react-bootstrap';
import TextEditor from './TextEditor';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {get, post, postFile} from "../../API";
// import {WithContext as ReactTags} from 'react-tag-input';

const KeyCodes = {
    space: 32,
    enter: 13,
};

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            alerts: [],
            modalShow: {
                'news': false,
                'tags': false
            },
            validated: false,
            formsData: {},
            news: [],
            newsEditID: null,
            tags: [],
            tagsTemp: []
        };
        this.API_URL = '/api/news';
        this.API_KEYWORDS_URL = '/api/keywords';
        this.NEWS_FUNC = {
            'add': 1,
            'edit': 2,
            'delete': 3,
            'updateTags': 4
        };
        this.KEYWORDS_FUNC = {
            'add': 1,
            'edit': 2,
            'delete': 3
        };
        this.TAGS_DELIMITERS = [KeyCodes.space, KeyCodes.enter];
    }

    componentWillMount() {
        this.loadNews();
        this.loadTags();
    }

    modalOpen(id) {
        const modalShow = {...this.state.modalShow};
        modalShow[id] = true;

        this.setState({modalShow});
    }

    modalHide(id, reset) {
        const modalShow = {...this.state.modalShow};
        modalShow[id] = false;

        if (reset) {
            const formsData = {...this.state.formsData};
            formsData['newsMedia'] = null;
            formsData['newsMediaThumb'] = null;
            formsData['newsTitle'] = null;
            formsData['newsText'] = null;
            formsData['newsLanguage'] = null;
            this.setState({modalShow, validated: false, formsData, newsEditID: null});
            return;
        }
        this.setState({modalShow});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};

        formsData[name] = value;

        this.setState({formsData});
    }

    handleFileInputChange(event, allowedFormats) {
        const target = event.target;
        const value = target.files[0];
        const name = target.name;
        const type = value.type;

        if (!allowedFormats.includes(type)) {
            let alerts = [...this.state.alerts];
            alerts.push({type: 'danger', text: 'Неподдържан формат!'});
            this.setState({alerts});
            return;
        }

        const formsData = {...this.state.formsData};
        formsData[name] = value;
        formsData['mediaType'] = type;

        this.setState({formsData});
    }

    getEditorContent(content, name) {
        const formsData = {...this.state.formsData};
        formsData[name] = content;

        this.setState({formsData});
    }

    handleCloseAlert(alert) {
        const currentAlerts = [...this.state.alerts];
        const alerts = currentAlerts.filter(al => al !== alert);
        this.setState({alerts});
    }

    handleTagsChange(ev, id) {
        const target = event.target;
        const value = target.value;
        const tags = [...this.state.tags];
        const thisTag = tags.filter(tag => tag['id'] === id);
        const index = thisTag.length ? tags.indexOf(thisTag[0]) : null;
        if (index !== null)
            tags[index]['name'] = value;

        this.setState({tags});
    }

    checkTag(tag) {
        const {tags} = this.state;

        return tags.filter(t => t.name === tag.name).length;
    }

    handleAddTag(tag) {
        const {tagsTemp} = this.state;
        const newTags = [...tagsTemp, tag];

        if (this.checkTag(tag) === 0)
            this.addTagSecondary(tag.name);

        this.setState({tagsTemp: newTags});
    }

    handleDeleteTag(i) {
        const {tagsTemp} = this.state;
        this.setState({
            tagsTemp: tagsTemp.filter((tag, index) => index !== i),
        });
    }

    loadNews() {
        this.setState({loaded: false, error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    this.setState({
                        loaded: true,
                        news: data['news']
                    });
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message'],
                        news: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadTags() {
        get(this.API_KEYWORDS_URL)
            .then(data => {
                if (data['response'] !== false) {
                    const tags = [];
                    data.map(({id, name}) => tags.push({id: id.toString(), name}));
                    this.setState({tags});
                } else {
                    this.setState({
                        tags: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    addEditNews(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;
        const {newsText} = this.state.formsData;

        this.setState({validated: true});

        if (newsText === undefined || newsText === '' || newsText === '<p></p>' || newsText === '<p><br></p>') {
            const alerts = [...this.state.alerts];
            alerts.push({type: 'danger', text: 'Съдържанието на новината не може да бъде празно!'});

            this.setState({alerts});
            return;
        }

        if (form.checkValidity()) {
            const {newsEditID, tagsTemp} = this.state;
            const {newsTitle, newsMedia, newsLanguage} = this.state.formsData;
            const func = newsEditID ? this.NEWS_FUNC.edit : this.NEWS_FUNC.add;
            const keywords = [];
            if (tagsTemp.length)
                tagsTemp.map(({id}) => keywords.push(id));
            const params = {
                title: newsTitle,
                text: newsText,
                lang_id: parseInt(newsLanguage),
                news_id: newsEditID,
                keywords
            };
            const formData = new FormData();
            formData.append('auth', JSON.stringify({func}));
            formData.append('params', JSON.stringify(params));
            if (newsMedia)
                formData.append('media', newsMedia);

            if (newsEditID)
                this.updateTags(keywords);

            postFile(this.API_URL, formData, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide('news', true);

                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts,
                            validated: false
                        });

                        this.loadNews();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    getNewsData(id) {
        get(`${this.API_URL}/${id}`)
            .then(data => {
                if (data['response'] !== false) {
                    const formsData = {...this.state.formsData};
                    const tagsTemp = [];
                    formsData['newsTitle'] = data['title'];
                    formsData['newsText'] = data['text'];
                    formsData['newsMediaThumb'] = data['media'] === '/storage/' ? null : data['media'];
                    formsData['newsLanguage'] = data['lang_id'];

                    if (data['news_keywords'] && data['news_keywords'].hasOwnProperty(id) && data['news_keywords'][id].length) {
                        data['news_keywords'][id].map(({id, keyword}) => tagsTemp.push({
                            id: id.toString(),
                            name: keyword
                        }));
                    }

                    this.setState({
                        formsData,
                        newsEditID: id,
                        tagsTemp
                    });
                    this.modalOpen('news');
                } else {
                    const alerts = [...this.state.alerts];
                    alerts.push({type: 'danger', text: data['message']});
                    this.setState({
                        loaded: true,
                        alerts
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    deleteNews(news_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете тази новина?')) {
            this.setState({loaded: false});
            const params = {news_id};
            const data = {auth: {func: this.NEWS_FUNC.delete}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.loadNews();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'danger', text: data['message']});

                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    addTag(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const keyword = this.state.formsData['tagNew'];
            const params = {keyword};
            const data = {auth: {func: this.KEYWORDS_FUNC.add}, params};

            post(this.API_KEYWORDS_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const formsData = {...this.state.formsData};
                        const alerts = [...this.state.alerts];
                        formsData['tagNew'] = null;
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts,
                            validated: false,
                            formsData
                        });

                        this.loadTags();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    addTagSecondary(keyword) {
        const params = {keyword};
        const data = {auth: {func: this.KEYWORDS_FUNC.add}, params};

        post(this.API_KEYWORDS_URL, data, true)
            .then(data => {
                if (data['response'] === true) {
                    this.loadTags();
                    const tagsTemp = [...this.state.tagsTemp];
                    const tagForChange = tagsTemp.filter(t => t['name'] === keyword);
                    const index = tagsTemp.indexOf(tagForChange[0]);
                    tagsTemp[index]['id'] = data['record_id'].toString();
                    this.setState({tagsTemp});
                } else {
                    const alerts = [...this.state.alerts];
                    alerts.push({type: 'danger', text: data['message']});

                    this.setState({
                        loaded: true,
                        alerts
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    editTag(keyword_id) {
        const {tags} = this.state;
        const thisTags = tags.filter(tag => tag['id'] === keyword_id);

        if (thisTags.length) {
            const params = {keyword_id, keyword: thisTags[0].name};
            const data = {auth: {func: this.KEYWORDS_FUNC.edit}, params};

            post(this.API_KEYWORDS_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts,
                            validated: false
                        });
                    } else {
                        this.setState({
                            loaded: true,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    deleteTag(keyword_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете този таг?')) {
            const params = {keyword_id};
            const data = {auth: {func: this.KEYWORDS_FUNC.delete}, params};

            post(this.API_KEYWORDS_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.loadTags();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    updateTags(keywords) {
        if (keywords.length) {
            const {newsEditID} = this.state;
            const params = {news_id: newsEditID, keywords};
            const data = {auth: {func: this.NEWS_FUNC.updateTags}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === false) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    drawNews() {
        const {news} = this.state;

        if (news.length) {
            return (
                <B.Row>
                    {news.map(news => {
                        const newsID = news['news_id'];
                        const newsDate = news['created_at'].split(' ')[0].split('-');
                        const date = new Date(newsDate[2], newsDate[1], newsDate[0]);

                        return (
                            <B.Col key={newsID} xs={12} md={6} lg={4} xl={3}>
                                <B.Card>
                                    <B.CardImg variant="top"
                                               src={news.media === '/storage/' ? '/images/default_news-admin.png' : news.media}
                                               alt={news.title}/>
                                    <B.Card.Body>
                                        <B.Card.Title>{news.title}</B.Card.Title>
                                        <B.Card.Subtitle
                                            className="text-muted">{`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}</B.Card.Subtitle>
                                    </B.Card.Body>
                                    <B.Card.Footer className="text-center">
                                        <span className="langMarker">{news.lang}</span>
                                        <a href={`/news/${newsID}`} className="btn btn-light mr-2" target="_blank">
                                            <FontAwesomeIcon icon="eye" fixedWidth/>
                                        </a>
                                        <B.Button type="button" variant="primary"
                                                  onClick={() => this.getNewsData(newsID)} className="mr-2">
                                            <FontAwesomeIcon icon="edit" fixedWidth/>
                                        </B.Button>
                                        <B.Button type="button" variant="danger"
                                                  onClick={() => this.deleteNews(newsID)}>
                                            <FontAwesomeIcon icon="trash-alt" fixedWidth/>
                                        </B.Button>
                                    </B.Card.Footer>
                                </B.Card>
                            </B.Col>
                        );
                    })}
                </B.Row>
            );
        }
    }

    drawModals() {
        const {modalShow, validated, formsData, newsEditID, tags, tagsTemp} = this.state;
        const {languages} = this.props;

        return (
            <>
                {/* News Modal */}
                <B.Modal
                    id='news'
                    centered
                    size="lg"
                    show={modalShow['news']}
                    onHide={() => this.modalHide('news', true)}
                    aria-labelledby="newsModalTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="newsModalTitle">
                            {newsEditID ? 'Редактирай' : 'Добави'} новина
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            noValidate
                            validated={validated}
                            onSubmit={e => this.addEditNews(e)}
                            method="POST"
                            encType="multipart/form-data">
                            <B.Form.Group controlId="newsMedia">
                                <label>Снимка</label>
                                {formsData['newsMediaThumb'] &&
                                <div className="w-25 mb-2"><img className="img-thumbnail"
                                                                src={formsData['newsMediaThumb']}
                                                                alt={formsData['newsTitle']}/></div>}
                                <div className="custom-file">
                                    <B.Form.Control type="file" name="newsMedia" className="custom-file-input"
                                                    onChange={(ev) => this.handleFileInputChange(ev, ['image/jpeg', 'image/png', 'image/gif'])}
                                                    accept="image/jpeg, image/png, image/gif"
                                                    lang="bg"/>
                                    <B.Form.Label className="custom-file-label">
                                        {formsData['newsMedia'] ?
                                            formsData['newsMedia'].length > 1 ?
                                                `${formsData['newsMedia'].length} снимки` :
                                                formsData['newsMedia']['name'] :
                                            <div>
                                                <span className="d-md-none">Снимки</span>
                                                <span className="d-none d-md-block">.jpeg, .jpg, .png, .gif</span>
                                            </div>}
                                    </B.Form.Label>
                                    <B.FormText className="text-muted d-md-none">.jpeg, .jpg, .png, .gif</B.FormText>
                                </div>
                            </B.Form.Group>
                            <B.Form.Group controlId="newsTitle">
                                <B.Form.Label>Заглавие</B.Form.Label>
                                <B.Form.Control type="text" name="newsTitle" value={formsData['newsTitle'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)} required/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId='newsLanguage'>
                                <B.Form.Label>Език</B.Form.Label>
                                <B.Form.Control as="select" name="newsLanguage" value={formsData['newsLanguage'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)}
                                                className="custom-select" required>
                                    <option value="">Избери език</option>
                                    {languages.map(({id, lang}) => (
                                        <option key={id} value={id}>{lang}</option>
                                    ))}
                                </B.Form.Control>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="newsText">
                                <B.Form.Label>Съдържание</B.Form.Label>
                                <TextEditor getContent={content => this.getEditorContent(content, 'newsText')}
                                            defaultValue={formsData['newsText'] || ''} required/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="newsTgs">
                                <B.Form.Label>Тагове</B.Form.Label>
                                {/* {tags.length ?
                                    <ReactTags tags={tagsTemp}
                                               suggestions={tags}
                                               labelField={'name'}
                                               name="sermonTags"
                                               placeholder="Въведи таг и натисни Enter или Space"
                                               handleDelete={i => this.handleDeleteTag(i)}
                                               handleAddition={tag => this.handleAddTag(tag)}
                                               delimiters={this.TAGS_DELIMITERS}
                                               allowDragDrop={false}
                                               inline={false}
                                               inputFieldPosition="bottom"
                                               classNames={{
                                                   tags: '',
                                                   tagInput: 'tag-input-wrapper',
                                                   tagInputField: 'form-control',
                                                   selected: 'tags-wrapper',
                                                   tag: 'tag p-2 mr-2 mb-2 border-1 bg-info text-white',
                                                   remove: 'remove-tag ml-1',
                                                   suggestions: 'suggestions',
                                                   activeSuggestion: 'suggestion-active'
                                               }}
                                    /> :
                                    <B.Alert variant="info">Няма въведени тагове</B.Alert>
                                } */}

                                <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                            </B.Form.Group>
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon={newsEditID ? 'edit' : 'plus'} fixedWidth className="mr-2"/>
                                    {newsEditID ? 'Редактирай' : 'Добави'} новина
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
                {/* Tags Modal */}
                <B.Modal id='tags'
                         centered
                         show={modalShow['tags']}
                         onHide={() => this.modalHide('tags', true)}
                         aria-labelledby="tagsTitle"
                         className="body-scroll"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="tagsTitle">Тагове</B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form onSubmit={ev => ev.preventDefault()}>
                            {tags.length === 0 ?
                                <B.Alert variant="info">Няма въведени тагове</B.Alert> : tags.map(({id, name}) => {
                                    return (
                                        <B.Form.Group key={id}>
                                            <B.Form.Row>
                                                <B.Col>
                                                    <B.Form.Control type="text" value={name || ''}
                                                                    onChange={ev => this.handleTagsChange(ev, id)}/>
                                                </B.Col>
                                                <B.Col>
                                                    <B.Button type="button" variant="primary" className="mr-2"
                                                              onClick={() => this.editTag(id)}>
                                                        <FontAwesomeIcon icon="edit" fixedWidth/>
                                                    </B.Button>
                                                    <B.Button type="button" variant="danger"
                                                              onClick={() => this.deleteTag(id)}>
                                                        <FontAwesomeIcon icon="trash-alt" fixedWidth/>
                                                    </B.Button>
                                                </B.Col>
                                            </B.Form.Row>
                                        </B.Form.Group>
                                    );
                                })}
                        </B.Form>
                    </B.Modal.Body>
                    <B.Modal.Footer>
                        <B.Form className="w-100"
                                onSubmit={ev => this.addTag(ev)}
                                noValidate
                                validated={validated}>
                            <B.Form.Group>
                                <B.Form.Row>
                                    <B.Col>
                                        <B.Form.Control type="text" name="tagNew"
                                                        value={formsData['tagNew'] || ''}
                                                        onChange={ev => this.handleInputChange(ev)} required/>
                                        <B.Form.Control.Feedback type="invalid">Полето е
                                            задължително!</B.Form.Control.Feedback>
                                    </B.Col>
                                    <B.Col>
                                        <B.Button type="submit" variant="primary">
                                            <FontAwesomeIcon icon="plus" fixedWidth className="mr-2"/>
                                            Добави таг
                                        </B.Button>
                                    </B.Col>
                                </B.Form.Row>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Footer>
                </B.Modal>
            </>
        );
    }

    render() {
        const {loaded, error, alerts} = this.state;

        if (error) {
            return (
                <NewsLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                            modalShow={(id) => this.modalOpen(id)}>
                    <B.Alert variant='danger'>
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                    {this.drawModals()}
                </NewsLayout>
            );
        } else if (!loaded) {
            return (
                <NewsLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                            modalShow={(id) => this.modalOpen(id)}>
                    <B.ProgressBar animated now={100} label="Loading..."/>
                    {this.drawModals()}
                </NewsLayout>
            );
        } else {
            return (
                <NewsLayout alerts={alerts}
                            handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                            modalShow={(id) => this.modalOpen(id)}>
                    {this.drawNews()}
                    {this.drawModals()}
                </NewsLayout>
            );
        }
    }
}

export default News;

const NewsLayout = props => {
    const {children, alerts, handleCloseClick, modalShow} = props;
    return (
        <>
            {alerts.length ?
                <Alerts alerts={alerts} handleCloseClick={(alert) => handleCloseClick(alert)}/>
                : ''}
            <div className="page-header mb-3">
                <h2 className="d-inline-block">Новини</h2>
                <div className="float-right d-inline-block">
                    <B.Button className="btn btn-light mr-3" onClick={() => modalShow('tags')}>
                        <FontAwesomeIcon icon='tags' className='mr-2 text-warning' fixedWidth/>
                        Тагове
                    </B.Button>
                    <B.Button className="btn btn-light" onClick={() => modalShow('news')}>
                        <FontAwesomeIcon icon='plus' className='mr-2 text-success' fixedWidth/>
                        Добави новина
                    </B.Button>
                </div>
            </div>
            {children}
        </>
    );
};
