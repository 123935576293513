import React, {Component} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as B from 'react-bootstrap';

export default class CreatePage extends Component {
    render() {
        return (
            <B.Form method="post">
                <B.Form.Group>
                    <B.Form.Label>Заглавие</B.Form.Label>
                    <B.Form.Control type="text" placeholder="Начало"/>
                </B.Form.Group>
                <B.Form.Group>
                    <B.Form.Label>Подзаглавие</B.Form.Label>
                    <B.Form.Control type="text" placeholder="Начало"/>
                </B.Form.Group>
                <B.Row>
                    <B.Col xs={12} md={6}>
                        <B.Form.Group>
                            <B.Form.Label>Служебно име</B.Form.Label>
                            <B.Form.Control type="text" placeholder="servants"/>
                        </B.Form.Group>
                    </B.Col>
                    <B.Col xs={12} md={6}>
                        <B.Form.Group>
                            <B.Form.Label>Шаблон</B.Form.Label>
                            <B.Form.Control as="select" name="component" className="custom-select">
                                <option value="null">Моля, изберете</option>
                                <option value="">За нас</option>
                                <option value="ministries">Служение</option>
                                <option value="page">Стандартна страница</option>
                            </B.Form.Control>
                        </B.Form.Group>
                    </B.Col>
                </B.Row>
                <B.Form.Group className="text-right mb-0">
                    <button type="submit" className="btn btn-primary">
                        <FontAwesomeIcon icon="plus" fixedWidth className="mr-2"/>
                        Създай страница
                    </button>
                </B.Form.Group>
            </B.Form>
        );
    }
}
