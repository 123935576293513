import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class LiveStream extends Component {
    constructor(props) {
        super(props);
        this.videoAddress = 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4';
    }

    conditionalRender() {
        const {isLive, onStartStream, onScheduleStream, onStopStream} = this.props;

        if (isLive) {
            return (
                <div className="row">
                    <div className="col-12 mb-3">
                        <video width="100%" height="100%" controls muted loop autoPlay>
                            <source src={this.videoAddress} type="video/mp4"/>
                        </video>
                    </div>
                    <div className="col-12">
                        <button type="button" className="btn btn-danger btn-block mb-1" onClick={onStopStream}>
                            <FontAwesomeIcon icon="stop-circle" fixedWidth className="mr-2"/>
                            Спри излъчването
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-12 mb-4">
                        <button type="button" className="btn btn-success btn-block mb-1" onClick={onStartStream}>
                            <FontAwesomeIcon icon="play-circle" fixedWidth className="mr-2"/>
                            Стартирай излъчване сега
                        </button>
                        <small className="text-muted"><em>Излъчването ще бъде със заглавие "ЕБЦ София - 1 - дд.мм.гггг".</em></small>
                    </div>
                    <div className="col-12">
                        <h5>Насрочване:</h5>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <label>Дата</label>
                        <input type="date" className="form-control" placeholder="дд.мм.гггг"/>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <label>Час</label>
                        <input type="text" className="form-control" placeholder="чч:мм"/>
                    </div>
                    <div className="col-12 mb-5">
                        <button type="button" className="btn btn-secondary btn-block" onClick={onScheduleStream}>
                            <FontAwesomeIcon icon="clock" fixedWidth className="mr-2"/>
                            Насрочи излъчване
                        </button>
                    </div>
                </div>
            );
        }
    }

    render() {
        return this.conditionalRender();
    }
}

export default LiveStream;
