import React, {Component} from 'react';
import {post} from "../API";
import * as B from 'react-bootstrap';
import {lang} from "../lang/all";
import { ReCaptcha } from 'react-recaptcha-v3'

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            validated: false,
            formsData: {
                contactTerms: 0
            }
        };
        this.API_URL = '/api/submit_ticket';
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};

        formsData[name] = value;

        this.setState({formsData});
    }

    sendMail(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {contactName, contactMail, contactMessage} = this.state.formsData;
            const params = {name: contactName, email: contactMail, message: contactMessage};
            const data = {params};

            post(this.API_URL, data)
                .then(data => {
                    if (data['response'] === true) {
                        const formsData = {contactTerms: 0};
                        const alert = {type: 'success', text: data['message']};

                        this.setState({
                            alert,
                            validated: false,
                            formsData
                        });
                    } else {
                        const alert = {type: 'danger', text: data['message']};

                        this.setState({
                            loaded: true,
                            alert
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!
        console.log(recaptchaToken, "<= your recaptcha token")
    }

    render() {
        const {validated, formsData, alert} = this.state;
        const {language, recaptcha} = this.props;

        return (
            <>
                {alert ?
                    <B.Alert variant={alert.type} className="mb-3" dismissible={alert.type === 'success'}>{alert.text}</B.Alert> : ''}
                <B.Form
                    noValidate
                    validated={validated}
                    onSubmit={e => this.sendMail(e)}
                    method="POST">
                    <B.Form.Group controlId="contactName">
                        <B.Form.Label>{lang[language].contactFormNameLabel}</B.Form.Label>
                        <B.Form.Control type="text" name="contactName"
                                        value={formsData['contactName'] || ''}
                                        onChange={ev => this.handleInputChange(ev)}
                                        placeholder={lang[language].contactFormNamePlaceholder} required/>
                        <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                    </B.Form.Group>
                    <B.Form.Group controlId="contactMail">
                        <B.Form.Label>Email</B.Form.Label>
                        <B.Form.Control type="email" name="contactMail"
                                        value={formsData['contactMail'] || ''}
                                        onChange={ev => this.handleInputChange(ev)}
                                        placeholder="your@mail.com" required/>
                        <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                    </B.Form.Group>
                    <B.Form.Group controlId="contactMessage">
                        <B.Form.Label>{lang[language].contactFormMessageLabel}</B.Form.Label>
                        <B.Form.Control as="textarea" name="contactMessage"
                                        value={formsData['contactMessage'] || ''}
                                        onChange={ev => this.handleInputChange(ev)} rows="2" required/>
                        <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                    </B.Form.Group>
                    <B.Form.Group>
                        <B.Form.Check custom type='checkbox' id='contactTerms' name="contactTerms"
                                      checked={formsData['contactTerms']}
                                      onChange={ev => this.handleInputChange(ev)}
                                      label={lang[language].contactFormPrivacy} required/>
                        <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                    </B.Form.Group>
                    <B.Button variant="primary" type="submit">{lang[language].contactFormBtn}</B.Button>
                    <ReCaptcha
                        sitekey={recaptcha}
                        action='homepage'
                        verifyCallback={token => this.verifyCallback(token)}
                    />
                </B.Form>
            </>
        );
    }
}

export default ContactForm;
