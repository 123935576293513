import React, {Component} from 'react';
import {Link} from "react-router-dom";
import * as B from 'react-bootstrap';

//Import Components
import Carousel from "./Carousel";
import LiveStream from "./LiveStream";
import IndexPreachings from "./IndexPreachings";
import IndexNews from "./IndexNews";
import IndexSermons from "./IndexSermons";
import IndexMinistries from "./IndexMinistries";
import {get} from "../API";
import {lang} from "../lang/all";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: null,
            loaded: false,
            error: null,
            error404: false,
            pages: [],
            sliders: [],
            slides: []
        };
        this.language = props.language;
        this.API_URL = '/api/page';
        this.SLIDER_API_URL = '/api/slider';
    }

    componentWillMount() {
        const {isLoaded} = this.props;
        isLoaded(false);
        this.loadSliders();
        document.title = lang[this.language].siteTitle;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.language = this.props.language;
            this.checkPage();
            this.loadSliders();
        }
    }

    checkPage() {
        const {pages, sliders} = this.state;
        const {isLoaded} = this.props;

        if (pages.length) {
            const page = pages.filter(page => page.addr.trim() === '/' && page.page === `${this.language === 1 ? 'Начало' : 'Home'}`);

            if (page && page.length > 0) {
                const pageID = page[0].id;
                if (sliders.length) {
                    const hasSlide = sliders.indexOf(pageID) !== -1;
                    if (hasSlide) {
                        this.getSliderData(pageID);
                    } else {
                        this.setState({slides: []});
                        isLoaded(true);
                    }
                }
            } else {
                this.setState({
                    loaded: true,
                    error404: true,
                });
            }
        }
    }

    loadPages() {
        this.setState({loaded: false, error: null, error404: false});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    if (data) {
                        const pages = data.filter(page => page.status !== 0 && page.lang_id === this.language);
                        const ministries = pages.filter(page => page.addr.indexOf('ministries') !== -1);

                        this.setState({
                            pages,
                            ministries
                        });
                        this.checkPage();
                    }
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadSliders() {
        get(this.SLIDER_API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    this.loadPages();
                    const sliders = [];
                    data.map(slider => {
                        sliders.push(slider.page);
                    });
                    this.setState({sliders});
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message'],
                        sliders: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    getSliderData(pageID) {
        get(`${this.SLIDER_API_URL}/${pageID}`)
            .then(data => {
                if (data['response'] !== false) {
                    const {isLoaded} = this.props;

                    if (data['data']) {
                        const slides = [];

                        if (data['data_content'] && data['data_content'].hasOwnProperty(pageID) && data['data_content'][pageID]['response'] !== false) {
                            data['data_content'][pageID].map(slide => {
                                slides.push(slide);
                            });
                        }
                        this.setState({slides});
                        isLoaded(true);
                    }
                } else {
                    this.setState({
                        loaded: false,
                        error: data['message'],
                        slides: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    render() {
        const {slides, ministries} = this.state;
        const {isLive} = this.props;

        return (
            <main id="app" role="main">
                <section id="indexCarousel">
                    <B.Row noGutters>
                        <B.Col>
                            {(slides && slides.length) ? <Carousel slides={slides}/> : ''}
                            {/*{isLive ? <LiveStream/> : (slides && slides.length) ? <Carousel slides={slides}/> : ''}*/}
                        </B.Col>
                    </B.Row>
                </section>
                <div id="homeBoxes" className="index-box-wrapper pb-1">
                    <B.Row noGutters>
                        <B.Col className="index-box bg-light">
                            <B.Container as='section'>
                                <h2 className="index-box-title mb-3">{lang[this.language].indexServicesTitle}</h2>
                                <div className="index-box-content">
                                    <IndexPreachings language={this.language}/>
                                </div>
                            </B.Container>
                        </B.Col>
                    </B.Row>
                    <B.Row noGutters>
                        <B.Col className="index-box">
                            <B.Container as='section'>
                                <h2 className="index-box-title mb-0"><Link to='/media/sermons'>{lang[this.language].indexSermonsTitle}</Link></h2>
                                <h6 className="index-box-subtitle mb-2">
                                    <small>{lang[this.language].indexSermonsSubTitle}</small>
                                </h6>
                                <div className="index-box-content">
                                    <IndexSermons/>
                                </div>
                            </B.Container>
                        </B.Col>
                    </B.Row>
                    <B.Row noGutters>
                        <B.Col className="index-box bg-light">
                            <B.Container as='section'>
                                <h2 className="index-box-title mb-3"><Link to="/news">{lang[this.language].indexNewsTitle}</Link></h2>
                                <div className="index-box-content">
                                    <IndexNews language={this.language}/>
                                </div>
                            </B.Container>
                        </B.Col>
                    </B.Row>
                    <B.Row noGutters>
                        <B.Col className="index-box">
                            <B.Container as='section'>
                                <h2 className="index-box-title mb-3">{lang[this.language].indexMinistriesTitle}</h2>
                                <div className="index-box-content">
                                    <IndexMinistries pages={ministries} language={this.language}/>
                                </div>
                            </B.Container>
                        </B.Col>
                    </B.Row>
                </div>
            </main>
        );
    }
}

export default Index;
