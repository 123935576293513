import React, {Component} from 'react';
import Alerts from "./Alerts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as B from 'react-bootstrap';
import {get, post} from "../../API";

class LiveStream extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            alerts: [],
            modalShow: {
                'credentials': false,
                'schedule': false
            },
            validated: false,
            formsData: {},
            streams: [],
            streamEditID: null,
            copySuccess: false,
            loadingStream: false,
            pageID: null
        };
        this.API_URL = '/api/fb';
        this.STREAM_FUNC = {
            'editCredentials': 1,
            'scheduleStream': 2,
            'editStream': 3,
            'removeStream': 4,
            'loadCredentials': 5,
            'startStream': 6,
            'stopStream': 7
        };
        this.fbGraphAddress = 'https://graph.facebook.com';
        this.attachRef = target => this.setState({target});
    }

    componentWillMount() {
        this.loadStreams();
        this.loadCredentials();
    }

    modalOpen(id) {
        const {modalShow} = this.state;
        modalShow[id] = true;
        this.setState({modalShow});
    }

    modalHide(id, reset) {
        const {modalShow} = this.state;
        modalShow[id] = false;

        if (reset) {
            const formsData = {...this.state.formsData};
            formsData['streamDate'] = null;
            formsData['streamTime'] = null;
            formsData['streamTitle'] = null;
            formsData['streamDescription'] = null;
            this.setState({modalShow, validated: false, formsData});
            return;
        }
        this.setState({modalShow});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};

        formsData[name] = value;

        this.setState({formsData});
    }

    handleCloseAlert(alert) {
        const currentAlerts = [...this.state.alerts];
        const alerts = currentAlerts.filter(al => al !== alert);
        this.setState({alerts});
    }

    copyToClipboard(e) {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({copySuccess: true});

        setTimeout(() => {
            this.setState({copySuccess: false})
        }, 5000);
    }

    loadStreams() {
        this.setState({loaded: false, error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    this.setState({
                        loaded: true,
                        streams: data
                    });
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message'],
                        streams: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadCredentials() {
        const params = {};
        const data = {auth: {func: this.STREAM_FUNC.loadCredentials}, params};

        post(this.API_URL, data, true)
            .then(data => {
                if (data['response'] !== false) {
                    const formsData = {...this.state.formsData};
                    formsData['appID'] = data['app_id'];
                    formsData['pageID'] = data['page_id'];

                    this.setState({formsData, pageID: data['page_id']});
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    getStreamData(id) {
        this.setState({streamEditID: id});

        get(`${this.API_URL}/${id}`)
            .then(data => {
                if (data['response'] !== false) {
                    const formsData = {...this.state.formsData};
                    const streamFullDate = data['begins_at'].split(' ');
                    const streamDate = streamFullDate[0].split('-');
                    const streamTime = streamFullDate[1].split(':');
                    const date = new Date(streamDate[2], streamDate[1], streamDate[0], streamTime[0], streamTime[1], streamTime[2]);

                    formsData['streamDate'] = `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
                    formsData['streamTime'] = `${date.getHours()}:${date.getMinutes()}`;
                    formsData['streamTitle'] = data['name'];
                    formsData['streamDescription'] = data['description'];

                    this.setState({formsData});

                    this.modalOpen('schedule');
                } else {
                    const alerts = [...this.state.alerts];
                    alerts.push({type: 'danger', text: data['message']});

                    this.setState({
                        loaded: true,
                        alerts,
                        streams: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    editCredentials(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {appID, pageID} = this.state.formsData;
            const params = {app_id: appID, page_id: pageID};
            const data = {auth: {func: this.STREAM_FUNC.editCredentials}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide('credentials');

                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});

                        this.setState({
                            alerts,
                            validated: false,
                        });

                        this.loadCredentials();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'danger', text: data['message']});

                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    addEditStream(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {streamEditID} = this.state;
            const {streamDate, streamTime, streamTitle, streamDescription} = this.state.formsData;
            const userDate = streamDate.split('-');
            const userTime = streamTime.split(':');
            const date = new Date(userDate[0], userDate[1], userDate[2], userTime[0], userTime[1]);
            const begins_at = `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}-${date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()}-${date.getFullYear()} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}:00`;
            const params = {begins_at, title: streamTitle, description: streamDescription, status: 1};
            const func = streamEditID ? this.STREAM_FUNC.editStream : this.STREAM_FUNC.scheduleStream;
            const data = {auth: {func}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide('schedule', true);

                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});

                        this.setState({
                            alerts,
                            validated: false,
                        });

                        this.loadStreams();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'danger', text: data['message']});

                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    deleteStream(stream_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете това предаване?')) {
            this.setState({loaded: false});
            const params = {stream_id};
            const data = {auth: {func: this.STREAM_FUNC.removeStream}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.loadStreams();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'danger', text: data['message']});

                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

//TODO: Remove this 3
    startStream(stream_id = 3) {
        this.setState({loadingStream: true});

        const {fbAccessToken} = this.props;

        get(`${this.fbGraphAddress}/me/accounts?access_token=${fbAccessToken}`)
            .then(response => {
                if (response.hasOwnProperty('data') && response['data'].length) {
                    const page = response['data'][0];
                    const a_token = page['access_token'];
                    const params = {a_token, stream_id};
                    const options = {auth: {func: this.STREAM_FUNC.startStream}, params};

                    post(this.API_URL, options, true)
                        .then(data => {
                            if (data['response'] === true) {
                                const alerts = [...this.state.alerts];
                                alerts.push({type: 'success', text: data['message']});
                                this.setState({
                                    alerts,
                                    loadingStream: false
                                });
                                this.loadStreams();
                            } else {
                                const alerts = [...this.state.alerts];
                                alerts.push({type: 'danger', text: data['message']});

                                this.setState({
                                    alerts,
                                    loadingStream: false
                                });
                            }
                        })
                        .catch(error => console.error('Start stream error:', error));
                } else {
                    const alerts = [...this.state.alerts];
                    alerts.push({type: 'danger', text: `Page Access Token Error: ${data['message']}`});

                    this.setState({
                        alerts,
                        loadingStream: false
                    });
                }
            })
            .catch(error => console.error('FB error:', error));
    }

    stopStream(stream_id) {
        const {fbAccessToken} = this.props;

        get(`${this.fbGraphAddress}/me/accounts?access_token=${fbAccessToken}`)
            .then(response => {
                if (response.hasOwnProperty('data') && response['data'].length) {
                    const page = response['data'][0];
                    const a_token = page['access_token'];
                    const params = {a_token, stream_id};
                    const data = {auth: {func: this.STREAM_FUNC.stopStream}, params};

                    post(this.API_URL, data, true)
                        .then(data => {
                            if (data['response'] === true) {
                                const alerts = [...this.state.alerts];
                                alerts.push({type: 'success', text: data['message']});
                                this.setState({
                                    alerts
                                });
                                this.loadStreams();
                            } else {
                                const alerts = [...this.state.alerts];
                                alerts.push({type: 'danger', text: data['message']});

                                this.setState({
                                    loaded: true,
                                    alerts
                                });
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            this.setState({
                                loaded: false,
                                error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                            });
                        });
                } else {
                    const alerts = [...this.state.alerts];
                    alerts.push({type: 'danger', text: `Page Access Token Error: ${data['message']}`});

                    this.setState({alerts});
                }
            })
            .catch(error => console.error('FB error:', error));
    }

    drawStreams() {
        const {fbAccessToken} = this.props;
        const {streams, copySuccess, target, loadingStream} = this.state;
        //TODO: CHANGE DISABLED ATTR VALUE ON THE DELETE BUTTON TO stream.status!
        if (streams.length) {
            return (
                <B.Row>
                    <B.Col xs={12} md={10} lg={8}>
                        <B.ListGroup className="admin-streams">
                            {streams.map(stream => {
                                const streamDateTime = stream['begins_at'].split(' ');
                                const streamDate = streamDateTime[0].split('-');
                                const streamTime = streamDateTime[1].split(':');
                                const date = new Date(streamDate[2], streamDate[1], streamDate[0], streamTime[0], streamTime[1], streamTime[2]);
                                const dateString = `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
                                const timeString = `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
                                const streamUrlParts = stream['stream_url'] ? stream['stream_url'].split('/rtmp/') : '';
                                const streamUrl = streamUrlParts.length ? streamUrlParts[1] : null;
                                return (
                                    <B.ListGroupItem key={stream['stream_id']}
                                                     className={stream.status ? 'active-stream' : ''}>

                                        <div className='d-flex align-items-center justify-content-between flex-wrap'>
                                            <span>
                                                   {stream.status ?
                                                       <div className="blinker-wrapper">
                                                           <FontAwesomeIcon icon="dot-circle" fixedWidth
                                                                            className="text-danger blinker mr-2"/>
                                                       </div>
                                                       : ''}
                                                Насрочено за {dateString} от {timeString} ч.
                                            </span>
                                            <div>
                                                {stream.status ?
                                                    <B.Button type="button" variant="danger" className="mr-2"
                                                              onClick={() => this.stopStream(stream['stream_id'])}
                                                              title="Спри сега">
                                                        <FontAwesomeIcon icon="stop-circle" size="lg" fixedWidth/>
                                                    </B.Button> :
                                                    <B.Button type="button" variant="success" className="mr-2"
                                                              onClick={() => this.startStream(stream['stream_id'])}
                                                              title="Стартирай сега"
                                                              disabled={!fbAccessToken || loadingStream}>
                                                        {loadingStream ?
                                                            <FontAwesomeIcon icon="spinner" spin fixedWidth/>
                                                            :
                                                            <FontAwesomeIcon icon='play-circle' fixedWidth/>
                                                        }
                                                    </B.Button>
                                                }
                                                <B.Button type="button" variant="light" disabled={stream.status}
                                                          onClick={() => this.getStreamData(stream['stream_id'])}>
                                                    <FontAwesomeIcon icon="edit" className="text-primary" fixedWidth/>
                                                </B.Button>
                                                <B.Button type="button" variant="light" className="ml-2"
                                                          disabled={true}
                                                          onClick={() => this.deleteStream(stream['stream_id'])}>
                                                    <FontAwesomeIcon icon="trash-alt" className="text-danger"
                                                                     fixedWidth/>
                                                </B.Button>
                                            </div>
                                        </div>
                                        {streamUrl && stream.status ?
                                            document.queryCommandSupported('copy') &&
                                            <div className="mt-3">
                                                <B.FormLabel>Secret Key:</B.FormLabel>
                                                <B.Row noGutters>
                                                    <B.Col xs={8} className="mr-2">
                                                        <B.Form.Control type="text"
                                                                        ref={(textarea) => this.textArea = textarea}
                                                                        value={streamUrl} readOnly/>
                                                    </B.Col>

                                                    <B.Col xs={3}>
                                                        <B.Button type="button" variant="light" ref={this.attachRef}
                                                                  onClick={ev => this.copyToClipboard(ev)}
                                                                  title="Копирай адреса за стрийм">
                                                            <FontAwesomeIcon icon="copy" fixedWidth className="mr-2"/>
                                                            Копирай
                                                        </B.Button>
                                                        <B.Overlay target={target} show={copySuccess} placement="auto">
                                                            {props => <B.Tooltip {...props}>Копиран!</B.Tooltip>}
                                                        </B.Overlay>
                                                    </B.Col>
                                                </B.Row>
                                            </div>
                                            : ''}
                                        {stream['stream_live_id'] && stream.status ?
                                            <div className="mt-3"><b>Stream ID:</b> {stream['stream_live_id']}</div>
                                            : ''}
                                    </B.ListGroupItem>
                                );
                            })}
                        </B.ListGroup>
                    </B.Col>
                </B.Row>
            );
        }
    }

    drawModals() {
        const {modalShow, validated, formsData, streamEditID} = this.state;

        return (
            <>
                {/* Credentials Modal */}
                <B.Modal
                    id='credentials'
                    centered
                    show={modalShow['credentials']}
                    onHide={() => this.modalHide('credentials')}
                    aria-labelledby="credentialsTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="credentialsTitle">
                            Credentials
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            noValidate
                            validated={validated}
                            onSubmit={e => this.editCredentials(e)}>
                            <B.Form.Group controlId="appID">
                                <B.Form.Label>App ID</B.Form.Label>
                                <B.Form.Control type="text" name="appID" value={formsData['appID'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)} required/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="pageID">
                                <B.Form.Label>Page ID</B.Form.Label>
                                <B.Form.Control type="text" name="pageID" value={formsData['pageID'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)} required/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon='save' fixedWidth className="mr-2"/>
                                    Запази промените
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
                {/* Schedule/Edit stream Modal */}
                <B.Modal
                    id='schedule'
                    centered
                    show={modalShow['schedule']}
                    onHide={() => this.modalHide('schedule')}
                    aria-labelledby="scheduleTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="scheduleTitle">
                            {streamEditID ? 'Редактирай' : 'Насрочи'} живо предаване
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            noValidate
                            validated={validated}
                            onSubmit={e => this.addEditStream(e)}>
                            <B.Form.Group controlId="streamDate">
                                <B.Form.Label>Дата на предаването</B.Form.Label>
                                <B.Form.Control type="date" name="streamDate" value={formsData['streamDate'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)} required/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="streamTime">
                                <B.Form.Label>Час на предаването</B.Form.Label>
                                <B.Form.Control type="text" name="streamTime" value={formsData['streamTime'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)} placeholder="8:30"
                                                required/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="streamTitle">
                                <B.Form.Label>Заглавие</B.Form.Label>
                                <B.Form.Control type="text" name="streamTitle" value={formsData['streamTitle'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)}/>
                                <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                            </B.Form.Group>
                            <B.Form.Group controlId="streamDescription">
                                <B.Form.Label>Описание</B.Form.Label>
                                <B.Form.Control as="textarea" name="streamDescription"
                                                value={formsData['streamDescription'] || ''} rows="2"
                                                onChange={(ev) => this.handleInputChange(ev)}/>
                                <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                            </B.Form.Group>
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon={streamEditID ? 'edit' : 'clock'} fixedWidth
                                                     className="mr-2"/>
                                    {streamEditID ? 'Редактирай' : 'Насрочи'} живо предаване
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
            </>
        );
    }

    render() {
        const {onFBClick, fbLogin, fbLoginName, fbPageName} = this.props;
        const {loaded, error, alerts, loadingStream, pageID} = this.state;

        if (error) {
            return (
                <LiveStreamLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                                  fbLogin={fbLogin} loading={loadingStream}
                                  fbLoginName={fbLoginName}
                                  fbPageName={fbPageName}
                                  onStartStream={() => this.startStream()}
                                  modalShow={(id) => this.modalOpen(id)}
                                  onFBClick={scope => onFBClick(scope, pageID)}>
                    <B.Alert variant='danger'>
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                    {this.drawModals()}
                </LiveStreamLayout>
            );
        } else if (!loaded) {
            return (
                <LiveStreamLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                                  fbLogin={fbLogin} loading={loadingStream}
                                  fbLoginName={fbLoginName}
                                  fbPageName={fbPageName}
                                  onStartStream={() => this.startStream()}
                                  modalShow={(id) => this.modalOpen(id)}
                                  onFBClick={scope => onFBClick(scope, pageID)}>
                    <B.ProgressBar animated now={100} label="Loading..."/>
                    {this.drawModals()}
                </LiveStreamLayout>
            );
        } else {
            return (
                <LiveStreamLayout alerts={alerts} loading={loadingStream}
                                  handleCloseClick={(alert) => this.handleCloseAlert(alert)} fbLogin={fbLogin}
                                  fbLoginName={fbLoginName} fbPageName={fbPageName} onStartStream={() => this.startStream()}
                                  modalShow={(id) => this.modalOpen(id)}
                                  onFBClick={scope => onFBClick(scope, pageID)}>
                    <h4>Насрочени предавания:</h4>
                    {this.drawStreams()}
                    {this.drawModals()}
                </LiveStreamLayout>
            );
        }
    }
}

export default LiveStream;

const LiveStreamLayout = props => {
    const {children, alerts, handleCloseClick, modalShow, onFBClick, fbLogin, fbLoginName, fbPageName, onStartStream, loading} = props;
    const isLoggedIn = fbLogin === 'connected';

    return (
        <>
            {alerts.length ?
                <Alerts alerts={alerts} handleCloseClick={(alert) => handleCloseClick(alert)}/>
                : ''}
            <div className="page-header mb-3 clearfix">
                <h2 className="d-inline-block">Live stream</h2>

                <div className="d-inline-block float-right">
                    <B.Button type="button" className="fb-button mb-2"
                              onClick={() => onFBClick('public_profile, manage_pages, publish_pages, pages_show_list')}>
                        <FontAwesomeIcon icon={['fab', 'facebook-square']} className="text-white" size="lg" fixedWidth/>
                        <span className="d-none d-md-inline-block ml-2">{fbLogin === 'connected' ? `Logged in as ${fbLoginName} ${fbPageName ? `at ${fbPageName}` : ''}` : 'Login with Facebook'}</span>
                    </B.Button>
                    <B.Button type="button" variant="light" className="ml-2 mb-2" onClick={() => modalShow('credentials')}>
                        <FontAwesomeIcon icon='lock' fixedWidth/>
                        <span className="d-none d-md-inline-block ml-2">Credentials</span>
                    </B.Button>
                    <B.Button type="button" variant="light" className="ml-2 mb-2" onClick={() => modalShow('schedule')}>
                        <FontAwesomeIcon icon={['far', 'clock']} className='text-success' fixedWidth/>
                        <span className="d-none d-md-inline-block ml-2">Насрочи предаване</span>
                    </B.Button>
                    <B.Button type="button" variant="success" className="ml-2 mb-2" onClick={() => onStartStream()}
                              disabled={!isLoggedIn || loading}>
                        {loading ?
                            <>
                                <FontAwesomeIcon icon="spinner" spin fixedWidth className="mr-2"/>
                                <span className="d-none d-md-inline-block ml-2">Loading...</span>
                            </> :
                            <>
                                <FontAwesomeIcon icon='play-circle' fixedWidth/>
                                <span className="d-none d-md-inline-block ml-2">Пусни предаване</span>
                            </>
                        }
                    </B.Button>
                </div>
            </div>
            {isLoggedIn ? '' : <B.Alert variant="warning">Login with Facebook first!</B.Alert>}
            <div className="clearfix"/>
            {children}
        </>
    );
};
