const bg = {
    siteTitle: 'Първа баптистка църква - София',
    brand: 'ПБЦ - София',
    searchLabel: 'Въведете търсената дума и натиснете Enter',
    searchPlaceholder: 'Търси...',
    indexServicesTitle: 'Богослужения',
    indexSermonsTitle: 'Проповеди',
    indexSermonsSubTitle: 'Последно качени видеа',
    indexNewsTitle: 'Новини',
    indexMinistriesTitle: 'Служения',
    contactTitle: 'Контакти',
    contactAddressTitle: 'Адрес',
    contactAddress: 'ул. "Осогово" 86, София 1303',
    contactPhoneTitle: 'Телефон',
    contactPhone: '02 931 06 82',
    contactWriteUsTitle: 'Пишете ни',
    contactWriteUsSubTitle: 'Пастирски екип:',
    contactFormNameLabel: 'Вашите имена',
    contactFormNamePlaceholder: 'Име Фамилия',
    contactFormMessageLabel: 'Съобщение',
    contactFormPrivacy: 'Съгласен съм с Политиката за поверителност',
    contactFormBtn: 'Изпрати',
    pastorTeddy: 'Теодор Опренов',
    pastorDidi: 'Димитрина Опренова',
    pastorVlady: 'Владимир Райчинов',
    followUs: 'Последвайте ни:',
    pages: 'Страници',
    images: 'Снимки',
    news: 'Новини',
    resources: 'Ресурси',
    sermons: 'Проповеди',
    gallery: 'Галерия',
    filters: 'Филтри',
    sortBy: 'Подреди по',
    filterBy: 'Филтрирай по:',
    book: 'Книга',
    books: 'Книги',
    speaker: 'Говорител',
    speakers: 'Говорители',
    singleSeries: 'Серия',
    series: 'Серии',
    share: 'Сподели:',
    shareWith: 'Сподели с',
    copyAddress: 'Копирай адреса',
    date: 'Дата',
    bookRef: 'Пасаж',
    attachment: 'Прикачен файл',
    download: 'Свали',
    addedOn: 'Добавен на',
    emptyAlbum: 'Албумът е празен',
    live: 'На живо',
    liveSubtitleSunday: 'неделя от 10:30 ч.',
    liveSubtitleWednesday: 'сряда от 18:30 ч.',
    countSeparator: 'от',
    timePreposition: 'от',
    year: 'Година',
    day: 'ден',
    days: 'дни',
    hour: 'час',
    hours: 'часа',
    minute: 'минута',
    minutes: 'минути',
    seconds: 'секунди',
    preachingsTitle: 'Нашите събрания:',
    copied: 'Копиран!',
    back: 'Назад',
    next: 'Напред',
    fbTitleAuthorise: 'Трябва да разрешите на фейсбук профила си достъп до сайта ни',
    fbTitleLogin: 'Трябва да влезете във фейсбук, за да можете да гледате на живо',
    tags: 'Ключови думи'
};

export {bg};
