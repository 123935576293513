import React, {Component} from 'react';
import Alerts from "./Alerts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as B from 'react-bootstrap';
import {get, post, postFile} from "../../API";

export default class Files extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            alerts: [],
            modalShow: false,
            validated: false,
            formsData: {},
            files: [],
            copySuccess: false
        };
        this.API_URL = '/api/storage';
        this.FILE_FUNC = {
            'add': 1,
            'delete': 2
        };
        this.MEDIA_TYPES_ICON = {
            'image': 'file-image',
            'image/jpeg': 'file-image',
            'image/png': 'file-image',
            'image/gif': 'file-image',
            'audio': 'file-audio',
            'audio/mpeg': 'file-audio',
            'audio/ogg': 'file-audio',
            'audio/wav': 'file-audio',
            'video': 'file-video',
            'video/mp4': 'file-video',
            'video/ogg': 'file-video',
            'application/pdf': 'file-pdf',
            'application/msword': 'file-word',
            'application/vnd.ms-word': 'file-word',
            'application/vnd.oasis.opendocument.text': 'file-word',
            'application/vnd.openxmlformats-officedocument.wordprocessingml': 'file-word',
            'application/vnd.ms-excel': 'file-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml': 'file-excel',
            'application/vnd.oasis.opendocument.spreadsheet': 'file-excel',
            'application/vnd.ms-powerpoint': 'file-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml': 'ffile-powerpoint',
            'application/vnd.oasis.opendocument.presentation': 'file-powerpoint',
            'text/plain': 'file-alt',
            'text/html': 'file-code',
            'application/json': 'file-code',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'file-word',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-excel',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'file-powerpoint',
            'application/gzip': 'file-archive',
            'application/zip': 'file-archive',
            'application/x-zip-compressed': 'file-archive',
            'application/octet-stream': 'file-archive',
        };
        this.attachRef = target => this.setState({target});
    }

    componentWillMount() {
        this.loadFiles();
    }

    modalOpen() {
        this.setState({modalShow: true});
    }

    modalHide(reset) {
        if (reset) {
            const formsData = {...this.state.formsData};
            formsData['file'] = null;
            formsData['fileName'] = null;
            this.setState({modalShow: false, validated: false, formsData});
            return;
        }
        this.setState({modalShow: false});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};

        formsData[name] = value;

        this.setState({formsData});
    }

    handleFileInputChange(event) {
        const target = event.target;
        const value = target.files[0];
        const name = target.name;
        const type = value.type;

        const formsData = {...this.state.formsData};
        formsData[name] = value;
        formsData['mediaType'] = type;

        this.setState({formsData});
    }

    handleCloseAlert(alert) {
        const currentAlerts = [...this.state.alerts];
        const alerts = currentAlerts.filter(al => al !== alert);
        this.setState({alerts});
    }

    copyToClipboard(e) {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({copySuccess: true});

        setTimeout(() => {
            this.setState({copySuccess: false})
        }, 5000);
    }

    loadFiles() {
        this.setState({loaded: false, error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    this.setState({
                        loaded: true,
                        files: data
                    });
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message'],
                        files: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    addFile(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {file, fileName, mediaType} = this.state.formsData;
            const func = this.FILE_FUNC.add;
            const params = {name: fileName, media_type: mediaType};
            const formData = new FormData();
            formData.append('auth', JSON.stringify({func}));
            formData.append('params', JSON.stringify(params));
            formData.append('media', file);

            postFile(this.API_URL, formData, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide(true);

                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts,
                            validated: false
                        });

                        this.loadFiles();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'danger', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    deleteFile(storage_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете този файл?')) {
            this.setState({loaded: false});
            const params = {storage_id};
            const data = {auth: {func: this.FILE_FUNC.delete}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.loadFiles();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'danger', text: data['message']});

                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    drawModals() {
        const {modalShow, validated, formsData} = this.state;

        return (
            <B.Modal
                id='files'
                centered
                show={modalShow}
                onHide={() => this.modalHide(true)}
                aria-labelledby="filesTitle"
            >
                <B.Modal.Header closeButton>
                    <B.Modal.Title id="filesTitle">
                        Добави файл
                    </B.Modal.Title>
                </B.Modal.Header>
                <B.Modal.Body>
                    <B.Form
                        noValidate
                        validated={validated}
                        onSubmit={e => this.addFile(e)}
                        method="POST"
                        encType="multipart/form-data">
                        <B.Form.Group controlId="file">
                            <label>Медия</label>
                            <div className="custom-file">
                                <B.Form.Control type="file" name="file" className="custom-file-input"
                                                onChange={(ev) => this.handleFileInputChange(ev)}
                                                lang="bg"
                                                required/>
                                <B.Form.Label className="custom-file-label">
                                    {formsData['file'] ?
                                        formsData['file']['name'] :
                                        <div>Файл</div>
                                    }
                                </B.Form.Label>
                            </div>
                            <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                        </B.Form.Group>
                        <B.Form.Group controlId="fileName">
                            <B.Form.Label>Име</B.Form.Label>
                            <B.Form.Control type="text" name="fileName" value={formsData['fileName'] || ''}
                                            onChange={(ev) => this.handleInputChange(ev)} required/>
                            <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                        </B.Form.Group>
                        <B.Form.Group className="text-center">
                            <B.Button type="submit" variant="primary">
                                <FontAwesomeIcon icon='plus' fixedWidth className="mr-2"/>
                                Добави файл
                            </B.Button>
                        </B.Form.Group>
                    </B.Form>
                </B.Modal.Body>
            </B.Modal>
        );
    }

    drawFiles() {
        const {files, copySuccess, target} = this.state;

        if (files.length) {
            return (
                <B.Row>
                    {files.map(file => {
                        const fileID = file['storage_id'];
                        const fileDate = file['created_at'].split(' ')[0].split('-');
                        const date = new Date(fileDate[2], fileDate[1], fileDate[0]);
                        return (<B.Col key={fileID} xs={12} md={6} lg={4} xl={3} className="mb-3">
                            <B.Card>
                                <B.Card.Body>
                                    <div className="text-center text-muted" title={file['media_type']}>
                                        <FontAwesomeIcon icon={this.MEDIA_TYPES_ICON[file['media_type']]} size="5x"/>
                                    </div>
                                    <B.Card.Title>{file.name}</B.Card.Title>
                                    <B.Card.Subtitle className="text-muted">{`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}</B.Card.Subtitle>
                                    {
                                        document.queryCommandSupported('copy') &&
                                        <B.Row noGutters className="mt-3">
                                            <B.Col xs={9} className="mr-2">
                                                <B.Form.Control type="text" ref={(textarea) => this.textArea = textarea} value={file.media} readOnly/>
                                            </B.Col>

                                            <B.Col xs={2}>
                                                <B.Button type="button" variant="light" ref={this.attachRef}
                                                          onClick={ev => this.copyToClipboard(ev)}
                                                          title="Копирай адреса на файла">
                                                    <FontAwesomeIcon icon="copy" fixedWidth/>
                                                </B.Button>
                                                <B.Overlay target={target} show={copySuccess} placement="auto">
                                                    {props => (
                                                        <B.Tooltip {...props}>Копиран!</B.Tooltip>
                                                    )}
                                                </B.Overlay>
                                            </B.Col>
                                        </B.Row>
                                    }
                                </B.Card.Body>
                                <B.Card.Footer className="text-center">
                                    <B.Button type="button" variant="danger" onClick={() => this.deleteFile(fileID)}>
                                        <FontAwesomeIcon icon="trash-alt" fixedWidth/>
                                    </B.Button>
                                </B.Card.Footer>
                            </B.Card>
                        </B.Col>);
                    })}
                </B.Row>
            );
        }
    }

    render() {
        const {loaded, error, alerts} = this.state;

        if (error) {
            return (
                <FilesLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                             modalShow={(id) => this.modalOpen(id)}>
                    <B.Alert variant='danger'>
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                    {this.drawModals()}
                </FilesLayout>
            );
        } else if (!loaded) {
            return (
                <FilesLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                             modalShow={(id) => this.modalOpen(id)}>
                    <B.ProgressBar animated now={100} label="Loading..."/>
                    {this.drawModals()}
                </FilesLayout>
            );
        } else {
            return (
                <FilesLayout alerts={alerts}
                             handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                             modalShow={() => this.modalOpen()}>
                    {this.drawFiles()}
                    {this.drawModals()}
                </FilesLayout>
            );
        }
    }
}

const FilesLayout = props => {
    const {children, alerts, handleCloseClick, modalShow} = props;
    return (
        <>
            {alerts.length ?
                <Alerts alerts={alerts} handleCloseClick={(alert) => handleCloseClick(alert)}/>
                : ''}
            <div className="page-header mb-3">
                <h2 className="d-inline-block">Файлове</h2>

                <B.Button className="btn btn-light float-right" onClick={() => modalShow()}>
                    <FontAwesomeIcon icon='plus' className='mr-2 text-success' fixedWidth/>
                    Добави файл
                </B.Button>
            </div>
            {children}
        </>
    );
};
