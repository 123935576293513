import React, {Component} from 'react';

export default class FooterAdmin extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const date = new Date();
        return(
            <footer className="footer bg-dark text-white py-4">
                <div className="container-fluid text-center">
                    Copyright &copy; {date.getFullYear()} ПБЦ - София 1
                </div>
            </footer>
        );
    }
}
