import React, {Component} from 'react';
import {get} from '../API';

//Import Layout
import PageTitle from "./layout/PageTitle";

import Error404 from "./Error404";
import * as B from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Carousel from "./Carousel";

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: props.match.params.category || '',
            name: props.match.params.name,
            page: null,
            pageContent: [],
            loaded: false,
            error: null,
            error404: false,
            pages: {},
            sliders: [],
            slides: []
        };
        this.API_URL = '/api/page';
        this.SLIDER_API_URL = '/api/slider';
    }

    componentWillMount() {
        const {isLoaded} = this.props;
        isLoaded(false);
        this.loadSliders();
    }

    componentDidUpdate(prevProps) {
        const {isLoaded} = this.props;
        const newCategory = this.props.match.params.category || '';
        const oldName = prevProps.match.params.name;
        const newName = this.props.match.params.name;

        if (oldName !== newName) {
            isLoaded(false);
            this.setState({name: newName});
            this.checkPage(newName, newCategory);
        }
    }

    checkPage(matchName, matchCategory = '') {
        const {pages, sliders} = this.state;

        const page = pages.filter(page => {
            const addr = page.addr.split('/');
            const cat = addr[0];
            const name = addr[1];

            return cat === matchCategory && name === matchName;
        });

        if (page && page.length > 0) {
            const pageID = page[0].id;
            this.loadPageData(pageID);

            if (sliders.length) {
                const hasSlide = sliders.indexOf(pageID) !== -1;
                if (hasSlide)
                    this.getSliderData(pageID);
            }
        } else {
            this.setState({
                loaded: true,
                error404: true,
            });
        }
    }

    loadPages() {
        this.setState({loaded: false, error: null, error404: false});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    if (data) {
                        const {language} = this.props;
                        const {category, name} = this.state;
                        const pages = data.filter(page => page.status !== 0 && page.lang_id === language);
                        this.setState({pages});
                        this.checkPage(name, category);
                    }
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadPageData(id) {
        this.setState({loaded: false, error: null, error404: false});

        get(`${this.API_URL}/${id}`)
            .then(data => {
                if (data) {
                    if (data['response'] !== false) {
                        const {isLoaded} = this.props;
                        let pageContent = [];
                        if (data['data']) {
                            const pageID = data['data'].id;

                            if (data['data_content'] && data['data_content'].hasOwnProperty(pageID) && data['data_content'][pageID]['response'] !== false) {
                                pageContent = data['data_content'][pageID];
                            }

                            this.setState({
                                loaded: true,
                                page: data['data'],
                                pageContent
                            });
                            isLoaded(true);
                        }
                    } else {
                        this.setState({
                            loaded: true,
                            error: data['message']
                        });
                    }
                } else {
                    this.setState({
                        loaded: true,
                        error404: true
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadSliders() {
        get(this.SLIDER_API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    this.loadPages();
                    const sliders = [];
                    data.map(slider => {
                        sliders.push(slider.page);
                    });
                    this.setState({sliders});
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message'],
                        sliders: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    getSliderData(pageID) {
        get(`${this.SLIDER_API_URL}/${pageID}`)
            .then(data => {
                if (data['response'] !== false) {
                    if (data['data']) {
                        const {isLoaded} = this.props;
                        const slides = [];

                        if (data['data_content'] && data['data_content'].hasOwnProperty(pageID) && data['data_content'][pageID]['response'] !== false) {
                            data['data_content'][pageID].map(slide => {
                                slides.push(slide);
                            });
                        }
                        this.setState({slides});
                        isLoaded(true);
                    }
                } else {
                    this.setState({
                        loaded: false,
                        error: data['message'],
                        slides: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    drawPageContent() {
        const {page, pageContent, slides} = this.state;
        let result = [];

        result.push(<PageTitle key={`page-${page.id}`} title={page.page}>
            {page['sub_title'] ? <h2>{page['sub_title']}</h2> : ''}
        </PageTitle>);

        if (slides.length)
            result.push(<Carousel slides={slides}/>);

        if (page['media'] && page['media'] !== '/storage/')
            result.push(<section className="page-media text-center py-3"><img className="img-fluid" src={page['media']} alt={page.page}/></section>);

        if (pageContent.length) {
            pageContent.map(section => {
                result.push(
                    <section key={`pageSection-${section.id}`} className="py-3"
                             style={section.color ? {backgroundColor: section.color} : {}}>
                        <B.Container>
                            <h3>{section.title}</h3>
                            <div dangerouslySetInnerHTML={{__html: section.content}}/>
                            {!section.icon ? '' :
                                <div className="section-icon-wrapper">
                                    <FontAwesomeIcon icon={section.icon} size="5x" className="section-icon"/>
                                </div>}
                        </B.Container>
                    </section>
                );
            });
        }

        return result;
    }

    render() {
        const {error, error404, page} = this.state;

        if (error404) {
            return <Error404/>;
        }

        if (error) {
            return (
                <main id="app" role="main" className="page">
                    <B.Alert variant='danger'>
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                </main>
            );
        } else {
            return (
                <main id="app" role="main" className="page">
                    {page ? this.drawPageContent() : ''}
                </main>
            );
        }
    }
}

export default Page;
