import React, {Component} from 'react';
import Alerts from "./Alerts";
import SettingsFooter from './SettingsFooter';
import SettingsPreaching from './SettingsPreaching';

export default class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: [],
            preaching: []
        }
    }

    handleCloseAlert(alert) {
        const currentAlerts = [...this.state.alerts];
        const alerts = currentAlerts.filter(al => al !== alert);
        this.setState({alerts});
    }

    handleAlerts(alerts) {
        this.setState({alerts});
    }

    handlePreaching(preaching) {
        this.setState({preaching});
    }

    render() {
        const {languages} = this.props;
        const {alerts, preaching} = this.state;

        return (
            <SettingsLayout alerts={alerts} handleCloseClick={alert => this.handleCloseAlert(alert)}>
                <SettingsPreaching alerts={alerts} preaching={preaching} languages={languages}
                                   onPreachingUpdate={preaching => this.handlePreaching(preaching)}
                                   updateAlerts={alerts => this.handleAlerts(alerts)}/>
                <hr/>
                <SettingsFooter alerts={alerts} preaching={preaching} languages={languages} updateAlerts={alerts => this.handleAlerts(alerts)}/>
            </SettingsLayout>
        );
    }
}

const SettingsLayout = props => {
    const {children, alerts, handleCloseClick} = props;
    return (
        <>
            {alerts.length ?
                <Alerts alerts={alerts} handleCloseClick={(alert) => handleCloseClick(alert)}/>
                : ''}
            <div className="page-header mb-3">
                <h2 className="d-inline-block">Настройки</h2>
            </div>
            {children}
        </>
    );
};

