import React, {Component} from 'react';
import {get, post} from '../../API';
import * as B from 'react-bootstrap';
import Alerts from "./Alerts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            alerts: [],
            modalShow: {'addEditUser': false, 'changePassword': false},
            validated: false,
            formsData: {},
            users: [],
            userEditID: null
        };
        this.API_URL = '/api/user';
        this.USERS_FUNC = {
            'addUser': 1,
            'editUser': 2,
            'deleteUser': 3,
            'changePassword': 4,
            'toggleAdmin': 5,
            'toggleModerator': 6
        };
        this.passPatt = "([a-zA-Z0-9_@?!{}\\-\\[\\]\\$\\(\\)\\|\\.]+){6,}";
    }

    componentWillMount() {
        this.loadUsers();
    }

    modalOpen(id, userEditID = null) {
        const modalShow = {...this.state.modalShow};
        modalShow[id] = true;

        this.setState({modalShow, userEditID});
    }

    modalHide(id, reset) {
        const modalShow = {...this.state.modalShow};
        modalShow[id] = false;

        if (reset) {
            const formsData = {...this.state.formsData};
            formsData['username'] = '';
            formsData['userMail'] = '';
            formsData['userPass'] = '';
            formsData['userPassConfirm'] = '';
            this.setState({modalShow, validated: false, formsData});
            return;
        }
        this.setState({modalShow});
    }

    handleCloseAlert(alert) {
        const currentAlerts = [...this.state.alerts];
        const alerts = currentAlerts.filter(al => al !== alert);
        this.setState({alerts});
    }

    handleInputChange(ev) {
        const target = ev.target;
        const type = target.type;
        const value = type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};
        const {modalShow} = this.state;
        formsData[name] = value;

        if (type === 'password' && modalShow['changePassword'] === true)
            this.setState({validated: true});

        this.setState({formsData});
    }

    handleSwitchChange(ev, userID, type) {
        const target = ev.target;
        const value = target.checked;
        const formsData = {...this.state.formsData};
        formsData[type][userID] = value;
        if (type === 'admin')
            formsData['mod'][userID] = value;

        this.setState({formsData});
        this.toggleUserPermissions(userID, value ? 1 : 0, type);
    }

    handleEditUserClick(userID) {
        this.getUserData(userID);
    }

    loadUsers() {
        this.setState({loaded: false, error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    const formsData = {...this.state.formsData};
                    formsData['mod'] = {};
                    formsData['admin'] = {};

                    data.map(user => {
                        formsData['mod'][user.id] = user['is_mod'];
                        formsData['admin'][user.id] = user['is_admin'] === null ? 0 : user['is_admin'];
                    });

                    this.setState({
                        users: data,
                        loaded: true,
                        formsData
                    });
                } else {
                    this.setState({
                        loaded: false,
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    getUserData(id) {
        get(`${this.API_URL}/${id}`)
            .then(data => {
                if (data['response'] !== false) {
                    const formsData = {...this.state.formsData};
                    formsData['username'] = data['name'];
                    formsData['userMail'] = data['email'];
                    this.setState({formsData});
                    this.modalOpen('addEditUser', id);
                } else {
                    this.setState({
                        loaded: false,
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    toggleUserPermissions(user_id, toggle, type) {
        const func = type === 'mod' ? this.USERS_FUNC.toggleModerator : this.USERS_FUNC.toggleAdmin;
        const params = {user_id, toggle};
        const data = {auth: {func}, params};

        post(this.API_URL, data, true)
            .then(data => {
                if (data['response'] === false) {
                    const alerts = [...this.state.alerts];
                    alerts.push({type: 'danger', text: data['message']});
                    this.setState({
                        alerts
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    handleAddEditUser(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const alerts = [...this.state.alerts];
            const {userEditID} = this.state;
            const {username, userMail, userPass, userPassConfirm} = this.state.formsData;
            const func = userEditID ? this.USERS_FUNC.editUser : this.USERS_FUNC.addUser;
            const params = {name: username, email: userMail, password: userPass, user_id: userEditID};
            const data = {auth: {func}, params};
            const patt = new RegExp(this.passPatt);

            if (userPass) {
                if (userPass !== userPassConfirm) {
                    alerts.push({type: 'danger', text: 'Паролите не съвпадат!'});
                    this.setState({
                        alerts
                    });
                    return;
                }

                if (userPass.length < 6 || userPassConfirm.length < 6) {
                    alerts.push({type: 'danger', text: 'Паролата е под 6 символа!'});
                    this.setState({
                        alerts
                    });
                    return;
                }

                if (!patt.test(userPass) || !patt.test(userPassConfirm)) {
                    alerts.push({type: 'danger', text: 'Използвате забранени символи!'});
                    this.setState({
                        alerts
                    });
                    return;
                }
            }

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.modalHide('addEditUser', true);
                        this.loadUsers();
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleDeleteUser(user_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете този потребител?')) {
            this.setState({loaded: false});
            const params = {user_id};
            const data = {auth: {func: this.USERS_FUNC.deleteUser}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.loadUsers();
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleChangePassword(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const alerts = [...this.state.alerts];
            const {newPass, newPassConfirm} = this.state.formsData;
            const patt = new RegExp(this.passPatt);
            const {userEditID} = this.state;

            if (newPass !== newPassConfirm) {
                alerts.push({type: 'danger', text: 'Паролите не съвпадат!'});
                this.setState({
                    alerts
                });
                return;
            }

            if (newPass.length < 6 || newPassConfirm.length < 6) {
                alerts.push({type: 'danger', text: 'Паролата е под 6 символа!'});
                this.setState({
                    alerts
                });
                return;
            }

            if (!patt.test(newPass) || !patt.test(newPassConfirm)) {
                alerts.push({type: 'danger', text: 'Използвате забранени символи!'});
                this.setState({
                    alerts
                });
                return;
            }

            const params = {user_id: userEditID, password: newPass};
            const data = {auth: {func: this.USERS_FUNC.changePassword}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.modalHide('changePassword', true);
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    drawTable() {
        const {users, formsData} = this.state;
        if (users.length) {
            return (
                <B.Table striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Потребител</th>
                        <th>Създаден на</th>
                        <th>Модератор</th>
                        <th>Администратор</th>
                        <th>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map(user => {
                        const editBtn = <B.Button type="button" variant="link" title="Редакция"
                                                  onClick={() => this.handleEditUserClick(user.id)}><FontAwesomeIcon
                            icon="user-edit" fixedWidth/></B.Button>;
                        const deleteBtn = <B.Button type="button" variant="link" title="Изтрий"
                                                    onClick={() => this.handleDeleteUser(user.id)}><FontAwesomeIcon
                            icon="user-times" fixedWidth className="text-danger"/></B.Button>;
                        const passwordBtn = <B.Button type="button" variant="link" title="Смени парола"
                                                      onClick={() => this.modalOpen('changePassword', user.id)}><FontAwesomeIcon
                            icon="user-lock" fixedWidth className="text-body"/></B.Button>;
                        const dbDate = user['created_at'].split('-');
                        const date = new Date(dbDate[2], dbDate[1], dbDate[0]);
                        const isMod = formsData['mod'][user.id];
                        const isAdmin = formsData['admin'][user.id];

                        return (<tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}</td>
                            <td>
                                <div className="custom-control custom-switch">
                                    <B.Form.Check type='checkbox' id={`toggleMod-${user.id}`}>
                                        <B.Form.Check.Input type='checkbox' className="custom-control-input"
                                                            checked={isMod}
                                                            onChange={(ev) => this.handleSwitchChange(ev, user.id, 'mod')}/>
                                        <B.Form.Check.Label
                                            className="custom-control-label">{isMod ? 'Да' : 'Не'}</B.Form.Check.Label>
                                    </B.Form.Check>
                                </div>
                            </td>
                            <td>
                                <div className="custom-control custom-switch">
                                    <B.Form.Check type='checkbox' id={`toggleAdmin-${user.id}`}>
                                        <B.Form.Check.Input type='checkbox' className="custom-control-input"
                                                            checked={isAdmin}
                                                            onChange={(ev) => this.handleSwitchChange(ev, user.id, 'admin')}/>
                                        <B.Form.Check.Label
                                            className="custom-control-label">{isAdmin ? 'Да' : 'Не'}</B.Form.Check.Label>
                                    </B.Form.Check>
                                </div>
                            </td>
                            <td>{editBtn}{deleteBtn}{passwordBtn}</td>
                        </tr>)
                    })}
                    </tbody>
                </B.Table>
            );
        }
    }

    drawModals() {
        const {formsData, validated, userEditID} = this.state;

        return (
            <>
                {/*Add/Edit User Modal*/}
                <B.Modal
                    id='addEditUser'
                    centered
                    show={this.state.modalShow['addEditUser']}
                    onHide={() => this.modalHide('addEditUser', true)}
                    aria-labelledby="addEditUserTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="addEditUserTitle">
                            {userEditID ? 'Редактирай' : 'Добави'} потребител
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            noValidate
                            validated={validated}
                            onSubmit={e => this.handleAddEditUser(e)}>
                            <fieldset>
                                <B.Form.Group controlId="username">
                                    <B.Form.Label>Потребител</B.Form.Label>
                                    <B.Form.Control type="text" name="username" value={formsData['username'] || ''}
                                                    onChange={(ev) => this.handleInputChange(ev)} required/>
                                    <B.Form.Control.Feedback type="invalid">Полето е
                                        задължително!</B.Form.Control.Feedback>
                                </B.Form.Group>
                                <B.Form.Group controlId="userMail">
                                    <B.Form.Label>Email</B.Form.Label>
                                    <B.Form.Control type="email" name="userMail" value={formsData['userMail'] || ''}
                                                    onChange={(ev) => this.handleInputChange(ev)} required/>
                                    <B.Form.Control.Feedback type="invalid">Полето е
                                        задължително!</B.Form.Control.Feedback>
                                </B.Form.Group>
                            </fieldset>
                            {userEditID ? '' :
                                <fieldset>
                                    <B.Form.Group controlId="userPass">
                                        <B.Form.Label>Нова парола</B.Form.Label>
                                        <B.Form.Control type="password" name="userPass"
                                                        value={formsData['userPass'] || ''} pattern={this.passPatt}
                                                        onChange={(ev) => this.handleInputChange(ev)} required/>
                                        <B.Form.Control.Feedback type="invalid">Паролата съдържа забранени символи
                                            или е под 6 символа!</B.Form.Control.Feedback>
                                    </B.Form.Group>
                                    <B.Form.Group controlId="userPassConfirm">
                                        <B.Form.Label>Повтори паролата</B.Form.Label>
                                        <B.Form.Control type="password" name="userPassConfirm"
                                                        value={formsData['userPassConfirm'] || ''}
                                                        pattern={this.passPatt}
                                                        onChange={(ev) => this.handleInputChange(ev)} required/>
                                        <B.Form.Control.Feedback type="invalid">Паролата съдържа забранени символи
                                            или е под 6 символа!</B.Form.Control.Feedback>
                                    </B.Form.Group>
                                </fieldset>}
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon={userEditID ? 'user-edit' : 'user-plus'} fixedWidth
                                                     className="mr-2"/>
                                    {userEditID ? 'Редактирай' : 'Добави'} потребител
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
                {/*Change Password Modal*/}
                <B.Modal
                    id='changePassword'
                    centered
                    show={this.state.modalShow['changePassword']}
                    onHide={() => this.modalHide('changePassword', true)}
                    aria-labelledby="changePasswordTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="changePasswordTitle">
                            Смяна на парола
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            noValidate
                            validated={validated}
                            onSubmit={e => this.handleChangePassword(e)}>
                            <B.Form.Group controlId="userNewPassword">
                                <B.Form.Label>Нова парола</B.Form.Label>
                                <B.Form.Control type="password" name="newPass" value={formsData['newPass'] || ''}
                                                pattern={this.passPatt}
                                                onChange={(ev) => this.handleInputChange(ev)} required/>
                                <B.Form.Control.Feedback type="invalid">Паролата съдържа забранени символи или е под
                                    6 символа!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="userNewPasswordConfirm">
                                <B.Form.Label>Повтори паролата</B.Form.Label>
                                <B.Form.Control type="password" name="newPassConfirm"
                                                value={formsData['newPassConfirm'] || ''} pattern={this.passPatt}
                                                onChange={(ev) => this.handleInputChange(ev)} required/>
                                <B.Form.Control.Feedback type="invalid">Паролата съдържа забранени символи или е под
                                    6 символа!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon="user-plus" fixedWidth className="mr-2"/>
                                    Смяна на парола
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
            </>
        )
    }

    render() {
        const {loaded, error, alerts} = this.state;

        if (error) {
            return (
                <UsersLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                             modalShow={(id) => this.modalOpen(id)}>
                    <B.Alert variant='danger'>
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                    {this.drawModals()}
                </UsersLayout>
            );
        } else if (!loaded) {
            return (
                <UsersLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                             modalShow={(id) => this.modalOpen(id)}>
                    <B.ProgressBar animated now={100} label="Loading..."/>
                    {this.drawModals()}
                </UsersLayout>
            );
        } else {
            return (
                <UsersLayout alerts={alerts}
                             handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                             modalShow={(id) => this.modalOpen(id)}>
                    {this.drawTable()}
                    {this.drawModals()}
                </UsersLayout>
            );
        }
    }
}

const UsersLayout = props => {
    const {children, alerts, handleCloseClick, modalShow} = props;
    return (
        <>
            {alerts.length ?
                <Alerts alerts={alerts} handleCloseClick={(alert) => handleCloseClick(alert)}/>
                : ''}
            <div className="page-header mb-3">
                <h2 className="d-inline-block">Потребители</h2>

                <B.Button className="btn btn-light float-right" onClick={() => modalShow('addEditUser')}>
                    <FontAwesomeIcon icon='plus' className='mr-2 text-success' fixedWidth/>
                    Добави потребител
                </B.Button>
            </div>
            {children}
        </>
    );
};
