import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import HeaderAdmin from "../dashboard/layout/HeaderAdmin";
import FooterAdmin from "../dashboard/layout/FooterAdmin";

const Layout = (props) => {
    const {language, languages, onLanguageChange, isAdmin} = props;

    return (
        <React.Fragment>
            {isAdmin ? <HeaderAdmin/> : <Header language={language} languages={languages} onLanguageChange={(lang) => onLanguageChange(lang)}/>}
            {props.children}
            {isAdmin ? <FooterAdmin/> :  <Footer language={language}/>}
        </React.Fragment>
    );
};

export default Layout;
