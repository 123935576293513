import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import * as B from 'react-bootstrap';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {post, get, postFile} from '../../API';
import Alerts from "./Alerts";
import TextEditor from './TextEditor';
import ColorPicker from "./ColorPicker";
import IconPicker from "./IconPicker";

export default class Pages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            alerts: [],
            modalShow: false,
            pages: [],
            pageEditID: props.match.params.id,
            validated: false,
            formsData: {}
        };
        this.API_URL = '/api/page';
        this.PAGE_FUNC = {
            'newPage': 1,
            'editPage': 2,
            'deletePage': 3,
            'newContent': 4,
            'editContent': 5,
            'deleteContent': 6
        };
        this.colorPatt = new RegExp(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    }

    componentWillMount() {
        const {pageEditID} = this.state;

        //If the param is not valid redirect
        if (pageEditID && pageEditID !== 'add' && isNaN(pageEditID)) {
            this.props.history.push('/admin/pages');
        }

        if (!isNaN(pageEditID))
            this.loadPageData(pageEditID);

        this.loadPages();
    }

    componentDidUpdate(prevProps) {
        const oldMatchName = prevProps.match.params.id;
        const pageEditID = this.props.match.params.id;

        if (oldMatchName !== pageEditID) {
            this.setState({pageEditID});

            if (!isNaN(pageEditID))
                this.loadPageData(pageEditID);
        }
    }

    modalOpen() {
        this.setState({modalShow: true});
    }

    modalHide(reset) {
        if (reset) {
            const formsData = {...this.state.formsData};
            this.setState({modalShow: false, validated: false, formsData});
            return;
        }
        this.setState({modalShow: false});
    }

    handleCloseAlert(alert) {
        const currentAlerts = [...this.state.alerts];
        const alerts = currentAlerts.filter(al => al !== alert);
        this.setState({alerts});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};
        formsData[name] = value;

        this.setState({formsData});
    }

    handleFileInputChange(event, allowedFormats) {
        const target = event.target;
        const value = target.files[0];
        const name = target.name;
        const type = value.type;

        if (!allowedFormats.includes(type)) {
            let alerts = [...this.state.alerts];
            alerts.push({type: 'danger', text: 'Неподдържан формат!'});
            this.setState({alerts});
            return;
        }

        const formsData = {...this.state.formsData};
        formsData[name] = value;
        formsData['mediaType'] = type;

        this.setState({formsData});
    }

    handlePageContentChange(event, name, id) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const formsData = {...this.state.formsData};
        formsData.content[id][name] = value;

        this.setState({formsData});
    }

    handleContentColorChange(color, name, id) {
        const formsData = {...this.state.formsData};
        if (id)
            formsData.content[id][name] = color;
        else
            formsData[name] = color;

        this.setState({formsData});
    }

    handleContentIconChange(icon, name, id) {
        const formsData = {...this.state.formsData};
        if (id)
            formsData.content[id][name] = icon;
        else
            formsData[name] = icon;

        this.setState({formsData});
    }

    getEditorContent(content, name, id) {
        const formsData = {...this.state.formsData};

        if (id) {
            formsData.content[id][name] = content;
        } else {
            formsData[name] = content;
        }

        this.setState({formsData});
    }

    loadPages() {
        this.setState({loaded: false, error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    this.setState({
                        loaded: true,
                        pages: data
                    });
                } else {
                    this.setState({
                        loaded: false,
                        error: data['message'],
                        pages: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadPageData(id) {
        this.setState({loaded: false, error: null});

        get(`${this.API_URL}/${id}`)
            .then(data => {
                if (data) {
                    if (data['response'] !== false) {
                        const formsData = {};
                        for (let name in data['data']) {
                            if (data['data'].hasOwnProperty(name)) {
                                if (name === 'addr') {
                                    formsData['component'] = data['data'][name].split('/')[0];
                                    formsData['addr'] = data['data'][name].split('/')[1];
                                    continue;
                                }
                                if (name === 'media') {
                                    formsData['pageImageThumb'] = data['data'][name] !== '/storage/' ?  data['data'][name] : null;
                                    continue;
                                }
                                formsData[name] = data['data'][name]
                            }
                        }
                        let pageForEditContent = {};

                        if (data['data_content'] && data['data_content'].hasOwnProperty(id) && data['data_content'][id]['response'] !== false) {
                            data['data_content'][id].forEach(content => {
                                pageForEditContent[content.id] = content;
                            });
                        } else {
                            pageForEditContent = null;
                        }

                        formsData['content'] = pageForEditContent;
                        this.setState({
                            loaded: true,
                            formsData
                        });
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                } else {
                    const alerts = [...this.state.alerts];
                    alerts.push({type: 'danger', text: 'Възникна грешка!'});
                    this.setState({
                        loaded: true,
                        alerts
                    });
                    this.props.history.push('/admin/pages');
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    handleAddClick() {
        this.setState({formsData: {}});
    }

    handleEditClick(pageID) {
        this.loadPageData(pageID);
    }

    handlePages(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            this.setState({loaded: false});
            const {pageEditID} = this.state;
            const func = pageEditID === 'add' ? this.PAGE_FUNC.newPage : this.PAGE_FUNC.editPage;
            const {page, sub_title, addr, component, status, pageImage, lang_id} = this.state.formsData;
            const params = {
                page_id: !isNaN(pageEditID) ? pageEditID : null,
                page,
                sub_title,
                lang_id,
                status: status ? status : 0,
                addr: `${component !== 'undefined' ? component : ''}/${addr}`
            };
            const formsData = new FormData();
            formsData.append('auth', JSON.stringify({func}));
            formsData.append('params', JSON.stringify(params));
            if (pageImage)
                formsData.append('media', pageImage);

            postFile(this.API_URL, formsData, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts,
                            validated: false
                        });
                        if (data['record_id']) {
                            this.props.history.push(`/admin/pages/${data['record_id']}`);
                        } else {
                            this.loadPageData(pageEditID);
                        }
                        this.loadPages();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'danger', text: data['message']});

                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleDeletePage(page_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете тази страница?')) {
            this.setState({loaded: false});

            const params = {page_id};
            const data = {auth: {func: this.PAGE_FUNC.deletePage}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts
                        });
                        this.loadPages();
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleSections(ev, func, page_content_id = null) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            this.setState({loaded: false});
            const {formsData} = this.state;
            let title = formsData['sectionTitle'];
            let content = formsData['sectionContent'];
            let color = formsData['sectionColor'];
            let icon = formsData['sectionIcon'];

            if (func === this.PAGE_FUNC.editContent) {
                const contentData = this.state.formsData.content[page_content_id];
                title = contentData.title;
                content = contentData.content;
                color = contentData.color;
                icon = contentData.icon;
            }

            if (content === undefined || content === '' || content === '<p></p>' || content === '<p><br></p>') {
                const alerts = [...this.state.alerts];
                alerts.push({type: 'danger', text: 'Съдържанието на секцията не може да бъде празно!'});

                this.setState({alerts, loaded: true});
                return;
            }

            const params = {
                page: this.state.pageEditID,
                content,
                page_content_id,
                title,
                icon,
                color
            };
            const data = {auth: {func}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        if (func === this.PAGE_FUNC.newContent)
                            this.modalHide(true);

                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts,
                            validated: false
                        });
                        this.loadPageData(this.state.pageEditID);
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleRemoveSection(sectionID) {
        if (confirm('Сигурни ли сте, че искате да изтриете тази секция?')) {
            this.setState({loaded: false});

            const params = {page_content_id: sectionID};
            const data = {auth: {func: this.PAGE_FUNC.deleteContent}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts
                        });
                        this.loadPageData(this.state.pageEditID);
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    drawTable() {
        const {pages} = this.state;

        if (pages) {
            return (
                <table className="table table-sm table-striped">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Заглавие</th>
                        <th>Адрес</th>
                        <th>Език</th>
                        <th>Статус</th>
                        <th>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pages.map(page => {
                        const viewBtn = <a href={`${page.addr.indexOf('/') === 0 ? '' : '/'}${page.addr}`}
                                           target="_blank"
                                           className="btn btn-link mr-1" title="Преглед">
                            <FontAwesomeIcon icon="eye" fixedWidth className="text-body"/>
                        </a>;
                        const editBtn = <Link to={`/admin/pages/${page.id}`} className="btn btn-link mr-1"
                                              title="Редакция">
                            <FontAwesomeIcon icon="edit" fixedWidth className="text-primary"/>
                        </Link>;
                        const deleteBtn = <button type="button" className="btn btn-link"
                                                  onClick={() => this.handleDeletePage(page.id)} title="Изтрий">
                            <FontAwesomeIcon icon="trash-alt" fixedWidth className="text-danger"/>
                        </button>;

                        return (<tr key={page.id}>
                            <td>{page.id}</td>
                            <td>
                                <Link to={`/admin/pages/${page.id}`}>{page.page}</Link>
                            </td>
                            <td>{page.addr}</td>
                            <td>{page.lang}</td>
                            <td>{page.status ? 'Публична' : 'Чернова'}</td>
                            <td>{viewBtn}{editBtn}{deleteBtn}</td>
                        </tr>)
                    })}
                    </tbody>
                </table>
            );
        }
    }

    drawPageSections() {
        const {formsData, validated} = this.state;

        if (formsData.content === null)
            return (<B.Alert variant='warning'>Страницата няма секции</B.Alert>);

        if (formsData.content) {
            let counter = 0;
            let content = [];
            for (let id in formsData.content) {
                if (formsData.content.hasOwnProperty(id)) {
                    const section = formsData.content[id];
                    const sectionColorMatch = section.color ? section.color.match(this.colorPatt) : null;
                    const sectionColor = sectionColorMatch ? {
                        r: sectionColorMatch[1],
                        g: sectionColorMatch[2],
                        b: sectionColorMatch[3],
                        a: sectionColorMatch[4]
                    } : null;
                    counter++;

                    content.push(
                        <B.Card key={id} className="mb-3">
                            <B.Card.Header id={`sectionHead-${id}`}>
                                <B.Button variant="link" type="button" data-toggle="collapse"
                                          data-target={`#section-${id}`}
                                          aria-expanded="false"
                                          aria-controls={`section-${id}`}>
                                    {section.title ? section.title : `Секция ${counter}`}
                                </B.Button>
                                <B.Button variant="link" type="button" className="float-right"
                                          title="Изтрий секцията"
                                          onClick={() => this.handleRemoveSection(id)}>
                                    <FontAwesomeIcon icon="trash-alt" fixedWidth size="lg" className="text-danger"/>
                                </B.Button>
                            </B.Card.Header>
                            <div id={`section-${id}`} className="collapse"
                                 aria-labelledby={`sectionHead-${id}`}
                                 data-parent="#pageSections">
                                <B.Card.Body>
                                    <B.Form
                                        noValidate
                                        validated={validated}
                                        onSubmit={e => this.handleSections(e, this.PAGE_FUNC.editContent, id)}>
                                        <B.Form.Group controlId="sectionTitle">
                                            <B.Form.Label>Заглавие на секцията</B.Form.Label>
                                            <B.Form.Control type="text"
                                                            value={section.title || ''}
                                                            onChange={ev => this.handlePageContentChange(ev, 'title', id)}/>
                                            <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                                        </B.Form.Group>
                                        <B.Form.Group controlId="sectionContent">
                                            <B.Form.Label>Съдържание на секцията</B.Form.Label>
                                            <TextEditor
                                                getContent={content => this.getEditorContent(content, 'content', id)}
                                                defaultValue={section.content || ''}/>
                                            <B.Form.Control.Feedback type="invalid">Полето е
                                                задължително!</B.Form.Control.Feedback>
                                        </B.Form.Group>
                                        <B.Form.Group controlId="sectionColor">
                                            <B.Form.Label>Фон на секцията</B.Form.Label>
                                            <ColorPicker color={sectionColor}
                                                         handleChange={(color) => this.handleContentColorChange(color, 'color', id)}/>
                                            <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                                        </B.Form.Group>
                                        <B.Form.Group controlId="sectionIcon">
                                            <B.Form.Label>Икона на секцията</B.Form.Label>
                                            <B.Form.Control type="text"
                                                            value={section.icon || ''}
                                                            onChange={ev => this.handlePageContentChange(ev, 'icon', id)}/>
                                            {/*TODO: Find a better way to pick up an icon*/}
                                            {/*<IconPicker onChange={(icon) => this.handleContentIconChange(icon, 'icon', id)}/>*/}
                                            <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                                        </B.Form.Group>
                                        <B.Form.Group className="text-right">
                                            <B.Button variant="light" type="submit">
                                                <FontAwesomeIcon icon="save" fixedWidth className="mr-2"/>
                                                Запази секцията
                                            </B.Button>
                                        </B.Form.Group>
                                    </B.Form>
                                </B.Card.Body>
                            </div>
                        </B.Card>
                    );
                }
            }

            return content;
        }
    }

    drawForm() {
        const {languages} = this.props;
        const {pageEditID, formsData, validated} = this.state;
        const sectionColorMatch = formsData['sectionColor'] ? formsData['sectionColor'].match(this.colorPatt) : null;
        const sectionColor = sectionColorMatch ? {
            r: sectionColorMatch[1],
            g: sectionColorMatch[2],
            b: sectionColorMatch[3],
            a: sectionColorMatch[4]
        } : null;

        return (
            <>
                <B.Form
                    noValidate
                    validated={validated}
                    onSubmit={e => this.handlePages(e)}
                    method="POST"
                    encType="multipart/form-data">
                    <fieldset>
                        <legend>Основна информация</legend>
                        <B.Form.Group controlId='pageTitle'>
                            <B.Form.Label>Заглавие</B.Form.Label>
                            <B.Form.Control type="text" placeholder="Страница" name="page"
                                            value={formsData['page'] || ''}
                                            onChange={(ev) => this.handleInputChange(ev)} required/>
                        </B.Form.Group>
                        <B.Form.Group controlId='pageSubTitle'>
                            <B.Form.Label>Подзаглавие</B.Form.Label>
                            <B.Form.Control type="text" placeholder="За служения" name="sub_title"
                                            value={formsData['sub_title'] || ''}
                                            onChange={(ev) => this.handleInputChange(ev)}/>
                            <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                        </B.Form.Group>
                        <B.Row>
                            <B.Col xs={12} md={3}>
                                <B.Form.Group controlId='pageName'>
                                    <B.Form.Label>Служебно име</B.Form.Label>
                                    <B.Form.Control type="text" placeholder="ministries" name="addr"
                                                    value={formsData['addr'] || ''}
                                                    onChange={(ev) => this.handleInputChange(ev)} required/>
                                </B.Form.Group>
                            </B.Col>
                            <B.Col xs={12} md={3}>
                                <B.Form.Group controlId='pageComponent'>
                                    <B.Form.Label>Шаблон</B.Form.Label>
                                    <B.Form.Control as="select" name="component" value={formsData['component'] || ''}
                                                    onChange={(ev) => this.handleInputChange(ev)}
                                                    className="custom-select">
                                        <option value="">Без префикс (/)</option>
                                        <option value="ministries">Служение (/ministries)</option>
                                        <option value="page">Страница (/page)</option>
                                        <option value="media">Ресурс (/media)</option>
                                    </B.Form.Control>
                                </B.Form.Group>
                            </B.Col>
                            <B.Col xs={12} md={3}>
                                <B.Form.Group controlId='pageLanguage'>
                                    <B.Form.Label>Език</B.Form.Label>
                                    <B.Form.Control as="select" name="lang_id" value={formsData['lang_id'] || ''}
                                                    onChange={(ev) => this.handleInputChange(ev)}
                                                    className="custom-select" required>
                                        <option value="">Избери език</option>
                                        {languages.map(({id, lang}) => (
                                            <option key={id} value={id}>{lang}</option>
                                        ))}
                                    </B.Form.Control>
                                    <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                                </B.Form.Group>
                            </B.Col>
                            <B.Col xs={12} md={3}>
                                <B.Form.Group controlId='pageStatus'>
                                    <label>Статус</label>
                                    <B.Form.Check custom type="checkbox" label="Публична" name="status"
                                                  checked={formsData['status'] ? formsData['status'] : 0} value="1"
                                                  onChange={(ev) => this.handleInputChange(ev)}/>
                                </B.Form.Group>
                            </B.Col>
                        </B.Row>
                        <B.Form.Group controlId="pageImage">
                            <B.Form.Label>Заглавна снимка</B.Form.Label>
                            {formsData['pageImageThumb'] ? <div className="w-25 mb-2"><img className="img-thumbnail" src={formsData['pageImageThumb']} alt={formsData['page']}/></div> : ''}
                            <div className="custom-file">
                                <B.Form.Control type="file" name="pageImage" className="custom-file-input"
                                                onChange={(ev) => this.handleFileInputChange(ev, ['image/jpeg', 'image/png', 'image/gif'])}
                                                accept="image/jpeg, image/png, image/gif"
                                                lang="bg"/>
                                <B.Form.Label className="custom-file-label">
                                    {formsData['pageImage'] ?
                                        formsData['pageImage'].length > 1 ?
                                            `${formsData['pageImage'].length} снимки` :
                                            formsData['pageImage']['name'] :
                                        <div>
                                            <span className="d-md-none">Снимки</span>
                                            <span className="d-none d-md-block">.jpeg, .jpg, .png, .gif</span>
                                        </div>}
                                </B.Form.Label>
                                <B.FormText className="text-muted d-md-none">.jpeg, .jpg, .png, .gif</B.FormText>
                                <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                            </div>
                        </B.Form.Group>
                        <B.Form.Group className="text-right">
                            <B.Button type="submit" variant='primary'>
                                <FontAwesomeIcon icon={!isNaN(pageEditID) ? 'save' : 'plus'} fixedWidth
                                                 className="mr-2"/>
                                {!isNaN(pageEditID) ? 'Запази промените' : 'Създай страница'}
                            </B.Button>
                        </B.Form.Group>
                    </fieldset>
                </B.Form>
                <div id="pageContentWrapper">
                    <h4 className="font-weight-normal">Съдържание</h4>
                    <div id="pageSections">
                        {!isNaN(pageEditID) ? this.drawPageSections() :
                            <B.Alert variant='info'>Трябва да създадете страница, за да добавите съдържание</B.Alert>}
                    </div>
                    {!isNaN(pageEditID) ?
                        <div className="text-center">
                            <B.Button type="button" variant='dark' onClick={() => this.modalOpen()}>
                                <FontAwesomeIcon icon='folder-plus' fixedWidth className="mr-2"/>
                                Добави секция
                            </B.Button>
                        </div>
                        : ''
                    }
                </div>
                <B.Modal
                    size="lg"
                    centered
                    keyboard={false}
                    show={this.state.modalShow}
                    onHide={() => this.modalHide()}
                    aria-labelledby="addSectionTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="addSectionTitle">
                            Добави секция
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            noValidate
                            validated={validated}
                            onSubmit={e => this.handleSections(e, this.PAGE_FUNC.newContent)}>
                            <B.Form.Group controlId="sectionTitle">
                                <B.Form.Label>Заглавие на секцията</B.Form.Label>
                                <B.Form.Control type="text" name="sectionTitle"
                                                value={formsData['sectionTitle'] || ''}
                                                onChange={ev => this.handleInputChange(ev)}/>
                                <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                            </B.Form.Group>
                            <B.Form.Group controlId='sectionContent'>
                                <B.Form.Label>Съдържание на секцията</B.Form.Label>
                                <TextEditor getContent={content => this.getEditorContent(content, 'sectionContent')}/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="sectionColor">
                                <B.Form.Label>Фон на секцията</B.Form.Label>
                                <ColorPicker color={sectionColor}
                                             handleChange={(color) => this.handleContentColorChange(color, 'sectionColor')}/>
                                <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                            </B.Form.Group>
                            <B.Form.Group controlId="sectionIcon">
                                <B.Form.Label>Икона на секцията</B.Form.Label>
                                <B.Form.Control type="text" name="sectionIcon"
                                                value={formsData['sectionIcon'] || ''}
                                                onChange={ev => this.handleInputChange(ev)}/>
                                {/*TODO: Find a better way to pick up an icon*/}
                                {/*<IconPicker onChange={(icon) => this.handleContentIconChange(icon, 'sectionIcon')}/>*/}
                                <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                            </B.Form.Group>
                            <B.Form.Group className="text-right">
                                <B.Button variant="secondary" onClick={() => this.modalHide()} className="mr-2">
                                    Затвори
                                </B.Button>
                                <B.Button variant="success" type="submit">
                                    <FontAwesomeIcon icon="plus" fixedWidth className="mr-2"/>
                                    Добави секция
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
            </>
    );
    }

    render() {
        const {pageEditID, loaded, error, alerts} = this.state;

        if (error) {
        return (
        <PagesLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}>
        <B.Alert variant='danger'>
        <strong>Грешка:</strong> {error}
        </B.Alert>
        </PagesLayout>
        );
    } else if (!loaded) {
        return (
        <PagesLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}>
        <B.ProgressBar animated now={100} label="Loading..."/>
        </PagesLayout>
        );
    } else {
        return (
        <PagesLayout isEdit={pageEditID} alerts={alerts} handleAddClick={() => this.handleAddClick()}
        handleCloseClick={(alert) => this.handleCloseAlert(alert)}>
        {pageEditID ? this.drawForm() : this.drawTable()}
        </PagesLayout>
        );
    }
    }
    }

    const PagesLayout = props => {
        const {isEdit, children, alerts, handleAddClick, handleCloseClick} = props;
        return (
        <React.Fragment>
        {alerts.length ?
            <Alerts alerts={alerts} handleCloseClick={(alert) => handleCloseClick(alert)}/>
            : ''}
        <div className="page-header mb-3">
        <h2 className="d-inline-block">Страници</h2>

        <Link to={isEdit ? '/admin/pages' : '/admin/pages/add'} className="btn btn-light float-right"
        onClick={() => handleAddClick()}>
        <FontAwesomeIcon icon={isEdit ? 'chevron-left' : 'plus'}
        className={`mr-2 ${isEdit ? '' : 'text-success'}`} fixedWidth/>
        {isEdit ? 'Обратно' : 'Добави страница'}
        </Link>
        </div>
        {children}
        </React.Fragment>
        );
    };
