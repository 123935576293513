import React from 'react';
import * as B from 'react-bootstrap';

const PageTitle = ({children, className, ...props}) => {
    return (
        <section key="pageTitleSectionKey" className={`page-title bg-primary py-2 text-white ${className ? className : ''}`}>
            <B.Container>
                <h1 key="pageTitleKey" className="display-3">{props.title}</h1>
                {children}
            </B.Container>
        </section>
    );
};

export default PageTitle;
