import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {lang} from "../lang/all";

class Countdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
        }
    }

    componentDidMount() {
        // update every second
        this.interval = setInterval(() => {
            const date = this.calculateCountdown(this.props.date);
            date ? this.setState(date) : this.stop();
        }, 1000);
    }

    componentWillUnmount() {
        this.stop();
    }

    calculateCountdown(endDate) {
        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            millisec: 0,
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }

    render() {
        const {language} = this.props;
        const {days, hours, min, sec} = this.state;

        return (
            <div className="countdown">
                <div className="countdown-col">
                    <div className="countdown-col-element">
                        <strong>{days}</strong>
                        <span>{days === 1 ? `${lang[language].day}` : `${lang[language].days}`}</span>
                    </div>
                </div>

                <div className="countdown-col">
                    <div className="countdown-col-element">
                        <strong>{this.addLeadingZeros(hours)}</strong>
                        <span>{hours === 1 ? `${lang[language].hour}` : `${lang[language].hours}`}</span>
                    </div>
                </div>


                <div className="countdown-col">
                    <div className="countdown-col-element">
                        <strong>{this.addLeadingZeros(min)}</strong>
                        <span>{min === 1 ? `${lang[language].minute}` : `${lang[language].minutes}`}</span>
                    </div>
                </div>

                <div className="countdown-col">
                    <div className="countdown-col-element">
                        <strong>{this.addLeadingZeros(sec)}</strong>
                        <span>{lang[language].seconds}</span>
                    </div>
                </div>
            </div>
        );
    }
}

Countdown.propTypes = {
    date: PropTypes.string.isRequired
};

Countdown.defaultProps = {
    date: new Date()
};

export default Countdown;
