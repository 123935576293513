import React, {Component} from 'react';
import * as B from 'react-bootstrap';
import {get} from "../API";
import {Link} from "react-router-dom";

class IndexSermons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sermons: [],
            lastSermon: {},
            error: null
        };
        this.API_URL = '/api/sermons';
        this.MEDIA_PLACEHOLDER = 'images/media_placeholder.jpg';
    }

    componentWillMount() {
        this.loadSermons();
    }

    loadSermons() {
        this.setState({error: null});

        const urlParams = new URLSearchParams();
        urlParams.set("page", 1);
        urlParams.set('year', 2021);

        get(`${this.API_URL}?${urlParams.toString()}`)
            .then(data => {
                if (data['response'] !== false) {
                    const lastSermon = data['sermons'].shift();
                    this.setState({
                        lastSermon,
                        sermons: data['sermons'].slice(0, 3)
                    });
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message'],
                        sermons: [],
                        lastSermon: {}
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    render() {
        const {lastSermon, sermons, error} = this.state;
        const lastSermonDate = lastSermon.hasOwnProperty('uploaded_at') ? lastSermon['uploaded_at'].split(' ')[0].split('-') : null;
        const date = lastSermonDate ? new Date(lastSermonDate[2], lastSermonDate[1], lastSermonDate[0]) : null;
        let lastSermonCols = sermons.length ? 9 : 12;

        if (error)
            return <B.Alert variant="info">{error}</B.Alert>;

        return (
            <B.Row className="index-media-wrapper">
                {lastSermon.hasOwnProperty('sermon_id') ?
                    <B.Col key={lastSermon['sermon_id']} xs={12} md={lastSermonCols} lg={lastSermonCols}
                           className="index-media-main d-flex flex-column justify-content-center mb-3 mb-md-0">
                        <video width="100%" height="100%" controls poster={lastSermon.cover !== '/storage' ? lastSermon.cover : this.MEDIA_PLACEHOLDER} preload="metadata">
                            <source src={lastSermon.media} type="video/mp4"/>
                        </video>
                        <h3 className="display-4 index-media-main-title">
                            <Link to={`/media/sermons/${lastSermon['sermon_id']}`} className="text-body">{lastSermon.title}</Link> - {`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}
                        </h3>
                    </B.Col>
                    : ''}
                {sermons.length ?
                    <B.Col key='olderSermonsKey' xs={12} md={3} lg={3}
                           className="d-flex flex-wrap flex-md-nowrap flex-row flex-md-column align-items-md-center">
                        {sermons.map(sermon => {
                            const sermonDate = sermon['uploaded_at'].split(' ')[0].split('-');
                            const date = new Date(sermonDate[2], sermonDate[1], sermonDate[0]);

                            return (
                                <div key={sermon['sermon_id']} className="index-media mb-3">
                                    <Link to={`/media/sermons/${sermon['sermon_id']}`} className="text-body">
                                        <img src={sermon.cover !== '/storage/' ? sermon.cover : this.MEDIA_PLACEHOLDER} className="img-fluid" alt="Video"/>
                                        {sermon.title}<br/>{`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}
                                    </Link>
                                </div>
                            );
                        })}
                    </B.Col>
                    : ''
                }
            </B.Row>
        );
    }
}

export default IndexSermons;
