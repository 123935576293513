import React from 'react';
import * as B from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Alerts = props => {
    const {alerts, handleCloseClick} = props;

    return (
        <div className="alerts-wrapper">
            {alerts.map((alert, i) => {
                return (
                    <div key={i} className="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="toast-header">
                            <B.Badge pill variant={alert.type} className="mr-2">
                                <FontAwesomeIcon icon={alert.type === 'success' ? 'check' : 'times'}/>
                            </B.Badge>
                            <strong className="mr-auto">{alert.type === 'success' ? 'Известие' : 'Грешка'}</strong>
                            <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" onClick={() => handleCloseClick(alert)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body">
                            {alert.text}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Alerts;
