import React, {Component} from 'react';
import Alerts from "./Alerts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as B from 'react-bootstrap';
import {get, post, postFile} from "../../API";

export default class Galleries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            alerts: [],
            modalShow: {
                'media': false,
                'categories': false,
            },
            validated: false,
            formsData: {},
            categoryEditID: null,
            galleries: [],
            categories: [],
            previews: []
        };
        this.API_URL = '/api/galleries';
        this.GALLERY_FUNC = {
            'addMedia': 1,
            'editMedia': 2,
            'deleteMedia': 3,
            'addCategory': 4,
            'editCategory': 5,
            'deleteCategory': 6,
            'loadCategories': 7
        };
    }

    componentWillMount() {
        this.loadCategories();
    }

    modalOpen(id, categoryEditID = null) {
        const modalShow = {...this.state.modalShow};
        modalShow[id] = true;

        this.setState({modalShow, categoryEditID});
    }

    modalHide(id, reset) {
        const modalShow = {...this.state.modalShow};
        modalShow[id] = false;

        if (reset) {
            const previews = [];
            const formsData = {...this.state.formsData};
            formsData['mediaCategory'] = null;
            formsData['mediaFiles'] = null;
            formsData['categoryNew'] = null;
            this.setState({modalShow, validated: false, formsData, previews});
            return;
        }
        this.setState({modalShow});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};

        formsData[name] = value;

        this.setState({formsData});
    }

    handleFileInputChange(event, allowedFormats) {
        const target = event.target;
        const files = target.files;
        const name = target.name;
        const formsData = {...this.state.formsData};
        formsData[name] = formsData[name] || [];
        let previews = [...this.state.previews] || [];

        if (files.length > 20 || (formsData[name].length + files.length) > 20) {
            let alerts = [...this.state.alerts];
            alerts.push({type: 'danger', text: 'Максималният брой файлове, които можете да качите е 20!'});
            this.setState({alerts});
            return;
        }

        for (let file of files) {
            const reader = new FileReader();
            const type = file.type;
            let img = '';
            let title = '';

            if (!allowedFormats.includes(type)) {
                let alerts = [...this.state.alerts];
                alerts.push({type: 'danger', text: 'Неподдържан формат!'});
                this.setState({alerts});
                return;
            }

            formsData[name].push(file);
            reader.onload = ev => {
                img = <img className="img-thumbnail mb-2" src={ev.target.result} alt="IMG"/>;
                previews.push({img, title});
                this.setState({previews});
            };
            reader.readAsDataURL(file);
        }

        this.setState({formsData});
    }

    handleCloseAlert(alert) {
        const currentAlerts = [...this.state.alerts];
        const alerts = currentAlerts.filter(al => al !== alert);
        this.setState({alerts});
    }

    handleCategoryChange(event, id) {
        const target = event.target;
        const value = target.value;
        const categories = [...this.state.categories];
        const thisCategory = categories.filter(category => category['category_id'] === id);
        const index = thisCategory.length ? categories.indexOf(thisCategory[0]) : null;
        if (index !== null)
            categories[index]['category'] = value;

        this.setState({categories});
    }

    handlePreviewTitleChange(ev, preview) {
        const value = ev.target.value;
        const previews = [...this.state.previews];
        const thisPreview = previews.filter(pr => pr === preview);
        const index = thisPreview ? previews.indexOf(thisPreview[0]) : null;

        if (index !== null && index !== -1)
            previews[index].title = value;

        this.setState({previews});
    }

    handleMediaChange(ev, categoryID, mediaID) {
        const value = ev.target.value;
        const galleries = {...this.state.galleries};
        const thisMedia = galleries[categoryID].filter(m => m['gallery_id'] === mediaID);
        const index = thisMedia ? galleries[categoryID].indexOf(thisMedia[0]) : null;

        if (index !== null && index !== -1)
            galleries[categoryID][index].title = value;

        this.setState({galleries});
    }

    loadGalleries() {
        this.setState({loaded: false, error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    const galleries = {};
                    data.map(media => {
                        if (!galleries[media.category])
                            galleries[media.category] = [];

                        galleries[media.category].push(media);
                    });
                    this.setState({
                        galleries,
                        loaded: true
                    });
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadCategories() {
        const params = {};
        const data = {auth: {func: this.GALLERY_FUNC.loadCategories}, params};

        post(this.API_URL, data)
            .then(data => {
                if (data['response'] !== false) {
                    this.loadGalleries();

                    this.setState({
                        categories: data
                    });
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message'],
                        categories: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    getMediaData(galleryEditID) {
        this.setState({galleryEditID});

        get(`${this.API_URL}/${galleryEditID}`)
            .then(data => {
                if (data['response'] !== false) {

                } else {
                    const alerts = [...this.state.alerts];
                    alerts.push({type: 'danger', text: data['message']});
                    this.setState({
                        loaded: true,
                        alerts
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    getCategoryByID(id) {
        const {categories} = this.state;
        const category = categories.filter(cat => cat['category_id'] === id);

        return category.length ? category[0] : null;
    }

    addMedia(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {previews} = this.state;
            const {mediaCategory, mediaFiles} = this.state.formsData;
            const func = this.GALLERY_FUNC.addMedia;

            for (let i = 0; i < mediaFiles.length; i++) {
                const params = {
                    category: mediaCategory,
                    title: previews[i]['title']
                };
                const formData = new FormData();
                formData.append('auth', JSON.stringify({func}));
                formData.append('params', JSON.stringify(params));
                formData.append('media', mediaFiles[i]);

                postFile(this.API_URL, formData, true)
                    .then(data => {
                        if (data['response'] === true) {
                            const alerts = [...this.state.alerts];
                            alerts.push({type: 'success', text: data['message']});
                            this.setState({alerts});
                            this.loadGalleries();
                        } else {
                            const alerts = [...this.state.alerts];
                            alerts.push({type: 'danger', text: data['message']});
                            this.setState({
                                loaded: true,
                                alerts
                            });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.setState({
                            loaded: false,
                            error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                        });
                    });
            }

            this.setState({
                validated: false,
                loaded: true
            });
            this.modalHide('media', true);
        }
    }

    editMedia(gallery_id) {
        const {galleries, categoryEditID} = this.state;
        const thisMedia = galleries[categoryEditID].filter(gal => gal['gallery_id'] === gallery_id);

        if (thisMedia.length) {
            const params = {gallery_id, title: thisMedia[0].title, category: categoryEditID};
            const data = {auth: {func: this.GALLERY_FUNC.editMedia}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts,
                            validated: false
                        });
                    } else {
                        this.setState({
                            loaded: true,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    deleteMedia(gallery_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете тази снимка?')) {
            this.setState({loaded: false});
            const params = {gallery_id};
            const data = {auth: {func: this.GALLERY_FUNC.deleteMedia}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.loadGalleries();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    addCategory(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const category = this.state.formsData['categoryNew'];
            const params = {category};
            const data = {auth: {func: this.GALLERY_FUNC.addCategory}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const formsData = {...this.state.formsData};
                        const alerts = [...this.state.alerts];
                        formsData['categoryNew'] = null;
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts,
                            validated: false,
                            formsData
                        });

                        this.loadCategories();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    editCategory(category_id) {
        const {categories} = this.state;
        const thisCategory = categories.filter(category => category['category_id'] === category_id);

        if (thisCategory.length) {
            const params = {category_id, category: thisCategory[0].category};
            const data = {auth: {func: this.GALLERY_FUNC.editCategory}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts,
                            validated: false
                        });
                    } else {
                        this.setState({
                            loaded: true,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    deleteCategory(category_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете този албум?')) {
            this.setState({loaded: false});
            const params = {category_id};
            const data = {auth: {func: this.GALLERY_FUNC.deleteCategory}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.loadCategories();
                    } else {
                        this.setState({
                            loaded: true,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    drawCategories() {
        const {categories, galleries} = this.state;

        return(
            <B.Row>
                {categories.map(category => {
                    const categoryID = category['category_id'];
                    const isEmpty = galleries.hasOwnProperty(categoryID) && galleries[categoryID].length;
                    const img = isEmpty ?
                        <B.CardImg variant="top" src={galleries[categoryID][0]['media']} alt={galleries[categoryID][0]['title']} /> :
                        <B.CardImg variant="top" src="/images/default_album.png" alt="Празен албум"/>;
                    const catDate = category['created_at'].split('-');
                    const date = new Date(catDate[2], catDate[1], catDate[0]);

                    return (
                        <B.Col key={categoryID} xs={12} md={6} lg={4} xl={3}>
                            <B.Card>
                                {img}
                                <B.Card.Body>
                                    <B.Card.Title>{category.category}</B.Card.Title>
                                    <B.Card.Subtitle className="text-muted">{`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}</B.Card.Subtitle>
                                </B.Card.Body>
                                <B.Card.Footer className="text-center">
                                    <B.Button type="button" variant="light" onClick={() => this.modalOpen('album', categoryID)} disabled={!isEmpty}>
                                        <FontAwesomeIcon icon="eye" fixedWidth className="mr-2"/>
                                        Разгледай
                                    </B.Button>
                                </B.Card.Footer>
                            </B.Card>
                        </B.Col>
                    );
                })}
            </B.Row>
        );
    }

    drawModals() {
        const {formsData, validated, modalShow, categoryEditID, categories, previews, galleries} = this.state;
        let media = null;
        let counter = 1;
        const category = categoryEditID ? this.getCategoryByID(categoryEditID) : null;

        if (formsData['galleryMediaThumb']) {
            media = <img className="img-thumbnail" src={formsData['galleryMediaThumb']}
                         alt={formsData['mediaTitle'] || ''}/>;
        }

        return (
            <>
                {/* Add Media Modal */}
                <B.Modal
                    id='media'
                    centered
                    size="lg"
                    show={modalShow['media']}
                    onHide={() => this.modalHide('media', true)}
                    aria-labelledby="mediaModalTitle"
                    className="body-scroll"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="mediaModalTitle">
                            Добави снимки
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            noValidate
                            validated={validated}
                            onSubmit={e => this.addMedia(e)}
                            method="POST"
                            encType="multipart/form-data">
                            <B.Form.Group controlId="mediaCategory">
                                <B.Form.Label>Албум</B.Form.Label>
                                <B.Form.Control as="select" name="mediaCategory" className="custom-select"
                                                value={formsData['mediaCategory'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)} required>
                                    <option value=''>Моля, изберете</option>
                                    {categories.map(category => <option key={category['category_id']} value={category['category_id']}>{category.category}</option>)}
                                </B.Form.Control>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="mediaFiles">
                                <label>Снимки</label>
                                {media ? <div className="w-25 mb-2">{media}</div> : ''}
                                <div className="custom-file">
                                    <B.Form.Control type="file" name="mediaFiles" className="custom-file-input"
                                                    onChange={(ev) => this.handleFileInputChange(ev, ['image/jpeg', 'image/png', 'image/gif'])}
                                                    accept="image/jpeg, image/png, image/gif"
                                                    lang="bg"
                                                    multiple
                                                    required/>
                                    <B.Form.Label className="custom-file-label">
                                        {formsData['mediaFiles'] ?
                                            formsData['mediaFiles'].length > 1 ?
                                                `${formsData['mediaFiles'].length} снимки` :
                                                formsData['mediaFiles'][0]['name'] :
                                            <div>
                                                <span className="d-md-none">Снимки</span>
                                                <span className="d-none d-md-block">.jpeg, .jpg, .png, .gif</span>
                                            </div>}
                                    </B.Form.Label>
                                    <B.FormText className="text-muted d-md-none">.jpeg, .jpg, .png, .gif</B.FormText>
                                    <B.FormText className="text-muted">Можете да качите до 20 снимки наведнъж.</B.FormText>
                                    <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                                </div>
                            </B.Form.Group>
                            {previews && previews.length ?
                                <B.Form.Group>
                                    <B.Row>
                                        {previews.map(preview => {
                                            return (<B.Col xs={12} md={6} lg={4} xl={3} key={`preview-${counter++}`} className="d-flex flex-grow-1">
                                                <B.Form.Group className="text-center">
                                                    {preview.img}
                                                    <B.Form.Control type="text"
                                                                    value={preview.title || ''}
                                                                    onChange={(ev) => this.handlePreviewTitleChange(ev, preview)}
                                                                    placeholder="Заглавие"
                                                                    required/>
                                                    <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                                                </B.Form.Group>
                                            </B.Col>)
                                        })}
                                    </B.Row>
                                </B.Form.Group>
                                : ''}
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon='plus' fixedWidth className="mr-2"/>
                                    Добави снимки
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
                {/* Show album Modal */}
                <B.Modal
                    id='album'
                    centered
                    size="lg"
                    show={modalShow['album']}
                    onHide={() => this.modalHide('album', true)}
                    aria-labelledby="albumTitle"
                    className="body-scroll"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="albumTitle">
                            Преглед на {category ? category.category : 'албум'}
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Row>
                            {galleries.hasOwnProperty(categoryEditID) && galleries[categoryEditID].length ?
                                galleries[categoryEditID].map(pic => {
                                    const picID = pic['gallery_id'];

                                    return(
                                        <B.Col key={picID} xs={12} md={6} lg={4} className="text-center mb-3">
                                            <div className="position-relative mb-3">
                                                <img className="img-fluid" src={pic.media} alt={pic.title} />
                                                <B.Button type="button" size="sm" variant="danger" className="media-delete-btn" onClick={() => this.deleteMedia(picID)}>
                                                    <FontAwesomeIcon icon="times" fixedWidth/>
                                                </B.Button>
                                            </div>
                                            <B.Row noGutters>
                                                <B.Col xs={9} className="mr-2">
                                                    <B.Form.Control type="text" value={pic.title} onChange={ev => this.handleMediaChange(ev, categoryEditID, picID)}/>
                                                </B.Col>
                                                <B.Col xs={2}>
                                                    <B.Button type="button" variant="primary" onClick={() => this.editMedia(picID)}>
                                                        <FontAwesomeIcon icon="edit" fixedWidth/>
                                                    </B.Button>
                                                </B.Col>
                                            </B.Row>
                                        </B.Col>
                                    );
                                }) : ''
                            }
                        </B.Row>
                    </B.Modal.Body>
                </B.Modal>
                {/* Categories Modal */}
                <B.Modal id='categories'
                         centered
                         show={modalShow['categories']}
                         onHide={() => this.modalHide('categories', true)}
                         aria-labelledby="categoriesTitle"
                         className="body-scroll"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="categoriesTitle">Албуми</B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form onSubmit={ev => ev.preventDefault()}>
                            {categories.length === 0 ?
                                <B.Alert variant="info">Няма въведени албуми</B.Alert> : categories.map(category => {
                                    return (
                                        <B.Form.Group key={category['category_id']}>
                                            <B.Form.Row>
                                                <B.Col>
                                                    <B.Form.Control type="text" value={category.category || ''}
                                                                    onChange={ev => this.handleCategoryChange(ev, category['category_id'])}/>
                                                </B.Col>
                                                <B.Col>
                                                    <B.Button type="button" variant="primary" className="mr-2"
                                                              onClick={() => this.editCategory(category['category_id'])}>
                                                        <FontAwesomeIcon icon="edit" fixedWidth/>
                                                    </B.Button>
                                                    <B.Button type="button" variant="danger"
                                                              onClick={() => this.deleteCategory(category['category_id'])}>
                                                        <FontAwesomeIcon icon="trash-alt" fixedWidth/>
                                                    </B.Button>
                                                </B.Col>
                                            </B.Form.Row>
                                        </B.Form.Group>
                                    );
                                })}
                        </B.Form>
                    </B.Modal.Body>
                    <B.Modal.Footer>
                        <B.Form className="w-100"
                                onSubmit={ev => this.addCategory(ev)}
                                noValidate
                                validated={validated}>
                            <B.Form.Group>
                                <B.Form.Row>
                                    <B.Col>
                                        <B.Form.Control type="text" name="categoryNew"
                                                        value={formsData['categoryNew'] || ''}
                                                        onChange={ev => this.handleInputChange(ev)} required/>
                                        <B.Form.Control.Feedback type="invalid">Полето е
                                            задължително!</B.Form.Control.Feedback>
                                    </B.Col>
                                    <B.Col>
                                        <B.Button type="submit" variant="primary">
                                            <FontAwesomeIcon icon="plus" fixedWidth className="mr-2"/>
                                            Добави албум
                                        </B.Button>
                                    </B.Col>
                                </B.Form.Row>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Footer>
                </B.Modal>
            </>
        );
    }

    render() {
        const {loaded, error, alerts} = this.state;

        if (error) {
            return (
                <GalleriesLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                                 modalShow={(id) => this.modalOpen(id)}>
                    <B.Alert variant='danger'>
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                    {this.drawModals()}
                </GalleriesLayout>
            );
        } else if (!loaded) {
            return (
                <GalleriesLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                                 modalShow={(id) => this.modalOpen(id)}>
                    <B.ProgressBar animated now={100} label="Loading..."/>
                    {this.drawModals()}
                </GalleriesLayout>
            );
        } else {
            return (
                <GalleriesLayout alerts={alerts}
                                 handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                                 modalShow={(id) => this.modalOpen(id)}>
                    {this.drawCategories()}
                    {this.drawModals()}
                </GalleriesLayout>
            );
        }
    }
}

const GalleriesLayout = props => {
    const {children, alerts, handleCloseClick, modalShow} = props;
    return (
        <>
            {alerts.length ?
                <Alerts alerts={alerts} handleCloseClick={(alert) => handleCloseClick(alert)}/>
                : ''}
            <div className="page-header mb-3">
                <h2 className="d-inline-block">Снимки</h2>

                <div className="d-inline-block float-right">
                    <B.Button className="btn btn-light mr-2" onClick={() => modalShow('categories')}>
                        <FontAwesomeIcon icon='images' className='mr-2' fixedWidth/>
                        Албуми
                    </B.Button>
                    <B.Button className="btn btn-light" onClick={() => modalShow('media')}>
                        <FontAwesomeIcon icon='plus' className='mr-2 text-success' fixedWidth/>
                        Добави снимки
                    </B.Button>
                </div>
            </div>
            {children}
        </>
    );
};
