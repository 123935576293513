import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import * as B from 'react-bootstrap';
import {get} from "../API";

class IndexNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            error: null
        };

        this.API_URL = '/api/news';
        this.DEFAULT_NEWS_IMG = 'images/default_news-admin.png';
    }

    componentWillMount() {
        this.loadNews();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language)
            this.loadNews();
    }

    loadNews() {
        this.setState({error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    const {language} = this.props;
                    const newsData = data['news'].filter(news => news.lang_id === language);

                    this.setState({
                        news: newsData.slice(0, 3)
                    });
                } else {
                    this.setState({
                        error: data['message'],
                        news: []
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    render() {
        const {news, error} = this.state;

        if (error)
            return <B.Alert variant="info">{error}</B.Alert>;

        if (news.length) {
            return (
                <B.Row className="news-wrapper">
                    {news.map(news => {
                        const newsDate = news['created_at'].split(' ')[0].split('-');
                        const date = new Date(newsDate[2], newsDate[1], newsDate[0]);

                        return (
                            <B.Col key={news['news_id']} xs={12} md={6} lg={4} className="news-col mb-3">
                                <B.Card className="news">
                                    <Link to={`/news/${news['news_id']}`}>
                                        <B.CardImg src={news.media === '/storage/' ? this.DEFAULT_NEWS_IMG : news.media} alt={news.title}/>
                                        <B.Card.ImgOverlay>
                                            <B.Card.Title>{news.title}</B.Card.Title>
                                            <B.Card.Text
                                                className="news-date">{`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}</B.Card.Text>
                                        </B.Card.ImgOverlay>
                                    </Link>
                                </B.Card>
                            </B.Col>
                        );
                    })}
                </B.Row>
            );
        }

        return null;
    }
}

export default IndexNews;
