import React, {Component} from 'react';
import * as B from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//Import Layout
import PageTitle from "./layout/PageTitle";

import GoogleMap from './GoogleMap';
import {lang} from "../lang/all";
import ContactForm from "./ContactForm";

class Contact extends Component {
    componentDidMount() {
        const {isLoaded} = this.props;
        isLoaded(true);
    }

    render() {
        const {language, recaptcha} = this.props;

        return (
            <main id="app" role="main" className="contacts">
                <PageTitle title={lang[language].contactTitle}>
                    <B.Row>
                        <B.Col xs={12} md={4} className="text-center">
                            <div className="fa-4x mb-2">
                                        <span className="fa-layers fa-fw">
                                          <FontAwesomeIcon icon="circle"/>
                                          <FontAwesomeIcon icon="map-marker-alt" transform="shrink-6"
                                                           className="text-primary"/>
                                        </span>
                            </div>
                            <h4>{lang[language].contactAddressTitle}</h4>
                            <p>{lang[language].contactAddress}</p>
                        </B.Col>
                        <B.Col xs={12} md={4} className="text-center">
                            <div className="fa-4x mb-2">
                                        <span className="fa-layers fa-fw">
                                          <FontAwesomeIcon icon="circle"/>
                                          <FontAwesomeIcon icon="phone" transform="shrink-6" className="text-primary"/>
                                        </span>
                            </div>
                            <h4>{lang[language].contactPhoneTitle}</h4>
                            <a href="tel:+35929310682" className="text-white">{lang[language].contactPhone}</a>
                        </B.Col>
                        <B.Col xs={12} md={4} className="text-center">
                            <div className="fa-4x mb-2">
                                        <span className="fa-layers fa-fw">
                                          <FontAwesomeIcon icon="circle"/>
                                          <FontAwesomeIcon icon="envelope" transform="shrink-6"
                                                           className="text-primary"/>
                                        </span>
                            </div>
                            <h4>EMAIL</h4>
                            <a href="mailto:office@sebc.bg" className="text-white">office@sebc.bg</a>
                        </B.Col>
                    </B.Row>
                </PageTitle>
                <B.Container as="section" className="contact-form-wrapper py-5 flex-grow-1">
                    <B.Row>
                        <B.Col xs={12} md={6} className="mb-4 mb-md-0">
                            <h3 className="font-weight-light mb-4">{lang[language].contactWriteUsTitle}</h3>
                            <p className="mb-1">{lang[language].contactWriteUsSubTitle}</p>
                            <dl className="pastors ml-3 mb-3">
                                <dt className="pastor">
                                    {lang[language].pastorTeddy}
                                </dt>
                                <dd><a href="mailto:t.oprenov@sebc.bg">t.oprenov@sebc.bg</a></dd>
                                <dt className="pastor">
                                    {lang[language].pastorDidi}
                                </dt>
                                <dd><a href="mailto:d.oprenova@sebc.bg">d.oprenova@sebc.bg</a></dd>
                                <dt className="pastor">
                                    {lang[language].pastorVlady}
                                </dt>
                                <dd><a href="mailto:raichinov@sebc.bg">raichinov@sebc.bg</a></dd>
                            </dl>
                            <p className="mb-1">{lang[language].followUs}</p>
                            <a href="https://www.facebook.com/sebc.bg" target="_blank" className="ml-3">
                                <FontAwesomeIcon icon={['fab', 'facebook-square']} size="2x" fixedWidth/>
                            </a>
                        </B.Col>
                        <B.Col xs={12} md={6}>
                            <ContactForm language={language} recaptcha={recaptcha}/>
                        </B.Col>
                    </B.Row>
                </B.Container>
                <section className="contact-map-wrapper">
                    <GoogleMap/>
                </section>
            </main>
        );
    }
}

export default Contact;
