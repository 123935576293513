import React, {Component} from 'react';

import ReactQuill, {Quill} from 'react-quill';
import ImageUploader from "quill-image-uploader";
import 'react-quill/dist/quill.snow.css';

import {get, postFile} from "../../API";

Quill.register("modules/imageUploader", ImageUploader);

class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ''
        };
        this.quillRef = null;      // Quill instance
        this.reactQuillRef = null; // ReactQuill component
        this.modules = {
            toolbar: [
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                ['bold', 'italic', 'underline', 'strike'],
                [{'color': []}, {'background': []}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'align': []}, {'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image'],
                ['clean']
            ],
            imageUploader: {
                upload: file => {
                    return this.uploadImage(file);
                }
            }
        };
        this.formats = [
            'header', 'bold', 'italic', 'underline', 'strike', 'color', 'background', 'script', 'align', 'list', 'bullet', 'indent', 'link', 'image'
        ];
        this.STORAGE_API_URL = '/api/storage';
    }

    componentWillMount() {
        const {defaultValue} = this.props;
        const {text} = this.state;

        if (defaultValue) {
            if (text !== defaultValue)
                this.setState({text: defaultValue});
        }
    }

    componentDidMount() {
        this.attachQuillRefs();
    }

    componentDidUpdate() {
        this.attachQuillRefs();
    }

    attachQuillRefs() {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;

        this.quillRef = this.reactQuillRef.getEditor();
    }

    handleChange(text) {
        const {getContent} = this.props;

        this.setState({text});

        const editor = this.reactQuillRef && this.reactQuillRef.getEditor();
        const unprivilegedEditor = this.reactQuillRef && this.reactQuillRef.makeUnprivilegedEditor(editor);

        if (unprivilegedEditor)
            getContent(unprivilegedEditor.getHTML());
    }

    uploadImage(image) {
        const params = {name: image.name, media_type: image.type};
        const formData = new FormData();
        formData.append('auth', JSON.stringify({func: 1}));
        formData.append('params', JSON.stringify(params));
        formData.append('media', image);

        return postFile(this.STORAGE_API_URL, formData, true)
            .then(data => {
                if (data['response'] === true) {
                    return this.getImageAddr(data['record_id']);
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    getImageAddr(id) {
        return get(`${this.STORAGE_API_URL}/${id}`)
            .then(data => {
                if (data['response'] !== false) {
                    return data['media'];
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    render() {
        const {readOnly} = this.props;
        const {text} = this.state;

        return (
            <ReactQuill ref={(el) => {this.reactQuillRef = el}}
                        value={text} modules={this.modules} formats={this.formats}
                        readOnly={readOnly}
                        onChange={value => this.handleChange(value)}/>
        );
    }
}

export default TextEditor;
