import React, {Component} from 'react';
import {get, post} from '../../API';
import * as B from 'react-bootstrap';
import Alerts from "./Alerts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {arrayMove} from 'react-sortable-hoc';

export default class Menus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            alerts: [],
            modalShow: false,
            validated: false,
            formsData: {},
            menus: [],
            subMenus: [],
            menusOrder: [],
            subMenusOrder: [],
            pages: []
        };
        this.API_URL = '/api/menu';
        this.PAGE_API_URL = '/api/page';
        this.MENU_FUNC = {
            'addMenu': 1,
            'deleteMenu': 2,
            'addChild': 3,
            'deleteChild': 4,
            'changeOrder': 5,
            'changeChildrenOrder': 6
        };
    }

    componentWillMount() {
        this.loadMenus();
        this.loadPages();
    }

    modalOpen() {
        this.setState({modalShow: true});
    }

    modalHide(reset) {
        if (reset) {
            const formsData = {...this.state.formsData};
            formsData['menuPage'] = null;
            formsData['menuParent'] = null;
            formsData['menuLanguage'] = null;
            this.setState({modalShow: false, validated: false, formsData});
            return;
        }
        this.setState({modalShow: false});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};
        formsData[name] = value;

        this.setState({formsData});
    }

    handleCloseAlert(alert) {
        const currentAlerts = [...this.state.alerts];
        const alerts = currentAlerts.filter(al => al !== alert);
        this.setState({alerts});
    }

    handleAddMenu(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            this.setState({loaded: false});
            const {menuPage, menuParent, menuLanguage} = this.state.formsData;
            const func = menuParent ? this.MENU_FUNC.addChild : this.MENU_FUNC.addMenu;
            const params = {page_id: menuPage, child_to_page: menuParent, lang_id: menuLanguage};
            const data = {auth: {func}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide(true);

                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts,
                            validated: false
                        });

                        this.loadMenus();
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleDeleteMenu(id, type) {
        if (confirm('Сигурни ли сте, че искате да изтриете това меню?')) {
            this.setState({loaded: false});
            const func = type === 'main' ? this.MENU_FUNC.deleteMenu : this.MENU_FUNC.deleteChild;
            const params = type === 'main' ? {page_id: id} : {page_secondary_id: id};

            const data = {auth: {func}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.loadMenus();
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleSortUp(ev, oldIndex, collection, parentID) {
        ev.preventDefault();
        const newIndex = oldIndex - 1 < 0 ? 0 : oldIndex - 1;
        const oldOrder = parentID ? [...this.state[collection][parentID]] : [...this.state[collection]];
        const reorder = arrayMove(oldOrder, oldIndex, newIndex);
        const subOrder = {...this.state[collection]};
        if (parentID)
            subOrder[parentID] = reorder;

        const newOrder = parentID ? subOrder : reorder;

        this.setState({[collection]: newOrder});
        this.changeOrder(newOrder, parentID);
    }

    handleSortDown(ev, oldIndex, collection, parentID) {
        ev.preventDefault();
        const newIndex = parseInt(oldIndex) + 1;
        const oldOrder = parentID ? [...this.state[collection][parentID]] : [...this.state[collection]];
        const reorder = [...arrayMove(oldOrder, oldIndex, newIndex)];
        const subOrder = {...this.state[collection]};
        if (parentID)
            subOrder[parentID] = reorder;

        const newOrder = parentID ? subOrder : reorder;

        this.setState({[collection]: newOrder});
        this.changeOrder(newOrder, parentID);
    }

    loadMenus() {
        this.setState({loaded: false, error: null});

        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    const menus = {};
                    const subMenus = {};
                    const menusOrder = [];
                    const subMenusOrder = {};

                    if (data['data']) {
                        data['data'].map(menu => {
                            if (!menus.hasOwnProperty(menu.lang_id))
                                menus[menu.lang_id] = {};

                            menus[menu.lang_id][menu.id] = menu;
                            menusOrder.push(menu.id);

                            if (data['data_content'] && data['data_content'].hasOwnProperty(menu.id) && data['data_content'][menu.id]['response'] !== false) {
                                subMenus[menu.id] = {};
                                subMenusOrder[menu.id] = [];
                                data['data_content'][menu.id].map(subMenu => {
                                    subMenus[menu.id][subMenu.id] = subMenu;
                                    subMenusOrder[menu.id].push(subMenu.id);
                                });
                            }
                        });
                    }

                    this.setState({menus, subMenus, menusOrder, subMenusOrder, loaded: true});
                } else {
                    this.setState({
                        loaded: false,
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadPages() {
        get(this.PAGE_API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    let pages = [];
                    //Only active pages
                    const filteredData = data.filter(page => page.status !== 0);
                    filteredData.sort((a, b) => a.page.localeCompare(b.page));
                    filteredData.map(page => {
                        pages.push({name: page.page, id: page.id});
                    });
                    this.setState({pages});
                } else {
                    this.setState({
                        loaded: false,
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    changeOrder(newOrder, parentID) {
        const orderArr = parentID ? newOrder[parentID] : newOrder;
        let menus = [];
        orderArr.map((menu_id, order) => {
            menus.push({menu_id, order});
        });
        const func = parentID ? this.MENU_FUNC.changeChildrenOrder : this.MENU_FUNC.changeOrder;
        const params = {menus};
        const data = {auth: {func}, params};

        post(this.API_URL, data, true)
            .then(data => {
                if (data['response'] === false) {
                    const alerts = [...this.state.alerts];
                    alerts.push({type: 'danger', text: data['message']});
                    this.setState({
                        alerts
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    drawMenus(langID, lang) {
        const {menus, subMenus, menusOrder, subMenusOrder} = this.state;
        let result = [];

        if (menus.hasOwnProperty(langID)) {
            result.push(
                <B.Col key={langID} xs={12} md={6}>
                    <h4 key={lang}>{lang}</h4>
                    {menusOrder.map((mID, mOrder) => {
                        const menu = menus[langID][mID];
                        const hasChildren = menu ? subMenus.hasOwnProperty(menu.id) : false;

                        if (!menu)
                            return null;

                        return (<B.ListGroupItem as="li" key={`menu-${menu.id}`}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="menu-item">
                                    <div className="page">{menu['page_name']}</div>
                                    <small className="address text-muted">Адрес: {menu['page_addr']}</small>
                                </div>
                                <div className="actions d-flex align-items-center">
                                    <div className="sort d-inline-flex flex-column">
                                        <a href="#"
                                           onClick={(ev) => this.handleSortUp(ev, mOrder, 'menusOrder')}
                                           title="Премести нагоре"
                                           style={mOrder < 1 ? {cursor: 'not-allowed'} : {}}>
                                            <FontAwesomeIcon icon="caret-up" fixedWidth
                                                             className={mOrder < 1 ? 'text-light' : ''}/>
                                        </a>
                                        <a href="#"
                                           onClick={(ev) => this.handleSortDown(ev, mOrder, 'menusOrder')}
                                           title="Премести надолу"
                                           style={mOrder === menusOrder.length - 1 ? {cursor: 'not-allowed'} : {}}>
                                            <FontAwesomeIcon icon="caret-down" fixedWidth
                                                             className={mOrder === menusOrder.length - 1 ? 'text-light' : ''}/>
                                        </a>
                                    </div>
                                    <B.Button variant="link"
                                              onClick={() => this.handleDeleteMenu(menu.id, 'main')}>
                                        <FontAwesomeIcon icon="trash-alt" className="text-danger" fixedWidth/>
                                    </B.Button>
                                </div>
                            </div>
                            {hasChildren ?
                                <B.ListGroup as="ul" className="subMenu mx-3 mt-2">
                                    {subMenusOrder[menu.id].map((smID, smOrder) => {
                                        const subMenu = subMenus[menu.id][smID];
                                        return (<B.ListGroupItem as="li" key={`subMenu-${subMenu.id}`} className="py-1">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="menu-item">
                                                    <div className="page">{subMenu['page_name']}</div>
                                                    <small
                                                        className="address text-muted">Адрес: {subMenu['page_addr']}</small>
                                                </div>
                                                <div className="actions d-flex align-items-center">
                                                    <div className="sort d-inline-flex flex-column">
                                                        <a href="#"
                                                           onClick={(ev) => this.handleSortUp(ev, smOrder, 'subMenusOrder', menu.id)}
                                                           title="Премести нагоре"
                                                           style={smOrder < 1 ? {cursor: 'not-allowed'} : {}}>
                                                            <FontAwesomeIcon icon="caret-up" fixedWidth
                                                                             className={smOrder < 1 ? 'text-light' : ''}/>
                                                        </a>
                                                        <a href="#"
                                                           onClick={(ev) => this.handleSortDown(ev, smOrder, 'subMenusOrder', menu.id)}
                                                           title="Премести надолу"
                                                           style={smOrder === subMenusOrder[menu.id].length - 1 ? {cursor: 'not-allowed'} : {}}>
                                                            <FontAwesomeIcon icon="caret-down" fixedWidth
                                                                             className={smOrder === subMenusOrder[menu.id].length - 1 ? 'text-light' : ''}/>
                                                        </a>
                                                    </div>
                                                    <B.Button variant="link"
                                                              onClick={() => this.handleDeleteMenu(subMenu.id, 'sub')}>
                                                        <FontAwesomeIcon icon="trash-alt" className="text-danger"
                                                                         fixedWidth/>
                                                    </B.Button>
                                                </div>
                                            </div>
                                        </B.ListGroupItem>)
                                    })}
                                </B.ListGroup>
                                : ''}
                        </B.ListGroupItem>)
                    })}
                </B.Col>
            );
        }

        return result;
    }

    drawModals() {
        const {pages, validated} = this.state;
        const {languages} = this.props;

        return (<>
            <B.Modal
                centered
                show={this.state.modalShow}
                onHide={() => this.modalHide()}
                aria-labelledby="addMenuTitle"
            >
                <B.Modal.Header closeButton>
                    <B.Modal.Title id="addMenuTitle">
                        Добави меню
                    </B.Modal.Title>
                </B.Modal.Header>
                <B.Modal.Body>
                    <B.Form
                        noValidate
                        validated={validated}
                        onSubmit={e => this.handleAddMenu(e)}>
                        <B.Form.Group controlId="menuPage">
                            <B.Form.Label>Страница</B.Form.Label>
                            <B.Form.Control as="select" name="menuPage" className="custom-select"
                                            onChange={(ev) => this.handleInputChange(ev)} required>
                                <option value=''>Моля, изберете</option>
                                {pages.map(page => <option key={page.id} value={page.id}>{page.name}</option>)}
                            </B.Form.Control>
                            <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                        </B.Form.Group>
                        <B.Form.Group controlId="menuParent">
                            <B.Form.Label>Родител</B.Form.Label>
                            <B.Form.Control as="select" name="menuParent" className="custom-select"
                                            onChange={(ev) => this.handleInputChange(ev)}>
                                <option value=''>Моля, изберете</option>
                                {pages.map(page => <option key={page.id} value={page.id}>{page.name}</option>)}
                            </B.Form.Control>
                            <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                        </B.Form.Group>
                        <B.Form.Group controlId='menuLanguage'>
                            <B.Form.Label>Език</B.Form.Label>
                            <B.Form.Control as="select" name="menuLanguage"
                                            onChange={(ev) => this.handleInputChange(ev)}
                                            className="custom-select" required>
                                <option value="">Избери език</option>
                                {languages.map(({id, lang}) => (
                                    <option key={id} value={id}>{lang}</option>
                                ))}
                            </B.Form.Control>
                            <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                        </B.Form.Group>
                        <B.Form.Group className="text-center">
                            <B.Button type="submit" variant="primary">
                                <FontAwesomeIcon icon="plus" fixedWidth className="mr-2"/>
                                Добави меню
                            </B.Button>
                        </B.Form.Group>
                    </B.Form>
                </B.Modal.Body>
            </B.Modal>
        </>)
    }


    render() {
        const {loaded, error, alerts} = this.state;
        const {languages} = this.props;

        if (error) {
            return (
                <MenusLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                             modalShow={() => this.modalOpen()}>
                    <B.Alert variant='danger'>
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                    {this.drawModals()}
                </MenusLayout>
            );
        } else if (!loaded) {
            return (
                <MenusLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                             modalShow={() => this.modalOpen()}>
                    <B.ProgressBar animated now={100} label="Loading..."/>
                    {this.drawModals()}
                </MenusLayout>
            );
        } else {
            return (
                <MenusLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                             modalShow={() => this.modalOpen()}>
                    <B.Row>
                        {languages.map(({id, lang}) => (
                            this.drawMenus(id, lang)
                        ))}
                    </B.Row>
                    {this.drawModals()}
                </MenusLayout>
            );
        }
    }
}

const MenusLayout = props => {
    const {children, alerts, handleCloseClick, modalShow} = props;
    return (
        <>
            {alerts.length ?
                <Alerts alerts={alerts} handleCloseClick={(alert) => handleCloseClick(alert)}/>
                : ''}
            <div className="page-header mb-3">
                <h2 className="d-inline-block">Менюта</h2>

                <B.Button className="btn btn-light float-right" onClick={() => modalShow()}>
                    <FontAwesomeIcon icon='plus' className='mr-2 text-success' fixedWidth/>
                    Добави меню
                </B.Button>
            </div>
            {children}
        </>
    );
};
