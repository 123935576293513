import React, {Component} from 'react';
import {ChromePicker} from "react-color";

class ColorPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: false,
            color: props.color ?
                props.color :
                {
                    r: '255',
                    g: '255',
                    b: '255',
                    a: '1'
                },
        };
    }

    handleColorChange(color) {
        const {handleChange} = this.props;
        const colorObj = color.rgb;

        this.setState({color: colorObj});
        return handleChange(`rgba(${colorObj.r},${colorObj.g},${colorObj.b},${colorObj.a})`);
    }

    handleColorClick() {
        this.setState(prevState => ({displayColorPicker: !prevState.displayColorPicker}))
    }

    handleColorClose() {
        this.setState({displayColorPicker: false})
    }

    render() {
        const {color} = this.state;

        return (
            <div className="color-picker-wrapper">
                <div className="color-swatch" onClick={() => this.handleColorClick()}>
                    <div className="color-picker" style={{background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`}}/>
                </div>
                {this.state.displayColorPicker ?
                    <div className="color-popover">
                        <div className="color-cover" onClick={() => this.handleColorClose()}/>
                        <ChromePicker color={color} onChangeComplete={(color) => this.handleColorChange(color, 'sectionColor')}/>
                    </div>
                : null}
            </div>
        );
    }
}

export default ColorPicker;
