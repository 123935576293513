import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {get, post, postFile} from "../../API";
import * as B from 'react-bootstrap';
import Alerts from "./Alerts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {arrayMove} from "react-sortable-hoc";

export default class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: null,
            alerts: [],
            modalShow: {'sliders': false, 'slides': false},
            validated: false,
            formsData: {},
            sliders: [],
            slides: {},
            slidesOrder: [],
            pages: [],
            pageID: props.match.params.id,
            sliderEditID: null,
            slideEditID: null
        };
        this.API_URL = '/api/slider';
        this.PAGE_API_URL = '/api/page';
        this.SLIDER_FUNC = {
            'addSlider': 1,
            'editSlider': 2,
            'deleteSlider': 3,
            'addSlide': 4,
            'editSlide': 5,
            'deleteSlide': 6,
            'changeSlidesOrder': 7
        };
    }

    componentWillMount() {
        const {pageID} = this.state;

        //If the param is not valid redirect
        if (pageID && isNaN(pageID)) {
            this.props.history.push('/admin/slider');
        }

        if (!isNaN(pageID))
            this.loadSliderData(pageID);

        this.loadSliders();
        this.loadPages();
    }

    componentDidUpdate(prevProps) {
        const oldMatchName = prevProps.match.params.id;
        const pageID = this.props.match.params.id;

        if (oldMatchName !== pageID) {
            this.setState({pageID});

            if (!isNaN(pageID))
                this.loadSliderData(pageID);
        }
    }

    modalOpen(id, slideEditID = null) {
        const modalShow = {...this.state.modalShow};
        modalShow[id] = true;

        this.setState({modalShow, slideEditID});
    }

    modalHide(id, reset) {
        const modalShow = {...this.state.modalShow};
        modalShow[id] = false;

        if (reset) {
            const formsData = {...this.state.formsData};
            formsData['sliderName'] = null;
            formsData['sliderPage'] = null;
            formsData['sliderLanguage'] = null;
            formsData['slideMediaThumb'] = null;
            formsData['slideMedia'] = null;
            formsData['slideTitle'] = null;
            formsData['slideSubTitle'] = null;
            formsData['slideButtonAddress'] = null;
            formsData['slideButton'] = null;
            this.setState({modalShow, validated: false, formsData});
            return;
        }
        this.setState({modalShow});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const formsData = {...this.state.formsData};
        formsData[name] = value;

        this.setState({formsData});
    }

    handleFileInputChange(event, allowedFormats) {
        const target = event.target;
        const value = target.files[0];
        const name = target.name;
        const type = value.type;

        if (!allowedFormats.includes(type)) {
            let alerts = [...this.state.alerts];
            alerts.push({type: 'danger', text: 'Неподдържан формат!'});
            this.setState({alerts});
            return;
        }

        const formsData = {...this.state.formsData};
        formsData[name] = value;
        formsData['mediaType'] = type;

        this.setState({formsData});
    }

    handleCloseAlert(alert) {
        const currentAlerts = [...this.state.alerts];
        const alerts = currentAlerts.filter(al => al !== alert);
        this.setState({alerts});
    }

    handleMainButton() {
        const {pageID} = this.state;

        if (pageID) {
            this.props.history.push('/admin/slider');
            return;
        }

        this.modalOpen('sliders');
    }

    loadSliders() {
        this.setState({loaded: false, error: null});
        get(this.API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    data.map(slider => {
                        this.loadSliderData(slider.page);
                    });
                    this.setState({sliders: data});
                } else {
                    this.setState({
                        loaded: true,
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadSliderData(pageID) {
        get(`${this.API_URL}/${pageID}`)
            .then(data => {
                if (data['response'] !== false) {
                    if (data['data']) {
                        const slides = {...this.state.slides};
                        const {formsData} = {...this.state};
                        const slidesOrder = [...this.state.slidesOrder];
                        const sliderEditID = data['data']['id'];
                        formsData['editSliderName'] = data['data']['name'];
                        formsData['editSliderPage'] = data['data']['page'];
                        formsData['editSliderLanguage'] = data['data']['lang_id'];
                        slides[pageID] = null;

                        if (data['data_content'] && data['data_content'].hasOwnProperty(pageID) && data['data_content'][pageID]['response'] !== false) {
                            slides[pageID] = {};
                            slidesOrder[pageID] = [];
                            data['data_content'][pageID].map(slide => {
                                slides[pageID][slide['slide_id']] = slide;
                                slidesOrder[pageID].push(slide['slide_id']);
                            });
                        }

                        this.setState({loaded: true, slides, slidesOrder, formsData, sliderEditID});
                    }
                } else {
                    this.setState({
                        loaded: false,
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    loadPages() {
        get(this.PAGE_API_URL)
            .then(data => {
                if (data['response'] !== false) {
                    let pages = [];
                    //Only active pages
                    const filteredData = data.filter(page => page.status !== 0);
                    filteredData.map(page => {
                        pages.push({name: page.page, id: page.id});
                    });
                    this.setState({pages});
                } else {
                    this.setState({
                        loaded: false,
                        error: data['message']
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    getPageByID(id) {
        const {pages} = this.state;
        if (pages.length === 0)
            return null;

        return pages.filter(page => page.id === id)[0];
    }

    getSlideByID(id) {
        const {pageID, slides} = this.state;
        const formsData = {...this.state.formsData};
        const slide = slides[pageID][id];

        formsData['slideMediaThumb'] = slide['slide_addr'];
        formsData['mediaType'] = slide['media_type'];
        formsData['slideTitle'] = slide['title'];
        formsData['slideSubTitle'] = slide['sub_title'];
        formsData['slideButtonAddress'] = slide['button_addr'];
        formsData['slideButton'] = slide['button'];

        this.setState({formsData});
        this.modalOpen('slides', id);
    }

    handleAddSlider(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {sliderEditID} = this.state;
            const {sliderName, sliderPage, sliderLanguage} = this.state.formsData;
            const params = {name: sliderName, page: sliderPage, slider_id: sliderEditID, lang_id: sliderLanguage};
            const data = {auth: {func: this.SLIDER_FUNC.addSlider}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide('sliders', true);

                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts,
                            validated: false
                        });

                        this.loadSliders();
                    } else {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'danger', text: data['message']});

                        this.setState({
                            loaded: true,
                            alerts
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleEditSlider(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {sliderEditID} = this.state;
            const {editSliderName, editSliderPage, editSliderLanguage} = this.state.formsData;
            const params = {name: editSliderName, page: editSliderPage, slider_id: sliderEditID, lang_id: editSliderLanguage};
            const data = {auth: {func: this.SLIDER_FUNC.editSlider}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts,
                            validated: false
                        });

                        this.loadSliders();
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleDeleteSlider(slider_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете този слайдер?')) {
            this.setState({loaded: false});
            const params = {slider_id};

            const data = {auth: {func: this.SLIDER_FUNC.deleteSlider}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.loadSliders();
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleAddEditSlide(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.currentTarget;

        this.setState({validated: true});

        if (form.checkValidity()) {
            const {slideMedia, slideTitle, slideSubTitle, slideButton, slideButtonAddress, mediaType} = this.state.formsData;
            const {pageID, sliderEditID, slideEditID} = this.state;
            const func = slideEditID ? this.SLIDER_FUNC.editSlide : this.SLIDER_FUNC.addSlide;
            const params = {
                slide_id: slideEditID,
                slider_id: sliderEditID,
                title: slideTitle,
                sub_title: slideSubTitle,
                media_type: mediaType,
                button: slideButton,
                button_addr: slideButtonAddress
            };
            const formData = new FormData();
            formData.append('auth', JSON.stringify({func}));
            formData.append('params', JSON.stringify(params));
            if (slideMedia)
                formData.append('media', slideMedia);

            postFile(this.API_URL, formData, true)
                .then(data => {
                    if (data['response'] === true) {
                        this.modalHide('slides', true);

                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            loaded: true,
                            alerts,
                            validated: false
                        });

                        this.loadSliderData(pageID);
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleDeleteSlide(slide_id) {
        if (confirm('Сигурни ли сте, че искате да изтриете този слайд?')) {
            this.setState({loaded: false});
            const params = {slide_id};

            const data = {auth: {func: this.SLIDER_FUNC.deleteSlide}, params};

            post(this.API_URL, data, true)
                .then(data => {
                    if (data['response'] === true) {
                        const {pageID} = this.state;
                        const alerts = [...this.state.alerts];
                        alerts.push({type: 'success', text: data['message']});
                        this.setState({
                            alerts
                        });
                        this.loadSliderData(pageID);
                    } else {
                        this.setState({
                            loaded: false,
                            error: data['message']
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        loaded: false,
                        error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                    });
                });
        }
    }

    handleSortUp(oldIndex) {
        const {pageID} = this.state;
        const newIndex = oldIndex - 1 < 0 ? 0 : oldIndex - 1;
        const oldOrder = [...this.state.slidesOrder[pageID]];
        const reorder = arrayMove(oldOrder, oldIndex, newIndex);
        const slidesOrder = {...this.state.slidesOrder};
        slidesOrder[pageID] = reorder;

        this.setState({slidesOrder});
        this.changeOrder(slidesOrder, pageID);
    }

    handleSortDown(oldIndex) {
        const {pageID} = this.state;
        const newIndex = parseInt(oldIndex) + 1;
        const oldOrder = [...this.state.slidesOrder[pageID]];
        const reorder = arrayMove(oldOrder, oldIndex, newIndex);
        const slidesOrder = {...this.state.slidesOrder};
        slidesOrder[pageID] = reorder;

        this.setState({slidesOrder});
        this.changeOrder(slidesOrder, pageID);
    }

    changeOrder(newOrder) {
        const {pageID} = this.state;
        const orderArr = newOrder[pageID];
        let slides = [];
        orderArr.map((slide_id, order) => {
            slides.push({slide_id, order});
        });
        const params = {slides};
        const data = {auth: {func: this.SLIDER_FUNC.changeSlidesOrder}, params};

        post(this.API_URL, data, true)
            .then(data => {
                if (data['response'] === false) {
                    const alerts = [...this.state.alerts];
                    alerts.push({type: 'danger', text: data['message']});
                    this.setState({
                        alerts
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    drawSliders() {
        const {sliders, slides} = this.state;

        if (sliders.length) {
            return (
                <B.Table striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Име</th>
                        <th>Към страница</th>
                        <th>Език</th>
                        <th>Слайдове</th>
                        <th>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sliders.map(slider => {
                        const page = this.getPageByID(slider.page);
                        const slidesCount = slides.hasOwnProperty(slider.page) && slides[slider.page] ? Object.keys(slides[slider.page]).length : 0;
                        const editBtn = <Link to={`/admin/slider/${slider.page}`} type="button" variant="link"
                                              title="Редакция"><FontAwesomeIcon
                            icon="edit" fixedWidth/></Link>;
                        const deleteBtn = <B.Button type="button" variant="link" title="Изтрий"
                                                    onClick={() => this.handleDeleteSlider(slider.id)}><FontAwesomeIcon
                            icon="trash-alt" fixedWidth className="text-danger"/></B.Button>;
                        return (<tr key={slider.id}>
                            <td>{slider.id}</td>
                            <td>{slider.name}</td>
                            <td>{page ? page.name : ''}</td>
                            <td>{slider.lang}</td>
                            <td>{slidesCount}</td>
                            <td>{editBtn}{deleteBtn}</td>
                        </tr>)
                    })}
                    </tbody>
                </B.Table>
            );
        }
    }

    drawForm() {
        const {pageID, slides, pages, formsData, validated} = this.state;
        const {languages} = this.props;

        return (
            <>
                <B.Form
                    noValidate
                    validated={validated}
                    onSubmit={e => this.handleEditSlider(e)}
                >
                    <B.Row>
                        <B.Col xs={12} md={4}>
                            <B.Form.Group controlId="editSliderName">
                                <B.Form.Label>Име на слайдера</B.Form.Label>
                                <B.Form.Control type="text" name="editSliderName"
                                                value={formsData['editSliderName'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)} required/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                        </B.Col>
                        <B.Col xs={12} md={4}>
                            <B.Form.Group controlId="editSliderPage">
                                <B.Form.Label>Страница</B.Form.Label>
                                <B.Form.Control as="select" name="editSliderPage" className="custom-select"
                                                value={formsData['editSliderPage'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)} required>
                                    <option value=''>Моля, изберете</option>
                                    {pages.map(page => <option key={page.id} value={page.id}>{page.name}</option>)}
                                </B.Form.Control>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                        </B.Col>
                        <B.Col xs={12} md={4}>
                            <B.Form.Group controlId='editSliderLanguage'>
                                <B.Form.Label>Език</B.Form.Label>
                                <B.Form.Control as="select" name="editSliderLanguage" value={formsData['editSliderLanguage'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)}
                                                className="custom-select" required>
                                    <option value="">Избери език</option>
                                    {languages.map(({id, lang}) => (
                                        <option key={id} value={id}>{lang}</option>
                                    ))}
                                </B.Form.Control>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                        </B.Col>
                    </B.Row>
                    <B.Form.Group className="text-right">
                        <B.Button type="submit" variant="primary">
                            <FontAwesomeIcon icon="save" fixedWidth className="mr-2"/>
                            Запази промените
                        </B.Button>
                    </B.Form.Group>
                </B.Form>
                <div id="slidesWrapper">
                    <h4 className="font-weight-normal">Слайдове</h4>
                    {slides[pageID] ? this.drawSlides() :
                        <B.Alert variant='info'>Няма добавени слайдове</B.Alert>}
                    <div className="text-center">
                        <B.Button type="button" variant='dark' onClick={() => this.modalOpen('slides')}>
                            <FontAwesomeIcon icon='folder-plus' fixedWidth className="mr-2"/>
                            Добави слайд
                        </B.Button>
                    </div>
                </div>
            </>
        );
    }

    drawSlides() {
        const {pageID, slides, slidesOrder} = this.state;
        return (
            <div className="slides-wrapper">
                <B.CardColumns>
                    {slidesOrder[pageID].map((sID, sOrder) => {
                        const slide = slides[pageID][sID];
                        const type = slide['media_type'];
                        const isVideo = type.indexOf('video') !== -1;
                        let media = <B.CardImg variant="top" src={slide['slide_addr']} alt={slide['title'] || ''}/>;
                        if (isVideo) {
                            media = <video className="card-img-top" autoPlay loop muted>
                                <source src={slide['slide_addr']} type={type}/>
                            </video>;
                        }

                        return (
                            <B.Card key={sID} className={`admin-slide ${isVideo ? '' : 'mask'}`}>
                                {media}
                                <B.Card.ImgOverlay className="text-white text-center d-flex flex-column align-items-center justify-content-end">
                                    <h2 className="font-weight-light">{slide['title'] || ' '}</h2>
                                    <B.Card.Subtitle as="p" className="lead">{slide['sub_title'] || ' '}</B.Card.Subtitle>
                                    {slide['button'] ?
                                        <B.Button type="button" className="btn btn-ebc-flat mt-2">{slide['button']}</B.Button> : <div className="mt-2"> </div>}
                                </B.Card.ImgOverlay>
                                <B.Card.Footer className="d-flex align-items-center justify-content-between">
                                    <B.Button type="button" variant="link" title="Премести напред"
                                              onClick={() => this.handleSortUp(sOrder)}
                                              style={sOrder < 1 ? {cursor: 'not-allowed'} : {}}>
                                        <FontAwesomeIcon icon="caret-left" fixedWidth size="lg"
                                                         className={sOrder < 1 ? 'text-muted' : ''}/>
                                    </B.Button>
                                    <div>
                                        <B.Button variant="primary"
                                                  onClick={() => this.getSlideByID(slide['slide_id'])} className="mr-2">
                                            <FontAwesomeIcon icon="edit" fixedWidth/>
                                        </B.Button>
                                        <B.Button variant="danger"
                                                  onClick={() => this.handleDeleteSlide(slide['slide_id'])}>
                                            <FontAwesomeIcon icon="trash-alt" fixedWidth/>
                                        </B.Button>
                                    </div>
                                    <B.Button type="button" variant="link" title="Премести назад"
                                              onClick={() => this.handleSortDown(sOrder)}
                                              style={sOrder === slidesOrder[pageID].length - 1 ? {cursor: 'not-allowed'} : {}}>
                                        <FontAwesomeIcon icon="caret-right" fixedWidth size="lg"
                                                         className={sOrder === slidesOrder[pageID].length - 1 ? 'text-muted' : ''}/>
                                    </B.Button>
                                </B.Card.Footer>
                            </B.Card>
                        );
                    })}
                </B.CardColumns>
            </div>
        );
    }

    drawModals() {
        const {pages, validated, formsData, pageID, slideEditID} = this.state;
        const {languages} = this.props;
        let media = null;

        if (formsData['slideMediaThumb']) {
            media = <img className="img-thumbnail" src={formsData['slideMediaThumb']} alt={formsData['slideTitle'] || ''}/>;
            if (formsData['mediaType'].indexOf('video') !== -1) {
                media = <video className="slide-video" controls>
                    <source src={formsData['slideMediaThumb']} type={formsData['mediaType']}/>
                </video>;
            }
        }

        return (
            <>
                {/* Add/Edit Slider Modal */}
                <B.Modal
                    id='sliders'
                    centered
                    show={this.state.modalShow['sliders']}
                    onHide={() => this.modalHide('sliders', true)}
                    aria-labelledby="slidersTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="slidersTitle">
                            {pageID ? 'Редактирай' : 'Добави'} слайдер
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            noValidate
                            validated={validated}
                            onSubmit={e => this.handleAddSlider(e)}>
                            <B.Form.Group controlId="sliderName">
                                <B.Form.Label>Име на слайдера</B.Form.Label>
                                <B.Form.Control type="text" name="sliderName" value={formsData['sliderName'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)} required/>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="sliderPage">
                                <B.Form.Label>Страница</B.Form.Label>
                                <B.Form.Control as="select" name="sliderPage" className="custom-select"
                                                value={formsData['sliderPage'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)} required>
                                    <option value=''>Моля, изберете</option>
                                    {pages.map(page => <option key={page.id} value={page.id}>{page.name}</option>)}
                                </B.Form.Control>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId='sliderLanguage'>
                                <B.Form.Label>Език</B.Form.Label>
                                <B.Form.Control as="select" name="sliderLanguage" value={formsData['sliderLanguage'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)}
                                                className="custom-select" required>
                                    <option value="">Избери език</option>
                                    {languages.map(({id, lang}) => (
                                        <option key={id} value={id}>{lang}</option>
                                    ))}
                                </B.Form.Control>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon={pageID ? 'edit' : 'plus'} fixedWidth className="mr-2"/>
                                    {pageID ? 'Редактирай' : 'Добави'} слайдер
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
                {/* Add/Edit Slide Modal */}
                <B.Modal
                    id='slides'
                    centered
                    show={this.state.modalShow['slides']}
                    onHide={() => this.modalHide('slides', true)}
                    aria-labelledby="slidesTitle"
                >
                    <B.Modal.Header closeButton>
                        <B.Modal.Title id="slidesTitle">
                            {slideEditID ? 'Редактирай' : 'Добави'} слайд
                        </B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <B.Form
                            noValidate
                            validated={validated}
                            onSubmit={e => this.handleAddEditSlide(e)}
                            method="POST"
                            encType="multipart/form-data">
                            <B.Form.Group controlId="slideMedia">
                                <label>Медия</label>
                                {media ? <div className="w-25 mb-2">{media}</div> : ''}
                                <div className="custom-file">
                                    <B.Form.Control type="file" name="slideMedia" className="custom-file-input"
                                                    onChange={(ev) => this.handleFileInputChange(ev, ['image/jpeg', 'image/gif', 'image/png', 'video/mp4', 'video/ogg'])}
                                                    accept="image/jpeg, image/gif, image/png, video/mp4, video/ogg"
                                                    lang="bg"
                                                    required={!slideEditID}/>
                                    <B.Form.Label className="custom-file-label">
                                        {formsData['slideMedia'] ? formsData['slideMedia']['name'] :
                                            <div><span className="d-md-none">Снимка/Видео</span><span
                                                className="d-none d-md-block">.jpg, .jpeg, .gif, .png, .mp4, .ogg</span>
                                            </div>}
                                    </B.Form.Label>
                                </div>
                                <B.FormText className="text-muted d-md-none">.jpg, .jpeg, .gif, .png, .mp4, .ogg</B.FormText>
                                <B.Form.Control.Feedback type="invalid">Полето е задължително!</B.Form.Control.Feedback>
                            </B.Form.Group>
                            <B.Form.Group controlId="slideTitle">
                                <B.Form.Label>Заглавие</B.Form.Label>
                                <B.Form.Control type="text" name="slideTitle" value={formsData['slideTitle'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)}/>
                                <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                            </B.Form.Group>
                            <B.Form.Group controlId="slideSubTitle">
                                <B.Form.Label>Подзаглавие</B.Form.Label>
                                <B.Form.Control type="text" name="slideSubTitle"
                                                value={formsData['slideSubTitle'] || ''}
                                                onChange={(ev) => this.handleInputChange(ev)}/>
                                <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                            </B.Form.Group>
                            <B.Row>
                                <B.Col xs={12} md={6}>
                                    <B.Form.Group controlId="slideButtonAddress">
                                        <B.Form.Label>Бутон - адрес</B.Form.Label>
                                        <B.Form.Control type="text" name="slideButtonAddress"
                                                        value={formsData['slideButtonAddress'] || ''}
                                                        onChange={(ev) => this.handleInputChange(ev)}
                                                        placeholder="/media/sermons"/>
                                        <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                                    </B.Form.Group>
                                </B.Col>
                                <B.Col xs={12} md={6}>
                                    <B.Form.Group controlId="slideButton">
                                        <B.Form.Label>Бутон - текст</B.Form.Label>
                                        <B.Form.Control type="text" name="slideButton"
                                                        value={formsData['slideButton'] || ''}
                                                        onChange={(ev) => this.handleInputChange(ev)}
                                                        placeholder="Проповеди"/>
                                        <B.FormText className="text-muted">Полето не е задължително</B.FormText>
                                    </B.Form.Group>
                                </B.Col>
                            </B.Row>
                            <B.Form.Group className="text-center">
                                <B.Button type="submit" variant="primary">
                                    <FontAwesomeIcon icon={slideEditID ? 'edit' : 'plus'} fixedWidth className="mr-2"/>
                                    {slideEditID ? 'Редактирай' : 'Добави'} слайд
                                </B.Button>
                            </B.Form.Group>
                        </B.Form>
                    </B.Modal.Body>
                </B.Modal>
            </>
        );
    }

    render() {
        const {loaded, error, alerts, pageID} = this.state;

        if (error) {
            return (
                <SliderLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                              handleMainButton={() => this.handleMainButton()}>
                    <B.Alert variant='danger'>
                        <strong>Грешка:</strong> {error}
                    </B.Alert>
                    {this.drawModals()}
                </SliderLayout>
            );
        } else if (!loaded) {
            return (
                <SliderLayout alerts={alerts} handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                              handleMainButton={() => this.handleMainButton()}>
                    <B.ProgressBar animated now={100} label="Loading..."/>
                    {this.drawModals()}
                </SliderLayout>
            );
        } else {
            return (
                <SliderLayout isEdit={pageID} alerts={alerts}
                              handleCloseClick={(alert) => this.handleCloseAlert(alert)}
                              handleMainButton={() => this.handleMainButton()}>
                    {pageID ? this.drawForm() : this.drawSliders()}
                    {this.drawModals()}
                </SliderLayout>
            );
        }
    }
}

const SliderLayout = props => {
    const {children, alerts, handleCloseClick, handleMainButton, isEdit} = props;
    return (
        <>
            {alerts.length ?
                <Alerts alerts={alerts} handleCloseClick={(alert) => handleCloseClick(alert)}/>
                : ''}
            <div className="page-header mb-3">
                <h2 className="d-inline-block">Слайдери</h2>

                <B.Button className="btn btn-light float-right" onClick={() => handleMainButton()}>
                    <FontAwesomeIcon icon={isEdit ? 'chevron-left' : 'plus'}
                                     className={`mr-2 ${isEdit ? '' : 'text-success'}`} fixedWidth/>
                    {isEdit ? 'Обратно' : 'Добави слайдер'}
                </B.Button>
            </div>
            {children}
        </>
    );
};
