import React, {Component} from 'react';
import {Link} from "react-router-dom";
import * as B from 'react-bootstrap';
import {get} from "../API";

class IndexMinistries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ministryContent: {},
            error: null
        };

        this.API_URL = '/api/page';
        this.DEFAULT_MINISTRY_IMG = '/images/default_ministry.png';
    }

    componentWillReceiveProps(nextProps) {
        const {pages} = this.props;

        if (nextProps.pages !== pages)
            this.setState({ministryContent: {}});
    }

    getPageData(id) {
        get(`${this.API_URL}/${id}`)
            .then(data => {
                if (data) {
                    let ministryContent = {...this.state.ministryContent};
                    if (data['data']) {
                        const pageID = data['data'].id;

                        ministryContent[pageID] = {};
                        ministryContent[pageID]['title'] = data['data'].page;

                        if (data['data_content'] && data['data_content'].hasOwnProperty(pageID))
                            ministryContent[pageID]['content'] = data['data_content'][pageID];

                        this.setState({
                            ministryContent
                        });
                    }
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    loaded: false,
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    render() {
        const {pages} = this.props;
        const {error, ministryContent} = this.state;

        if (error)
            return <B.Alert variant="info">{error}</B.Alert>;

        if (pages && pages.length) {
            const ministries = pages.slice(0, 3);

            return (
                <B.Row className="ministries-wrapper">
                    {ministries.map(ministry => {

                        if (Object.keys(ministryContent).length < ministries.length)
                            this.getPageData(ministry.id);

                        if (!ministryContent.hasOwnProperty(ministry.id))
                            return null;

                        return (
                            <B.Col key={ministry.id} xs={12} md={6} lg={4} className="mb-3">
                                <B.Card className="ministry">
                                    <Link to={ministry.addr}>
                                        <B.CardImg variant="top"
                                                   src={ministry.media !== '/storage/' ? ministry.media : this.DEFAULT_MINISTRY_IMG}
                                                   alt="Служение"/>
                                        <B.Card.Body>
                                            <B.Card.Title>{ministryContent[ministry.id]['title']}</B.Card.Title>
                                            {Object.keys(ministryContent).length && ministryContent.hasOwnProperty(ministry.id) && ministryContent[ministry.id]['content']['response'] !== false ?
                                                <B.Card.Text>{ministryContent[ministry.id]['content'][0]['content'].replace(/\&nbsp;/g, ' ').replace(/(<([^>]+)>)/ig, "").slice(0, 157)}...</B.Card.Text>
                                                : ''}
                                        </B.Card.Body>

                                    </Link>
                                </B.Card>
                            </B.Col>
                        );
                    })}
                </B.Row>
            );
        }

        return null;
    }
}

export default IndexMinistries;
