import '@babel/polyfill';
import 'whatwg-fetch';

import './bootstrap';
import './sass/app.scss';

import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';

import {loadReCaptcha} from 'react-recaptcha-v3'

// Import FontAwesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'

library.add(fab, fas, far);

import Layout from './components/layout/Layout';

//Import Components
import Admin from './components/Admin';
import Index from "./components/Index";
import Error404 from "./components/Error404";
import Contact from "./components/Contact";
import Media from "./components/Media";
import Live from "./components/Live";
import Page from "./components/Page";
import News from "./components/News";
import ScrollToTop from "./components/layout/ScrollToTop";
import Login from "./components/Login";
import {get} from "./API";
import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1,
            languages: [],
            isLive: false,
            component: '/',
            error: null,
            errorInfo: null,
            loaded: false,
            isUC: false,
            fbLogin: false,
            fbLoginName: '',
            fbPageName: '',
            fbAccessToken: null
        };
        this.RECAPTCHA_SITE_KEY = '6LfXFp0UAAAAAEuUXDouGGG1dM5slKhl1tEug3xs';
        this.FB_APP_ID = '328784674414252';
    }

    componentWillMount() {
        this.loadLanguages();

        const localLanguage = localStorage.getItem('lang');
        const userLanguage = (navigator.languages && navigator.languages[0]) || navigator.language;
        const possibleLanguage = localLanguage ? localLanguage : (userLanguage === 'bg' ? 1 : 2);

        localStorage.setItem('lang', possibleLanguage ? possibleLanguage : 1);
        this.setState({language: possibleLanguage ? parseInt(possibleLanguage) : 1});
    }

    componentDidMount() {
        loadReCaptcha(this.RECAPTCHA_SITE_KEY);

        window.fbAsyncInit = () => {
            FB.init({
                appId: this.FB_APP_ID,
                cookie: true,
                xfbml: true,
                version: 'v5.0'
            });

            FB.AppEvents.logPageView();
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    componentDidCatch(error, errorInfo) {
        this.setState({error, errorInfo});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {loaded} = this.state;
        document.body.style.overflow = `${loaded ? 'auto' : 'hidden'}`;
        document.body.style.position = `${loaded ? 'initial' : 'fixed'}`;
    }

    testFBAPI(pageID = '210186852330017') {
        FB.api('/me',
            'GET',
            {},
            response => {
                this.setState({fbLoginName: response.name});
            });

        if (pageID) {
            FB.api(
                `/${pageID}`,
                'GET',
                {},
                response => {
                    if (response.name) {
                        this.setState({fbPageName: response.name});
                    }
                }
            );
        }
    }

    statusChangeCallback(response, pageID) {
        this.setState({fbLogin: response.status});

        if (response.status === 'connected') {
            this.testFBAPI(pageID);
            this.setState({fbAccessToken: response.authResponse.accessToken});
        } else {
            console.log('User cancelled login or did not fully authorize.');
        }
    }

    checkLoginState(pageID) {
        FB.getLoginStatus(response => {
            this.statusChangeCallback(response, pageID);
        });
    }

    handleFBClick(scope = 'public_profile', pageID = null) {
        FB.login(response => {
            this.checkLoginState(pageID);
        }, {scope: scope, return_scopes: true});
    }

    handleStartStream() {
        this.setState({isLive: true});
    }

    handleScheduleStream() {
        this.setState({isLive: true});
    };

    handleStopStream() {
        this.setState({isLive: false});
    };

    handleChangeLanguage(language) {
        localStorage.setItem('lang', language);
        location.reload(true);
    }

    handleChangeComponent(component) {
        if (this.state.component !== component)
            this.setState({component});
    }

    handleLoaded(loaded) {
        this.setState({loaded});
    }

    isLoggedIn() {
        return localStorage.getItem('tkn');
    }

    isLogIn() {
        return window.location.toString().indexOf('/login') !== -1;
    }

    loadLanguages() {
        get('/api/lang')
            .then(data => {
                if (data['response'] !== false) {
                    this.setState({
                        languages: data
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    error: 'Възникна неочаквана грешка! Моля, опитайте отново.'
                });
            });
    }

    render() {
        const {language, languages, isLive, component, error, loaded, isUC, fbLogin, fbLoginName, fbPageName, fbAccessToken} = this.state;

        if (isUC && !this.isLoggedIn() && !this.isLogIn())
            return <h1 className="text-center mt-3">Сайтът е в процес на разработка</h1>;

        if (error)
            return <Alert variant="danger" className="mt-3 mx-auto w-75">{error}</Alert>;

        return (
            <>
                <div className={`loader ${loaded ? '' : 'show'}`}>
                    <FontAwesomeIcon icon="spinner" pulse size="5x" className="text-primary"/>
                </div>
                <BrowserRouter>
                    <ScrollToTop onLoad={(path) => this.handleChangeComponent(path)}>
                        <Layout language={language} languages={languages}
                                isAdmin={component.indexOf('admin') !== -1}
                                onLanguageChange={(lang) => this.handleChangeLanguage(lang)}>
                            <Switch>
                                <Route exact path='/'
                                       render={props => <Index {...props} isLive={isLive} language={language}
                                                               languages={languages}
                                                               isLoaded={loaded => this.handleLoaded(loaded)}/>}/>
                                <Route path='/login' render={props => (
                                    this.isLoggedIn() ?
                                        <Redirect to='/admin'/>
                                        : <Login {...props} isLoaded={loaded => this.handleLoaded(loaded)}/>)
                                }/>
                                <Route path='/admin' render={props =>
                                    this.isLoggedIn() ?
                                        (<Admin {...props} isLive={isLive} languages={languages}
                                                onStartStream={() => this.handleStartStream()}
                                                onScheduleStream={() => this.handleScheduleStream()}
                                                onStopStream={() => this.handleStopStream()}
                                                onFBClick={(scope, pageID) => this.handleFBClick(scope, pageID)}
                                                fbLogin={fbLogin}
                                                fbLoginName={fbLoginName}
                                                fbPageName={fbPageName}
                                                fbAccessToken={fbAccessToken}
                                                isLoaded={loaded => this.handleLoaded(loaded)}/>)
                                        : (<Redirect to='/'/>)
                                }/>
                                <Route exact path="/media"
                                       render={props => <Media {...props} language={language} languages={languages}
                                                               isLoaded={loaded => this.handleLoaded(loaded)}/>}/>
                                <Route exact path="/media/:type"
                                       render={props => <Media {...props} language={language} languages={languages}
                                                               isLoaded={loaded => this.handleLoaded(loaded)}/>}/>
                                <Route exact path="/media/:type/:id"
                                       render={props => <Media {...props} language={language} languages={languages}
                                                               isLoaded={loaded => this.handleLoaded(loaded)}/>}/>
                                <Route exact path="/live"
                                       render={props => <Live {...props} isLive={isLive} language={language}
                                                              languages={languages}
                                                              onFBClick={() => this.handleFBClick()}
                                                              fbLogin={fbLogin}
                                                              isLoaded={loaded => this.handleLoaded(loaded)}/>}/>
                                <Route exact path="/news"
                                       render={props => <News {...props} language={language} languages={languages}
                                                              isLoaded={loaded => this.handleLoaded(loaded)}/>}/>
                                <Route exact path="/news/:id"
                                       render={props => <News {...props} language={language} languages={languages}
                                                              isLoaded={loaded => this.handleLoaded(loaded)}/>}/>
                                <Route exact path="/contact"
                                       render={props => <Contact {...props} language={language}
                                                                 recaptcha={this.RECAPTCHA_SITE_KEY}
                                                                 languages={languages}
                                                                 isLoaded={loaded => this.handleLoaded(loaded)}/>}/>
                                <Route exact path="/:category/:name"
                                       render={props => <Page {...props} language={language} languages={languages}
                                                              isLoaded={loaded => this.handleLoaded(loaded)}/>}/>
                                <Route exact path="/:name"
                                       render={props => <Page {...props} language={language} languages={languages}
                                                              isLoaded={loaded => this.handleLoaded(loaded)}/>}/>
                                <Route render={props => <Error404 {...props}
                                                                  isLoaded={loaded => this.handleLoaded(loaded)}/>}/>
                            </Switch>
                        </Layout>
                    </ScrollToTop>
                </BrowserRouter>
            </>
        );
    }
}

if ($('#root')) {
    ReactDOM.render(
        <App/>,
        document.getElementById('root')
    );
}
